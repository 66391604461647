import HeaderContext from 'context/headerContext'
import { useContext } from 'react'
import DatePicker from 'react-date-picker'
import './overrideDatePicker.css'

const SelectDate = ({
  dateProperty,
  changeType,
  setUsingPeriod,
  minDate,
  maxDate
}) => {
  const { headerState, headerDispatch } = useContext(HeaderContext)
  let date = headerState.maxDate
  if (changeType === 'STARTDATE_CHANGE') {
    date = headerState?.startDate || headerState.minDate
  } else if (changeType === 'ENDDATE_CHANGE') {
    date = headerState?.endDate || headerState.maxDate
  }

  const updateDate = (newDate) => {
    setUsingPeriod(null)
    headerDispatch({
      type: changeType,
      [dateProperty]: newDate
    })
  }

  const disableTile = ({ date: tileDate }) => {
    // Desactivar la fecha si es igual a la contraparte (startDate o endDate)
    if (dateProperty === 'startDate' && headerState?.endDate) {
      return (
        tileDate.getFullYear() === headerState.endDate.getFullYear() &&
        tileDate.getMonth() === headerState.endDate.getMonth() &&
        tileDate.getDate() === headerState.endDate.getDate()
      )
    } else if (dateProperty === 'endDate' && headerState?.startDate) {
      return (
        tileDate.getFullYear() === headerState.startDate.getFullYear() &&
        tileDate.getMonth() === headerState.startDate.getMonth() &&
        tileDate.getDate() === headerState.startDate.getDate()
      )
    }
    return false
  }

  return (
    <DatePicker
      value={date}
      clearIcon={null}
      locale="es"
      onChange={updateDate}
      minDate={minDate || headerState?.minDate}
      maxDate={maxDate || headerState?.maxDate}
      tileDisabled={disableTile}
    />
  )
}

export default SelectDate
