import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Input } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

import 'components/DatePicker/overrideDatePicker.css'
import useDebounce from 'hooks/useDebounce'
import { useEffect } from 'react'
import DatePicker from 'react-date-picker'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  datePickerContainer: {
    position: 'relative', // Establish this element as a containing block for absolute positioning
    overflow: 'visible', // Allow the contents to overflow
    zIndex: 1000 // Ensure it's above other elements
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center', // This ensures vertical alignment of filters
    justifyContent: 'flex-start', // Aligns filters to the start
    height: '100%' // Make the filter container fill the height
    // Any additional styling for the filter container
  },
  numericInput: {
    // Style for the numeric input
    margin: theme.spacing(1),
    '& input': {
      padding: '8px',
      fontSize: '0.75rem'
    }
  },
  textFilterInput: {
    // Style for the text filter input (Autocomplete)
    '& .MuiOutlinedInput-root': {
      border: 'none',
      '& fieldset': {
        border: 'none'
      },
      '&:hover fieldset': {
        border: 'none'
      },
      '&.Mui-focused fieldset': {
        border: 'none'
      },
      fontSize: '0.75rem'
    }
  },
  bigTextFilterInput: {
    // Style for the text filter input (Autocomplete)
    '& .MuiOutlinedInput-root': {
      borderBottom: '1px solid #d3d3d3',
      '& fieldset': {
        border: 'none'
      },
      '&:hover fieldset': {
        border: 'none'
      },
      '&.Mui-focused fieldset': {
        border: 'none'
      },
      fontSize: '0.75rem'
    }
  },
  datePicker: {
    width: '40%',
    '& .react-date-picker__wrapper': {
      fontSize: '0.75rem',
      overflow: 'visible'
    }
  }
}))

const NumericFilter = ({ column }) => {
  const classes = useStyles()
  const safelyConvertToNumber = (value) => {
    const num = parseFloat(value)
    return isNaN(num) ? '' : num
  }

  // Convert initial filter values from string to number
  const initialMinValue =
    safelyConvertToNumber(column.getFilterValue()?.[0]) ?? ''
  const initialMaxValue =
    safelyConvertToNumber(column.getFilterValue()?.[1]) ?? ''
  const [minValue, setMinValue] = useState(initialMinValue)
  const [maxValue, setMaxValue] = useState(initialMaxValue)
  const debouncedMinVal = useDebounce(minValue, 1500)
  const debouncedMaxVal = useDebounce(maxValue, 1500)

  // Update the filter value for min when debounced value changes
  useEffect(() => {
    const numDebouncedMinVal = safelyConvertToNumber(debouncedMinVal)
    if (numDebouncedMinVal !== initialMinValue) {
      column.setFilterValue((old) => [numDebouncedMinVal, old?.[1]])
    }
  }, [debouncedMinVal, initialMinValue, column])

  // Update the filter value for max when debounced value changes
  useEffect(() => {
    const numDebouncedMaxVal = safelyConvertToNumber(debouncedMaxVal)
    if (numDebouncedMaxVal !== initialMaxValue) {
      column.setFilterValue((old) => [old?.[0], numDebouncedMaxVal])
    }
  }, [debouncedMaxVal, initialMaxValue, column])

  const onMinChange = useCallback((e) => {
    setMinValue(e.target.value)
  }, [])

  const onMaxChange = useCallback((e) => {
    setMaxValue(e.target.value)
  }, [])

  const formatPlaceholder = (value) => {
    const numValue = safelyConvertToNumber(value)
    return typeof numValue === 'number'
      ? (Math.ceil(numValue * 100) / 100).toFixed(2)
      : ''
  }

  const getWidthForValue = (value) => {
    return Math.max(50, (value.length + 1) * 10) + 'px'
  }

  const minPlaceholder = `Min ${formatPlaceholder(
    column.getFacetedMinMaxValues()?.[0]
  )}`
  const maxPlaceholder = `Max ${formatPlaceholder(
    column.getFacetedMinMaxValues()?.[1]
  )}`

  return (
    <div>
      <Input
        type="number"
        size="small"
        value={minValue}
        onChange={onMinChange}
        placeholder={minPlaceholder}
        style={{ width: getWidthForValue(minPlaceholder) }}
        className={classes.numericInput}
      />
      <Input
        type="number"
        size="small"
        value={maxValue}
        onChange={onMaxChange}
        placeholder={maxPlaceholder}
        style={{ width: getWidthForValue(maxPlaceholder) }}
        className={classes.numericInput}
      />
    </div>
  )
}

const TextFilter = ({ column }) => {
  const classes = useStyles()
  const columnFilterValue = column.getFilterValue()
  const uniqueValuesArray = useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column]
  )

  const onTextFilterChange = useCallback(
    (event, newValue) => {
      column.setFilterValue(newValue != null ? String(newValue) : undefined)
    },
    [column]
  )

  return (
    <Autocomplete
      freeSolo
      size="small"
      options={uniqueValuesArray.slice(0, 5000)}
      value={String(columnFilterValue ?? '')}
      className={classes.textFilterInput}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Filter.. (${uniqueValuesArray.length})`}
          variant="outlined"
          InputLabelProps={{
            style: { fontSize: '0.65rem' }
          }}
          onBlur={(event) => {
            const newValue = event.target.value
            onTextFilterChange(event, newValue)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              const newValue = event.target.value
              onTextFilterChange(event, newValue)
            }
          }}
        />
      )}
    />
  )
}

const BigTextFilter = ({ column }) => {
  const classes = useStyles()
  const columnFilterValue = column.getFilterValue()
  const uniqueValuesArray = useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column]
  )

  const onTextFilterChange = useCallback(
    (event, newValue) => {
      column.setFilterValue(newValue != null ? String(newValue) : undefined)
    },
    [column]
  )

  return (
    <Autocomplete
      freeSolo
      size="small"
      options={uniqueValuesArray}
      value={String(columnFilterValue ?? '')}
      className={classes.bigTextFilterInput}
      style={{ width: column.getSize() - 0.1 * column.getSize() }}
      onChange={(event, newValue) => {
        onTextFilterChange(event, newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Buscar... (${uniqueValuesArray.length})`}
          variant="outlined"
          onBlur={(event) => {
            const newValue = event.target.value
            onTextFilterChange(event, newValue)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              const newValue = event.target.value
              onTextFilterChange(event, newValue)
            }
          }}
        />
      )}
    />
  )
}

const DateFilter = ({ column }) => {
  const classes = useStyles()
  const minMaxDates = column.getFacetedMinMaxValues()
  const minDate = minMaxDates?.[0]
  const maxDate = minMaxDates?.[1]

  const [startDate, setStartDate] = useState(
    column.getFilterValue()?.[0] ||
      (minDate !== undefined ? String(minDate) : undefined)
  )
  const [endDate, setEndDate] = useState(
    column.getFilterValue()?.[1] ||
      (maxDate !== undefined ? String(maxDate) : undefined)
  )

  // This function converts a Date object to a string in dd-mm-yyyy format
  const formatDateToString = (date) => {
    if (!date) return ''
    let day = date.getDate().toString().padStart(2, '0')
    let month = (date.getMonth() + 1).toString().padStart(2, '0') // Month is 0-based
    let year = date.getFullYear()
    return `${day}-${month}-${year}`
  }
  // Parses a string in dd-mm-yyyy format back into a Date object
  const parseMinDateString = (value) => {
    if (!value) {
      if (!minDate) {
        return null // If there's no minDate, return null
      }
      value = minDate // Use minDate if value is not provided
    }
    const [day, month, year] = value.split('-').map(Number)
    return new Date(year, month - 1, day) // Month is 0-based
  }

  const parseMaxDateString = (value) => {
    if (!value) {
      if (!maxDate) {
        return null // If there's no minDate, return null
      }
      value = maxDate // Use minDate if value is not provided
    }
    const [day, month, year] = value.split('-').map(Number)
    return new Date(year, month - 1, day) // Month is 0-based
  }

  const onStartDateChange = useCallback(
    (date) => {
      const dateString = formatDateToString(date)
      setStartDate(dateString)

      column.setFilterValue((old) => [dateString, old?.[1]])
    },
    [column, formatDateToString]
  )

  const onEndDateChange = useCallback(
    (date) => {
      const dateString = formatDateToString(date)
      setEndDate(dateString)
      column.setFilterValue((old) => [old?.[0], dateString])
    },
    [column, formatDateToString] // include all the hooks that this callback depends on
  )

  return (
    <div>
      <DatePicker
        label="Start Date"
        value={parseMinDateString(startDate)}
        clearIcon={null}
        locale="es"
        onChange={onStartDateChange}
        className={classes.datePicker}
        renderInput={(params) => <TextField {...params} />}
      />
      <DatePicker
        label="End Date"
        value={parseMaxDateString(endDate)}
        clearIcon={null}
        locale="es"
        onChange={onEndDateChange}
        className={classes.datePicker}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  )
}

const TanstackFilter = ({ column, table, increaseSize = false }) => {
  const classes = useStyles()
  const preFilteredRows = table.getPreFilteredRowModel()
  const firstValue = preFilteredRows.flatRows[-1]?.getValue(column.id) // Change to the last row in the table, because of the added empty rows
  const isFirstValueNumeric = !isNaN(Number(firstValue))
  const isDateColumn =
    typeof firstValue === 'string' && /^\d{2}-\d{2}-\d{4}$/.test(firstValue)

  return (
    <div className={classes.filterContainer}>
      {isFirstValueNumeric ? (
        <NumericFilter column={column} />
      ) : isDateColumn ? (
        <div className={classes.datePickerContainer}>
          <DateFilter column={column} />
        </div>
      ) : increaseSize ? (
        <BigTextFilter column={column} />
      ) : (
        <TextFilter column={column} />
      )}
    </div>
  )
}

export default TanstackFilter
