import {
  deleteAuthRequest,
  getRequest,
  operationsPostBlobRequest,
  postAuthRequest
} from 'axios/request'

export function instrumentSeriesReturnsRequest(params) {
  const url = '/portfolios/data/returns-list/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function portfolioAssetsSearchRequest(params) {
  const url = '/portfolios/data/asset-search/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function assetsSearchRequest() {
  const url = '/assets/search/'
  return getRequest(url, {})
}

export function createSearchListRequest(params) {
  const url = '/assets/search/create/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function updateSearchListRequest(params) {
  const url = '/assets/search/update/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function deleteSearchListRequest(params) {
  const url = '/assets/search/delete/'
  return deleteAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function downloadSeriesReturnsRequest(params) {
  const url = '/portfolios/data/returns-list/download/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function rebalanceReturnsRequest(params) {
  const url = '/portfolios/data/returns-list/rebalance/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function rebalancePortfolioSearchRequest(params) {
  const url = '/portfolios/data/rebalance/search/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function createRebalancePortfolioRequest(params) {
  const url = '/portfolios/data/rebalance/search/create/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function updateRebalancePortfolioRequest(params) {
  const url = '/portfolios/data/rebalance/search/update/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function deleteRebalancePortfolioRequest(params) {
  const url = '/portfolios/data/rebalance/search/delete/'
  return deleteAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function downloadRebalancePortfolioRequest(params) {
  const url = 'portfolios/data/returns-list/rebalance/download/'
  return operationsPostBlobRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}
