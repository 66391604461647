import { Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { errorNotification } from 'utils/UI/Notifications/Notifications'

import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Autocomplete from '@material-ui/lab/Autocomplete'
import HeaderContext from 'context/headerContext'
import useResponsive from 'hooks/useResponsive'
import DownloadButton from 'utils/UI/Buttons/DownloadButton'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import {
  AllocationsReportRequest,
  ClientPortfoliosRequest,
  ClientsRequest,
  ResumenPosicionesReportRequest,
  SeriesPortfolioReportRequest
} from 'axios/requests/operations'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  }
}))

export default function OPReports() {
  const classes = useStyles()
  const theme = useTheme()
  const { isMobile } = useResponsive()
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate } = headerState

  const [loadingAllocations, setLoadingAllocations] = useState(false)
  const [loadingSeriesPortfolio, setLoadingSeriesPortfolio] = useState(false)
  const [loadingResumenPosiciones, setLoadingResumenPosiciones] =
    useState(false)
  const [clients, setClients] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const [portfolios, setPortfolios] = useState([])
  const [selectedPortfolios, setSelectedPortfolios] = useState([])

  useEffect(() => {
    let didCancel = false
    const fetchClients = async () => {
      try {
        const result = await ClientsRequest()
        if (!didCancel) {
          setClients(result.data)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchClients()

    return () => {
      didCancel = true
    }
  }, [])

  // Fetch portfolios when a client is selected
  useEffect(() => {
    let didCancel = false
    const fetchPortfolios = async () => {
      try {
        if (selectedClient) {
          const result = await ClientPortfoliosRequest({
            client_id: selectedClient.id
          })
          if (!didCancel) {
            setPortfolios(result.data)
          }
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchPortfolios()

    return () => {
      didCancel = true
    }
  }, [selectedClient])

  const downloadAllocationsHandler = async () => {
    setLoadingAllocations(true)
    try {
      const response = await AllocationsReportRequest({
        aggregate: 'root-class'
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Abaqus - Allocations Report' + '.xlsx')
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingAllocations(false)
  }

  const downloadSeriesPortfolioHandler = async () => {
    setLoadingSeriesPortfolio(true)
    try {
      const portfolioCodes = selectedPortfolios.map((p) => p.code).join(',')
      const response = await SeriesPortfolioReportRequest({
        codes: portfolioCodes,
        start_date: format(startDate, 'yyyy-MM-dd'),
        end_date: format(endDate, 'yyyy-MM-dd'),
        currency: 'CLP'
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Abaqus - Series Portfolio Report - ${portfolioCodes}.xlsx`
      )
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingSeriesPortfolio(false)
  }

  const downloadResumenPosicionesHandler = async () => {
    if (!selectedClient) {
      errorNotification('Please select a client')
      return
    }

    setLoadingResumenPosiciones(true)
    try {
      const response = await ResumenPosicionesReportRequest({
        client_id: selectedClient.id,
        start_date: format(startDate, 'yyyy-MM-dd'),
        end_date: format(endDate, 'yyyy-MM-dd')
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Abaqus - Resumen Posiciones - ${selectedClient.code}.xlsx`
      )
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingResumenPosiciones(false)
  }

  return (
    <div className={classes.contentContainer}>
      {/* Client and Portfolio Selection */}
      <div className={classes.clientDataContainer}>
        <CardWrapper title="Reports" titleFeedback="Reports">
          <Grid
            container
            className={classes.holdingsSelectorContainer}
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                label="Selecciona un cliente:"
                variant="outlined"
                placeholder="Clientes"
                margin="normal"
                options={clients}
                getOptionLabel={(client) =>
                  `[${client.code}] ${client.first_name} ${client.last_name}`
                }
                value={selectedClient}
                onChange={(event, newValue) => {
                  setSelectedClient(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona un cliente:"
                    variant="outlined"
                    placeholder="Clientes"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((client, index) => (
                    <Tooltip title={client.code} key={index}>
                      <Chip
                        style={{
                          marginRight: 12,
                          color: lighten(
                            [
                              theme.palette.primary.main,
                              theme.palette.secondary.main
                            ][index],
                            0.5
                          )
                            ? '#fff'
                            : '#000'
                        }}
                        label={`[${client.code}] ${client.first_name} ${client.last_name}`}
                        key={client.code}
                        classes={{ deleteIcon: classes.deleteIcon }}
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                label="Selecciona portafolios:"
                variant="outlined"
                placeholder="Portafolios"
                margin="normal"
                multiple
                options={portfolios}
                getOptionLabel={(portfolio) =>
                  `[${portfolio.code}]: ${portfolio.name}`
                }
                value={selectedPortfolios}
                onChange={(event, newValues) => {
                  setSelectedPortfolios(newValues)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona portafolios:"
                    variant="outlined"
                    placeholder="Portafolios"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((portfolio, index) => (
                    <Tooltip title={portfolio.code} key={index}>
                      <Chip
                        style={{
                          marginRight: 12,
                          backgroundColor: theme.palette.orange.lighten1,
                          color: theme.palette.getContrastText(
                            theme.palette.orange.lighten1
                          )
                        }}
                        size="small"
                        label={portfolio.code}
                        key={portfolio.code}
                        classes={{ deleteIcon: classes.deleteIcon }}
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))
                }
              />
            </Grid>
          </Grid>
        </CardWrapper>
      </div>
      <Typography className={classes.message}>
        {selectedPortfolios.length === 0 &&
          'Select at least one portfolio to enable the "Series Portfolio Report" download.'}
      </Typography>
      <Typography className={classes.message}>
        {!selectedClient &&
          'Select a client to enable the "Resumen de Posiciones" download.'}
      </Typography>
      {/* Allocations Report Section */}
      <div className={classes.reportContainer}>
        <CardWrapper className={classes.reportCard} width="100%">
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.cardReportName}>
              Reporte Allocations
            </Typography>
            {loadingAllocations ? (
              <CircularProgress />
            ) : (
              <DownloadButton onClickHandler={downloadAllocationsHandler} />
            )}
          </Grid>
        </CardWrapper>
      </div>

      {/* Series Portfolio Report Section */}
      <div className={classes.reportContainer}>
        <CardWrapper className={classes.reportCard} width="100%">
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.cardReportName}>
              Reporte Series Portfolio
            </Typography>
            {loadingSeriesPortfolio ? (
              <CircularProgress />
            ) : (
              <DownloadButton
                onClickHandler={downloadSeriesPortfolioHandler}
                disabled={!selectedPortfolios.length}
              />
            )}
          </Grid>
        </CardWrapper>
      </div>

      {/* Resumen Posiciones Report Section */}
      <div className={classes.reportContainer}>
        <CardWrapper className={classes.reportCard} width="100%">
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.cardReportName}>
              Reporte Resumen de Posiciones
            </Typography>
            {loadingResumenPosiciones ? (
              <CircularProgress />
            ) : (
              <DownloadButton
                onClickHandler={downloadResumenPosicionesHandler}
                disabled={!selectedClient}
              />
            )}
          </Grid>
        </CardWrapper>
      </div>
    </div>
  )
}
