export default function decoder(data) {
  // Refactor this code
  const byteCharacters = atob(data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  // End of future refactor
  return new Blob([byteArray], { type: 'blob' })
}
