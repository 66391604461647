import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'

import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import CancelIcon from '@material-ui/icons/Cancel'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  GetAssetClassesCodesRequest,
  UpdateAssetCompositionRequest
} from 'axios/requests/operations'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import Loading from 'utils/UI/Loading'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'
import { COLORS_GRAPH_ASSETS } from 'utils/UI/Theme'

const useStyles = makeStyles({
  composition: {
    padding: '16px 0',
    borderBottom: '1px solid #ddd'
  },
  compositionLabel: {
    fontWeight: 'bold',
    marginBottom: 8
  }
})

export default function AssetCompositionsEditDialog({
  open,
  setOpen,
  compositions,
  loadingActualCompositions
}) {
  const classes = useStyles()
  const theme = useTheme()
  const [assetClasses, setAssetClasses] = useState([])
  const [assetClassCodesOptions, setAssetClassCodesOptions] = useState([])
  const [weights, setWeights] = useState([])
  const [date, setDate] = useState(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleWeightChange = (event, index) => {
    const updatedWeights = [...weights]
    updatedWeights[index] = event.target.value
    setWeights(updatedWeights)
  }

  const isSumEqualToOne = (weights) => {
    const sum = weights.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    )
    return Math.abs(sum - 1) < Number.EPSILON // Using epsilon for floating-point precision
  }

  const areNumbers = (values) => {
    return !values.some((value) => {
      return typeof value !== 'number' || isNaN(value)
    })
  }
  const handleSubmitNewCompositions = async () => {
    setError('')
    const newAssetClassesCodes = assetClasses.map(
      (assetClass) => assetClass.code
    )

    const newWeights = weights.map((weight) =>
      typeof weight === 'string' ? parseFloat(weight.replace(',', '.')) : weight
    )

    if (!areNumbers(newWeights)) {
      setError('Los weights deben ser números entre 0 y 1')
      return
    }
    if (!isSumEqualToOne(newWeights)) {
      setError('La suma de los weights debe sumar 1')
      return
    }
    if (newAssetClassesCodes.includes('-')) {
      setError('Falta por seleccionar: Asset Class')
      return
    }
    setLoading(true)
    try {
      await UpdateAssetCompositionRequest({
        asset_ticker: compositions[0].assetTicker,
        asset_class_codes: newAssetClassesCodes,
        weights: newWeights,
        date: format(new Date(date), 'yyyy-MM-dd')
      })
      successNotification('savedCorrectly')
      setLoading(false)
      handleClose() // Close the dialog after successful submission
    } catch (error) {
      console.log(error)
      errorNotification('generalError')
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetAssetClassesCodesRequest()
        // add an empty option for new compositions
        response.data.asset_classes_codes.push({ code: '-', name: '-' })
        setAssetClassCodesOptions(response.data.asset_classes_codes)
      } catch (error) {
        errorNotification('generalError')
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (compositions.length === 0) return
    setAssetClasses(
      compositions.map(({ assetClassCode: code, assetClassName: name }) => ({
        code,
        name
      }))
    )
    setWeights(compositions.map((composition) => composition.weight))
    setDate(compositions[0].date)
  }, [compositions])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!loadingActualCompositions ? (
          <>
            <DialogTitle id="form-dialog-title">
              Asset Composition de: {compositions[0]?.assetTicker}
            </DialogTitle>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {compositions[0]?.assetName}
            </Typography>
            <DialogContent>
              {error.length > 0 && <Alert severity="error">{error}</Alert>}
              <TextField
                margin="dense"
                label="Date"
                id="date"
                type="date"
                fullWidth
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              {assetClasses.map((assetClass, index) => (
                <div key={index} className={classes.composition}>
                  <Typography variant="h6" className={classes.compositionLabel}>
                    Composition {index + 1}
                  </Typography>
                  <Autocomplete
                    id={`assetClass-${index}`}
                    fullWidth
                    label="Selecciona un Asset Class:"
                    variant="outlined"
                    placeholder="Asset Class"
                    margin="normal"
                    size="small"
                    options={assetClassCodesOptions}
                    getOptionLabel={(assetClass) =>
                      `[${assetClass.code}] ${assetClass.name}`
                    }
                    value={assetClasses[index]}
                    getOptionSelected={(option, value) =>
                      option.code === value.code
                    }
                    onChange={(event, newValue) => {
                      // handle cancel assetclass
                      if (newValue === null) {
                        newValue = { code: '-', name: '-' }
                      }
                      const updatedAssetClasses = [...assetClasses]
                      updatedAssetClasses[index] = newValue
                      setAssetClasses(updatedAssetClasses)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona un Asset Class:"
                        variant="outlined"
                        placeholder="Asset Classes"
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((assetClass, index) => (
                        <Tooltip title={assetClass.code} key={index}>
                          <Chip
                            style={{
                              marginRight: 12,
                              color: lighten(
                                [
                                  theme.palette.primary.main,
                                  ...COLORS_GRAPH_ASSETS
                                ][index],
                                0.5
                              )
                                ? '#fff'
                                : '#000'
                            }}
                            size="small"
                            label={`[${assetClass.code}] ${assetClass.name}`}
                            key={assetClass.code}
                            classes={{ deleteIcon: classes.deleteIcon }}
                            {...getTagProps({ index })}
                          />
                        </Tooltip>
                      ))
                    }
                  />

                  <TextField
                    margin="dense"
                    id={`weight-${index}`}
                    label="Weight"
                    type="text"
                    fullWidth
                    value={weights[index]}
                    onChange={(event) => {
                      handleWeightChange(event, index)
                    }}
                  />
                  {index > 0 && (
                    <IconButton
                      onClick={() => {
                        setAssetClasses((prev) =>
                          prev.filter((_, i) => i !== index)
                        )
                        setWeights((prev) => prev.filter((_, i) => i !== index))
                      }}
                      color="secondary"
                    >
                      <CancelIcon />
                    </IconButton>
                  )}
                </div>
              ))}
              <Button
                onClick={() => {
                  setAssetClasses((prev) => [...prev, { code: '-', name: '-' }])
                  setWeights((prev) => [...prev, ''])
                }}
                color="primary"
              >
                Agregar
              </Button>
            </DialogContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '18px',
                marginBottom: '18px',
                marginLeft: '24px',
                marginRight: '24px'
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={handleSubmitNewCompositions}
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    filter: 'saturate(50%)'
                  }}
                >
                  Guardar
                </Button>
              )}
              <Button
                onClick={handleClose}
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  filter: 'saturate(50%)'
                }}
              >
                Cancelar
              </Button>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </Dialog>
    </div>
  )
}
