/* Languague variable must be change to a global app variable that can change */
const lan = 'es'

export const bigNumberFormatter = (value) => {
  if (lan === 'es') {
    return (
      '$' +
      parseFloat(value).toLocaleString('de-DE', { maximumFractionDigits: 0 })
    )
  } else {
    return (
      '$' +
      parseFloat(value).toLocaleString('en-US', { maximumFractionDigits: 0 })
    )
  }
}

export const smallNumberFormatter = (
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) => {
  if (lan === 'es') {
    return (
      '$' +
      parseFloat(value).toLocaleString('de-DE', {
        maximumFractionDigits,
        minimumFractionDigits
      })
    )
  } else {
    return (
      '$' +
      parseFloat(value).toLocaleString('en-US', {
        maximumFractionDigits,
        minimumFractionDigits
      })
    )
  }
}

export const formatToUSD = (value) => {
  if (value) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 4
    })
    // $ 4,200.50 USD
    const formattedValue = formatter.format(value)
    // replace , for . and . for ,
    // $ 4.200,50 USD
    const replacedValue = formattedValue
      .replace(/,/g, 'temp')
      .replace(/\./g, ',')
      .replace(/temp/g, '.')
    return replacedValue
  } else {
    return '-'
  }
}

export const formatToCLP = (value) => {
  if (value) {
    const formatter = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    return formatter.format(value)
  } else {
    return '-'
  }
}

export const formatToCLP2decimals = (value) => {
  if (value) {
    const formatter = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    return formatter.format(value)
  } else {
    return '-'
  }
}
