import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Slide from '@material-ui/core/Slide'
import { Alert, AlertTitle } from '@material-ui/lab'

import { wizardUseStyles } from 'components/RegistrationPage/Wizard/wizardUseStyles'

import { processConfirmation } from 'axios/requests/wizard'

export default function ConfirmationPage(props) {
  const classes = wizardUseStyles()
  const { params } = props.computedMatch
  const [confirmation, setConfirmation] = useState(false)
  const [error, setError] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push('/')
    }, 5000)
    const resultProcessConfirmation = async () => {
      const formData = new FormData()
      formData.append('json', JSON.stringify({ token: params.token }))
      try {
        const { data } = await processConfirmation(formData)
        return data
      } catch (err) {
        console.log(err)
      }
    }
    resultProcessConfirmation()
      .then((data) => {
        setError(data.success)
        if (data.success) {
          setConfirmation('Su cuenta ha sido correctamente confirmada')
        } else {
          setConfirmation('Error en la confirmación de su cuenta')
        }
        return () => clearTimeout(timer)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [history, params.token])

  return (
    <div className={classes.root}>
      <div>
        <h1>Confirmación de Cuenta</h1>
      </div>
      <Slide direction="left" in mountOnEnter unmountOnExit>
        <Box mx="auto" my={1}>
          <Container maxWidth="xs">
            {confirmation !== '' ? (
              <>
                <Alert severity={error ? 'success' : 'error'}>
                  <AlertTitle>{confirmation}</AlertTitle>
                </Alert>
              </>
            ) : (
              <>
                <Alert severity="info">
                  <AlertTitle>confirming account...</AlertTitle>
                </Alert>
              </>
            )}
          </Container>
        </Box>
      </Slide>
    </div>
  )
}
