import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import DownloadButton from 'utils/UI/Buttons/DownloadButton'

import { useEffect, useState } from 'react'

import AbaqusLogo from 'assets/images/abaqus-logo-black.svg'
import { resumePdfReportRequest } from 'axios/requests/message'
import Chart from 'components/ChatPage/Chart'

const useStyles = makeStyles((theme) => ({
  messageLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: 'fit-content',
    maxWidth: '100%',
    // wordBreak: 'break-word',
    marginBottom: '10px',
    whiteSpace: 'pre-line'
  },
  userMessage: {
    backgroundColor: theme.palette.primary.light
  },
  aiMessage: {
    backgroundColor: theme.palette.secondary.light
  },
  avatar: {
    marginRight: '10px'
  }
}))

export default function MessageLabel({ messageId, message, sender }) {
  const classes = useStyles()
  const [chartData, setChartData] = useState(null)
  const [reportData, setReportData] = useState(null)
  const [loadingPDF, setLoadingPDF] = useState(false)

  const getMessageBackgroundColor = () => {
    if (sender === 'human') {
      return classes.userMessage
    } else if (sender === 'bot') {
      return classes.aiMessage
    }
    return null
  }

  const getAvatarIcon = () => {
    if (sender === 'human') {
      return (
        <Avatar className={classes.avatar}>
          <PersonIcon />
        </Avatar>
      )
    } else if (sender === 'ai') {
      return (
        <Avatar className={classes.avatar}>
          {/* Replace with appropriate AI icon */}
          <img
            src={AbaqusLogo}
            alt="AI"
            style={{ width: '100%', height: '100%' }}
          />
        </Avatar>
      )
    }
    return null
  }

  // para saber si el string del message es un json con la data para hacer el grafico
  const isJSONString = (str) => {
    try {
      const parsedValue = JSON.parse(str)
      return typeof parsedValue === 'object' && parsedValue !== null
    } catch (error) {
      return false
    }
  }

  // download statement report pdf
  const downloadResumePdfHandler = async () => {
    setLoadingPDF(true)
    try {
      const response = await resumePdfReportRequest({
        messageId: messageId
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', reportData.fileName) // or any other extension
      document.body.appendChild(link)
      link.click()
      setLoadingPDF(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (isJSONString(message)) {
      const data = JSON.parse(message)
      // Check if data is from a report or a chart
      if (data.file) {
        setReportData(data)
      } else if (data.portfolioData) {
        setChartData(data)
      }
    }
  }, [message])

  return (
    <div className={`${classes.messageLabel} ${getMessageBackgroundColor()}`}>
      {getAvatarIcon()}
      {chartData ? (
        <div>
          {chartData.chartDescription && <p>{chartData.chartDescription}</p>}
          <Chart chartData={chartData} />
        </div>
      ) : reportData ? (
        <div>
          <p>{reportData.description}</p>
          <p>{reportData.fileName}</p>
          {loadingPDF ? (
            <CircularProgress />
          ) : (
            <DownloadButton onClickHandler={downloadResumePdfHandler} />
          )}
        </div>
      ) : (
        <p>{message}</p>
      )}
    </div>
  )
}
