import { useState } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import { hijackLogout } from 'utils/Auth/AuthProvider'

const useStyles = makeStyles((theme) => ({
  bannerRoot: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '100%',
    background: '#F8D7A3'
  }
}))

export default function HijackBanner() {
  const classes = useStyles()
  const [show, setShow] = useState(false)

  const handleHijackLogOut = () => {
    hijackLogout()
    window.location.replace('/dashboard/operations')
  }

  return (
    <Grid container>
      <Grid item xs={8}>
        <Slide appear={false} direction="down" in={!show}>
          <Paper variant="outlined" className={classes.bannerRoot}>
            <Button onClick={() => handleHijackLogOut()}>Release hijack</Button>
          </Paper>
        </Slide>
      </Grid>
      <Grid item xs={4}>
        <Button onClick={() => setShow((prev) => !prev)}>
          {show ? <VisibilityIcon /> : <VisibilityOffIcon />} HIJACKING
        </Button>
      </Grid>
    </Grid>
  )
}
