import { useMediaQuery, useTheme } from '@material-ui/core'

const useResponsive = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return {
    isMobile,
    isDesktop: !isMobile
  }
}

export default useResponsive
