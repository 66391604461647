const lan = 'es'

export const percentageFormatter = (value) => {
  if (!value) {
    return '-'
  } else if (lan === 'es') {
    return (
      parseFloat(value).toLocaleString('de-DE', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
      }) + '%'
    )
  } else {
    return (
      parseFloat(value).toLocaleString('en-US', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
      }) + '%'
    )
  }
}
