import { toast } from 'react-toastify'

const notificationMsg = {
  sendEmail: 'Se ha enviado un email a tu correo',
  credentialError: 'Las credenciales entregadas no son correctas',
  emailNotFound: 'No se ha encontrado una cuenta asociada a ese email',
  hijackLogin: 'Hijack Exitoso',
  hijackLogout: 'Hijack terminado exitosamente',
  generalError: 'Oops, ha habido un error',
  miniBloombergError: 'Ha ocurrido un error al cargar el gráfico',
  savedCorrectly: 'Se ha guardado correctamente',
  deletedCorrectly: 'Se ha borrado correctamente',
  userFlagsError: 'Ha ocurrido un error al obtener los permisos del usuario'
}

export const successNotification = (type) => {
  toast.success(notificationMsg[type], {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined
  })
}

export const errorNotification = (type) => {
  toast.error(notificationMsg[type], {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined
  })
}
