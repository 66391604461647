import AssetBenchmarkSeries from 'components/Graphs/AssetBenchmarkSeries'
import TreeContributionGraph from 'components/Graphs/TreeContribution'
import TreeReturnsGraph from 'components/Graphs/TreeReturns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

export default function Analysis() {
  const { i18n } = useTranslation()
  const [languageKey, setLanguageKey] = useState(i18n.language)

  useEffect(() => {
    setLanguageKey(i18n.language)
  }, [i18n.language])

  return (
    <Auxiliary>
      <TreeContributionGraph key={`tree-contribution-${languageKey}`} />
      <TreeReturnsGraph key={`tree-returns-${languageKey}`} />
      <AssetBenchmarkSeries key={`asset-benchmark-${languageKey}`} />
    </Auxiliary>
  )
}
