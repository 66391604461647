/* eslint-disable react/display-name */
import { useContext, useEffect, useState } from 'react'

import clsx from 'clsx'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import HeaderContext from 'context/headerContext'
import baseStyles from 'utils/UI/BaseStyles'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { monthlyReturnsRequest } from 'axios/requests/analysis'
import DefaultTable from 'utils/UI/Tables/DefaultTable'

export default function MonthlyReturns() {
  const { t } = useTranslation(['dashboard', 'common'])
  const cols = [
    { id: 'year', label: t('Año') },
    { id: 'jan', label: t('Ene') },
    { id: 'feb', label: t('Feb') },
    { id: 'mar', label: t('Mar') },
    { id: 'apr', label: t('Abr') },
    { id: 'may', label: t('May') },
    { id: 'jun', label: t('Jun') },
    { id: 'jul', label: t('Jul') },
    { id: 'aug', label: t('Ago') },
    { id: 'sep', label: t('Sep') },
    { id: 'oct', label: t('Oct') },
    { id: 'nov', label: t('Nov') },
    { id: 'dec', label: t('Dic') },
    { id: 'total', label: t('Total') }
  ]

  const baseClasses = baseStyles()
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [tableData, setTableData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    let mounted = true
    const fetchData = async () => {
      try {
        const result = await monthlyReturnsRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
          portfolio_id: currentPortfolio.id
        })
        return result.data
      } catch (err) {
        console.log(err)
      }
    }

    if (mounted && currentPortfolio && currency) {
      fetchData().then((data) => {
        if (mounted && data?.monthly_returns) {
          setTableData(data.monthly_returns.sort((a, b) => b.year - a.year))
          setLoading(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [currency, currentPortfolio])

  const renderCol = (key) => (row) =>
    (
      <span
        className={clsx({
          [baseClasses.errorColor]: parseFloat(row[key]) < 0
        })}
      >
        {row[key] ? row[key] : '-'}
      </span>
    )

  const colsData = cols.map((col) => ({
    ...col,
    align: col.id === 'year' ? 'left' : 'right',
    bold: col.id === 'year' ? true : false,
    render: col.id !== 'year' ? renderCol(col.id) : undefined
  }))

  const formatValue = (value) =>
    value ? parseFloat(value).toFixed(1) + '%' : '-'

  const rowsData =
    tableData?.map((row) => {
      const formattedValues = row.values.reduce((acc, value, index) => {
        const colId = cols[index + 1].id // +1 para saltar 'year'
        acc[colId] = formatValue(value)
        return acc
      }, {})

      return {
        year: row.year, // Aquí simplemente asignamos el año
        ...formattedValues
      }
    }) || []

  return (
    <CardWrapper
      title={t('Retornos Mensuales')}
      titleFeedback={t('Retornos Mensuales')}
    >
      {tableData && !loading ? (
        <DefaultTable
          colsData={colsData}
          rowsData={rowsData}
          maxHeight={false}
          minWidth={1000}
        />
      ) : (
        <Loading />
      )}
    </CardWrapper>
  )
}
