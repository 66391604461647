import { Box, Grid } from '@material-ui/core'
import React from 'react'
import PortfolioSelectionTable from './PortfolioSelectionTable'
import PortfolioWeightsTable from './PortfolioWeightsTable'
import ReturnsTable from './RebalanceReturnsTable'

const PortfolioTablesComponent = ({
  portfolioWeightsDict,
  selectedPortfolio,
  selectedTickersNamesDict,
  COLORS_GRAPH_ASSETS,
  rebalanceFrequencyDict,
  returnsData,
  setSelectedPortfolio,
  handleEditPortfolio,
  handleSavePortfolio,
  handleDeletePortfolio
}) => (
  <Grid container spacing={2}>
    <Grid item xs={5}>
      <PortfolioSelectionTable
        portfolioWeightsDict={portfolioWeightsDict}
        COLORS_GRAPH_ASSETS={COLORS_GRAPH_ASSETS}
        setSelectedPortfolio={setSelectedPortfolio}
        handleEditPortfolio={handleEditPortfolio}
        handleSavePortfolio={handleSavePortfolio}
        handleDeletePortfolio={handleDeletePortfolio}
      />
    </Grid>
    <Grid item xs={7}>
      <PortfolioWeightsTable
        portfolioWeightsDict={portfolioWeightsDict}
        selectedPortfolio={selectedPortfolio}
        selectedTickersNamesDict={selectedTickersNamesDict}
        COLORS_GRAPH_ASSETS={COLORS_GRAPH_ASSETS}
        rebalanceFrequencyDict={rebalanceFrequencyDict}
      />
    </Grid>
    <Grid item xs={12}>
      <Box mt={2}>
        <ReturnsTable returnsData={returnsData} />
      </Box>
    </Grid>
  </Grid>
)

const PortfolioTables = React.memo(PortfolioTablesComponent)

export default PortfolioTables
