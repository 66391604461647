import { useEffect, useState } from 'react'
import { errorNotification } from 'utils/UI/Notifications/Notifications'

import { Backdrop, Modal } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import {
  GetAssetsRequest,
  GetInstitutionsRequest,
  getDistinctValuesForAssetsFieldsRequest
} from 'axios/requests/operations'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'
import AssetsTable from './AssetsTable'

const AssetsColsData = [
  {
    id: 'ticker',
    label: 'Ticker'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'administrator',
    label: 'Administrator'
  },
  {
    id: 'dividend_ticker',
    label: 'Dividend Ticker'
  },
  {
    id: 'exchange',
    label: 'Exchange'
  },
  {
    id: 'currency',
    label: 'Currency'
  },
  {
    id: 'risk_currency',
    label: 'Risk Currency'
  },
  {
    id: 'tax_currency',
    label: 'Tax Currency'
  },
  {
    id: 'macro_asset',
    label: 'Macro Asset'
  },
  {
    id: 'data_source',
    label: 'Data Source'
  },
  {
    id: 'fund_type',
    label: 'Fund Type'
  },
  {
    id: 'fund_series',
    label: 'Fund Series'
  },
  {
    id: 'start_date',
    label: 'Start Date'
  },
  {
    id: 'end_date',
    label: 'End Date'
  },
  {
    id: 'default_price',
    label: 'Default Price'
  },
  {
    id: 'withholding_tax',
    label: 'Withholding Tax'
  },
  {
    id: 'dividend_payment',
    label: 'Dividend Payment'
  },
  {
    id: 'boerse_id',
    label: 'Boerse ID'
  },
  {
    id: 'osa_ticker',
    label: 'OSA Ticker'
  }
]

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  duplicatedDivsContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(10)
  },
  holdingsDivsBalanceContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4)
  },
  fullscreenContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Set the background color to a light color
    borderRadius: 8, // Adjust as needed
    padding: theme.spacing(2), // Add padding as needed
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.drawer + 1 // Ensure the fullscreen component is above the sidebar
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function AssetsCreation() {
  const classes = useStyles()
  const [rowsData, setRowsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [institutions, setInstitutions] = useState([])
  const [loadingInstitutions, setLoadingInstitutions] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)
  const [assetsFieldsDistinctValues, setAssetsFieldsDistinctValues] =
    useState(null)

  // edit asset rows
  const [editedRows, setEditedRows] = useState({})

  // create asset rows
  const [createdRows, setCreatedRows] = useState({})

  const handleFullscreenToggle = () => {
    setFullscreen(!fullscreen)
  }

  useEffect(() => {
    let didCancel = false
    setLoading(true)
    setRowsData([])
    const fetchData = async () => {
      try {
        const response = await GetAssetsRequest()
        if (!didCancel) {
          const data = []
          response.data.forEach((row) => {
            data.push({
              ticker: row.ticker || '', // If row.ticker is null, use an empty string
              name: row.name || '',
              administrator:
                {
                  code: row.administrator_code,
                  name: row.administrator_name
                } || '',
              dividend_ticker: row.dividend_ticker || '',
              exchange: row.exchange || '',
              currency: row.currency || '',
              risk_currency: row.risk_currency || '',
              tax_currency: row.tax_currency || '',
              macro_asset: row.macro_asset || '',
              data_source: row.data_source || '',
              fund_type: row.fund_type || '',
              fund_series: row.fund_series || '',
              start_date: row.start_date || '',
              end_date: row.end_date || '',
              default_price: row.default_price || '',
              withholding_tax: row.withholding_tax || '',
              dividend_payment: row.dividend_payment || '',
              boerse_id: row.boerse_id || '',
              osa_ticker: row.osa_ticker || ''
            })
          })
          setRowsData(data)
          setLoading(false)
        }
      } catch (error) {
        if (!didCancel) {
          setLoading(false)
          errorNotification('generalError')
        }
      }
    }
    fetchData()
    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    let didCancel = false
    setLoadingInstitutions(true)
    const fetchData = async () => {
      try {
        const response = await GetInstitutionsRequest()
        if (!didCancel) {
          const data = []
          response.data.forEach((row) => {
            data.push({
              code: row.code,
              name: row.name
            })
          })
          data.push({ code: '', name: '' })
          setInstitutions(data)
          setLoadingInstitutions(false)
        }
      } catch (error) {
        if (!didCancel) {
          setLoadingInstitutions(false)
          errorNotification('generalError')
        }
      }
    }
    fetchData()
    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      try {
        const response = await getDistinctValuesForAssetsFieldsRequest()
        if (!didCancel) {
          setAssetsFieldsDistinctValues(response.data.fields)
        }
      } catch (error) {
        if (!didCancel) {
          console.log(error)
          errorNotification('generalError')
        }
      }
    }
    fetchData()
    return () => {
      didCancel = true
    }
  }, [])

  const assetsTable = (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
      <Grid item xs={12}>
        {loading || loadingInstitutions ? (
          <Loading />
        ) : (
          <>
            {rowsData && rowsData.length > 0 && assetsFieldsDistinctValues && (
              <AssetsTable
                colsData={AssetsColsData}
                rowsData={rowsData}
                setRowsData={setRowsData}
                assetAdministratorInstitutionOptions={institutions}
                assetsFieldsDistinctValues={assetsFieldsDistinctValues}
                setAssetsFieldsDistinctValues={setAssetsFieldsDistinctValues}
                editedRows={editedRows}
                setEditedRows={setEditedRows}
                createdRows={createdRows}
                setCreatedRows={setCreatedRows}
                isFullscreen={fullscreen}
                showFullscreenButton={true}
                onTogleFullscreen={handleFullscreenToggle}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )

  return (
    <div className={classes.holdingsDivsBalanceContainer}>
      <CardWrapper title="Assets" titleFeedback="Assets">
        {fullscreen ? (
          // Fullscreen render
          <>
            <Backdrop open={fullscreen} />
            <Modal
              open={fullscreen}
              onClose={handleFullscreenToggle}
              className={classes.modal}
            >
              <div className={classes.fullscreenContainer}>{assetsTable}</div>
            </Modal>
          </>
        ) : (
          // Normal render. Same component as above, but without the fullscreen container
          assetsTable
        )}
      </CardWrapper>
    </div>
  )
}
