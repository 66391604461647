import { InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'

import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Divider from '@mui/material/Divider'
import {
  CreateAssetRequest,
  GetAssetClassesCodesRequest
} from 'axios/requests/operations'
import { useEffect, useState } from 'react'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'
import { COLORS_GRAPH_ASSETS } from 'utils/UI/Theme'

const useStyles = makeStyles({
  composition: {
    padding: '16px 0',
    borderBottom: '1px solid #ddd'
  },
  compositionLabel: {
    fontWeight: 'bold',
    marginBottom: 8
  }
})

export default function AssetCompositionsCreateDialog({
  open,
  setOpen,
  newAssetData,
  setNewAssetData,
  assetAdministratorInstitutionOptions,
  assetFieldsPredefinedValues,
  setIsAssetCreated
}) {
  const classes = useStyles()
  const theme = useTheme()
  const [assetClasses, setAssetClasses] = useState([{ code: '', name: '' }])
  const [assetClassCodesOptions, setAssetClassCodesOptions] = useState([])
  const [weights, setWeights] = useState([''])
  const [date, setDate] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [assetFieldsErrors, setAssetFieldsErrors] = useState({})

  const handleClose = () => {
    setIsAssetCreated(false)
    setOpen(false)
  }
  const handleSaved = () => {
    setIsAssetCreated(true)
    setOpen(false)
  }

  const getPredefinedValues = (field) => {
    const predefinedValues = assetFieldsPredefinedValues[field].map(
      (value, index) => {
        if (index === 2 && field.includes('currency')) {
          return [
            <MenuItem key={index} value={value}>
              {' '}
              {value === '' ? '---' : value}{' '}
            </MenuItem>,
            <Divider key="divider" />
          ]
        }
        return (
          <MenuItem key={index} value={value}>
            {value === '' ? '---' : value}
          </MenuItem>
        )
      }
    )
    return predefinedValues
  }

  const checkAssetFieldsErrors = () => {
    const errors = {}
    const notRequiredFields = [
      'exchange',
      'default_price',
      'withholding_tax',
      'dividend_ticker',
      'end_date',
      'boerse_id',
      'osa_ticker',
      'fund_series',
      'fund_type'
    ]
    for (const field in newAssetData) {
      if (!notRequiredFields.includes(field)) {
        if (newAssetData[field] === '' || newAssetData[field]?.code === '') {
          errors[field] = 'requerido'
        }
      }
    }
    // check for ticker uniqness
    if (newAssetData.ticker) {
      if (assetFieldsPredefinedValues.ticker.includes(newAssetData.ticker)) {
        errors.ticker = 'El ticker ya existe'
      }
    }

    // check for default price is a number
    if (newAssetData.default_price) {
      const cleanedDefaultPrice = newAssetData.default_price
        .replace(/,/g, '')
        .replace(/\./g, '.')
      if (!isNaN(cleanedDefaultPrice) === false) {
        errors.default_price = 'Debe ser un número'
      }
    }

    // check for withholding tax is a number
    if (newAssetData.withholding_tax) {
      if (
        !isValidDecimal(newAssetData.withholding_tax) &&
        newAssetData.withholding_tax !== '' &&
        newAssetData.withholding_tax !== '0'
      ) {
        errors.withholding_tax = 'Debe ser un decimal entre 0 y 1'
      }
    }

    setAssetFieldsErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleWeightChange = (event, index) => {
    const updatedWeights = [...weights]
    updatedWeights[index] = event.target.value
    setWeights(updatedWeights)
  }

  const isValidDecimal = (str) => {
    // Check if input is a string
    if (typeof str !== 'string') {
      return false
    }

    // Check if contains any letters
    if (/[a-zA-Z]/.test(str)) {
      return false
    }

    // Check numeric values
    const num = parseFloat(str)
    if (isNaN(num) || num < 0 || num > 1) {
      return false
    }

    // Finally check format
    const regex = /^0([.,]\d+)$/
    return regex.test(str)
  }

  const isSumEqualToOne = (weights) => {
    const sum = weights.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    )
    return Math.abs(sum - 1) < Number.EPSILON // Using epsilon for floating-point precision
  }

  const areNumbers = (values) => {
    return !values.some((value) => {
      return typeof value !== 'number' || isNaN(value)
    })
  }

  const handleSubmitNewAssetAndCompositions = async () => {
    setError('')
    const newAssetClassesCodes = assetClasses.map(
      (assetClass) => assetClass.code
    )

    const newWeights = weights.map((weight) =>
      typeof weight === 'string' ? parseFloat(weight.replace(',', '.')) : weight
    )
    if (checkAssetFieldsErrors() === false) {
      setError('Faltan campos requeridos')
      return
    }

    if (!areNumbers(newWeights)) {
      setError('Los weights deben ser números entre 0 y 1')
      return
    }
    if (!isSumEqualToOne(newWeights)) {
      setError('La suma de los weights debe sumar 1')
      return
    }
    if (newAssetClassesCodes.includes('')) {
      setError('Falta por seleccionar: Asset Class')
      return
    }
    setLoading(true)
    // make request for create asset

    try {
      await CreateAssetRequest({
        ticker: newAssetData.ticker,
        name: newAssetData.name,
        administrator_code: newAssetData.administrator.code,
        dividend_ticker:
          newAssetData.dividend_ticker === ''
            ? null
            : newAssetData.dividend_ticker,
        exchange: newAssetData.exchange,
        currency: newAssetData.currency,
        risk_currency: newAssetData.risk_currency,
        tax_currency: newAssetData.tax_currency,
        macro_asset: newAssetData.macro_asset,
        data_source: newAssetData.data_source,
        fund_type: newAssetData.fund_type,
        fund_series: newAssetData.fund_series,
        start_date: newAssetData.start_date,
        end_date: newAssetData.end_date === '' ? null : newAssetData.end_date,
        default_price:
          newAssetData.default_price === '' ? null : newAssetData.default_price,
        withholding_tax:
          newAssetData.withholding_tax === ''
            ? 0.0
            : parseFloat(newAssetData.withholding_tax.replace(',', '.')),
        dividend_payment: newAssetData.dividend_payment,
        boerse_id:
          newAssetData.boerse_id === '' ? null : newAssetData.boerse_id,
        osa_ticker: newAssetData.osa_ticker,
        asset_class_codes: newAssetClassesCodes,
        asset_composition_weights: newWeights,
        asset_composition_date: date
      })
      successNotification('savedCorrectly')
      setLoading(false)
      handleSaved() // Close the dialog after successful submission
    } catch (error) {
      setLoading(false)
      errorNotification('generalError')
    }
  }

  useEffect(() => {
    checkAssetFieldsErrors()
  }, [newAssetData])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetAssetClassesCodesRequest()
        // add an empty option for new compositions
        response.data.asset_classes_codes.push({ code: '', name: '' })
        setAssetClassCodesOptions(response.data.asset_classes_codes)
      } catch (error) {
        errorNotification('generalError')
      }
    }

    fetchData()
  }, [])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Grid
            container
            spacing={2}
            style={{
              padding: '10px'
            }}
          >
            <Grid item xs={3}>
              <TextField
                label="Ticker"
                value={newAssetData?.ticker}
                onChange={(e) =>
                  setNewAssetData({ ...newAssetData, ticker: e.target.value })
                }
                helperText={assetFieldsErrors?.ticker}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!assetFieldsErrors?.ticker ? (
                        <CheckIcon style={{ color: 'green' }} />
                      ) : (
                        <ClearIcon style={{ color: 'red' }} />
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Name"
                value={newAssetData?.name}
                onChange={(e) =>
                  setNewAssetData({ ...newAssetData, name: e.target.value })
                }
                helperText={assetFieldsErrors?.name}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!assetFieldsErrors?.name ? (
                        <CheckIcon style={{ color: 'green' }} />
                      ) : (
                        <ClearIcon style={{ color: 'red' }} />
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={5}>
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                label="Selecciona una Institución:"
                variant="outlined"
                placeholder="Institución"
                margin="normal"
                size="small"
                options={assetAdministratorInstitutionOptions}
                getOptionLabel={(institution) => {
                  if (institution.code === '') {
                    return ''
                  } else {
                    return `[${institution.code}] ${institution.name}`
                  }
                }}
                style={{ width: 280 }}
                value={newAssetData?.administrator}
                getOptionSelected={(option, value) =>
                  option.code === value.code
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setNewAssetData({
                      ...newAssetData,
                      administrator: newValue
                    })
                  } else {
                    setNewAssetData({
                      ...newAssetData,
                      administrator: { code: '', name: '' }
                    })
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona una Institución:"
                    variant="outlined"
                    placeholder="Institución"
                    helperText={
                      assetFieldsErrors?.administrator ? (
                        <ClearIcon style={{ color: 'red' }} />
                      ) : (
                        <CheckIcon style={{ color: 'green' }} />
                      )
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Dividend Ticker"
                value={newAssetData?.dividend_ticker}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    dividend_ticker: e.target.value
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="exchange-label">Exchange</InputLabel>
              <Select
                value={newAssetData?.exchange}
                onChange={(e) =>
                  setNewAssetData({ ...newAssetData, exchange: e.target.value })
                }
              >
                {getPredefinedValues('exchange')}
              </Select>
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                value={newAssetData?.currency}
                onChange={(e) =>
                  setNewAssetData({ ...newAssetData, currency: e.target.value })
                }
              >
                {getPredefinedValues('currency')}
              </Select>
              {!assetFieldsErrors?.currency ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <ClearIcon style={{ color: 'red' }} />
              )}
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="risk-currency-label">Risk Currency</InputLabel>
              <Select
                value={newAssetData?.risk_currency}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    risk_currency: e.target.value
                  })
                }
              >
                {getPredefinedValues('risk_currency')}
              </Select>
              {!assetFieldsErrors?.risk_currency ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <ClearIcon style={{ color: 'red' }} />
              )}
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="tax-currency-label">Tax Currency</InputLabel>
              <Select
                value={newAssetData?.tax_currency}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    tax_currency: e.target.value
                  })
                }
              >
                {getPredefinedValues('tax_currency')}
              </Select>
              {!assetFieldsErrors?.tax_currency ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <ClearIcon style={{ color: 'red' }} />
              )}
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="macro-asset-label">Macro Asset</InputLabel>
              <Select
                value={newAssetData?.macro_asset}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    macro_asset: e.target.value
                  })
                }
              >
                {getPredefinedValues('macro_asset')}
              </Select>

              {!assetFieldsErrors?.macro_asset ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <ClearIcon style={{ color: 'red' }} />
              )}
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="data-source-label">Data Source</InputLabel>
              <Select
                value={newAssetData?.data_source}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    data_source: e.target.value
                  })
                }
              >
                {getPredefinedValues('data_source')}
              </Select>
              {!assetFieldsErrors?.data_source ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <ClearIcon style={{ color: 'red' }} />
              )}
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="fund-type-label">Fund Type</InputLabel>
              <Select
                value={newAssetData?.fund_type}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    fund_type: e.target.value
                  })
                }
              >
                {getPredefinedValues('fund_type')}
              </Select>
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Fund Series"
                value={newAssetData?.fund_series}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    fund_series: e.target.value
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="start-date-label">Start Date</InputLabel>
              <TextField
                type="date"
                value={newAssetData?.start_date}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    start_date: e.target.value
                  })
                }
              />
              {!assetFieldsErrors?.start_date ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <ClearIcon style={{ color: 'red' }} />
              )}
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="end-date-label">End Date</InputLabel>
              <TextField
                type="date"
                value={newAssetData?.end_date}
                onChange={(e) =>
                  setNewAssetData({ ...newAssetData, end_date: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Default Price"
                value={newAssetData?.default_price}
                onChange={(e) => {
                  setNewAssetData({
                    ...newAssetData,
                    default_price: e.target.value
                  })
                }}
                helperText={assetFieldsErrors?.default_price}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!assetFieldsErrors?.default_price ? (
                        newAssetData?.default_price !== '' && (
                          <CheckIcon style={{ color: 'green' }} />
                        )
                      ) : (
                        <ClearIcon style={{ color: 'red' }} />
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Withholding Tax"
                value={newAssetData?.withholding_tax}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    withholding_tax: e.target.value
                  })
                }
                helperText={assetFieldsErrors?.withholding_tax}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!assetFieldsErrors?.withholding_tax ? (
                        newAssetData?.withholding_tax !== '' && (
                          <CheckIcon style={{ color: 'green' }} />
                        )
                      ) : (
                        <ClearIcon style={{ color: 'red' }} />
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="dividend-payment-label">
                Dividend Payment
              </InputLabel>
              <Select
                value={newAssetData?.dividend_payment}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    dividend_payment: e.target.value
                  })
                }
              >
                {getPredefinedValues('dividend_payment')}
              </Select>

              {!assetFieldsErrors?.dividend_payment ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <ClearIcon style={{ color: 'red' }} />
              )}
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Boerse ID"
                value={newAssetData?.boerse_id}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    boerse_id: e.target.value
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="OSA Ticker"
                value={newAssetData?.osa_ticker}
                onChange={(e) =>
                  setNewAssetData({
                    ...newAssetData,
                    osa_ticker: e.target.value
                  })
                }
              />
            </Grid>
          </Grid>
          <Divider />
          <DialogTitle id="form-dialog-title">
            Asset Composition de: {newAssetData?.ticker}
          </DialogTitle>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {newAssetData?.name}
          </Typography>
          {error.length > 0 && <Alert severity="error">{error}</Alert>}
          <InputLabel id="date-label">Composition Date</InputLabel>
          <TextField
            id="date"
            type="date"
            fullWidth
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          {assetClasses.map((assetClass, index) => (
            <div key={index} className={classes.composition}>
              <Typography variant="h6" className={classes.compositionLabel}>
                Composition {index + 1}
              </Typography>
              <Autocomplete
                id={`assetClass-${index}`}
                fullWidth
                label="Selecciona un Asset Class:"
                variant="outlined"
                placeholder="Asset Class"
                margin="normal"
                size="small"
                options={assetClassCodesOptions}
                getOptionLabel={(assetClass) => {
                  if (assetClass.code === '') {
                    return ''
                  } else {
                    return `[${assetClass.code}] ${assetClass.name}`
                  }
                }}
                value={assetClasses[index]}
                getOptionSelected={(option, value) =>
                  option.code === value.code
                }
                onChange={(event, newValue) => {
                  // handle cancel assetclass
                  if (newValue === null) {
                    newValue = { code: '', name: '' }
                  }
                  const updatedAssetClasses = [...assetClasses]
                  updatedAssetClasses[index] = newValue
                  setAssetClasses(updatedAssetClasses)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona un Asset Class:"
                    variant="outlined"
                    placeholder="Asset Classes"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((assetClass, index) => (
                    <Tooltip title={assetClass.code} key={index}>
                      <Chip
                        style={{
                          marginRight: 12,
                          color: lighten(
                            [
                              theme.palette.primary.main,
                              ...COLORS_GRAPH_ASSETS
                            ][index],
                            0.5
                          )
                            ? '#fff'
                            : '#000'
                        }}
                        size="small"
                        label={`[${assetClass.code}] ${assetClass.name}`}
                        key={assetClass.code}
                        classes={{ deleteIcon: classes.deleteIcon }}
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))
                }
              />

              <TextField
                margin="dense"
                id={`weight-${index}`}
                label="Weight"
                type="text"
                fullWidth
                value={weights[index]}
                onChange={(event) => {
                  handleWeightChange(event, index)
                }}
              />
              {index > 0 && (
                <IconButton
                  onClick={() => {
                    setAssetClasses((prev) =>
                      prev.filter((_, i) => i !== index)
                    )
                    setWeights((prev) => prev.filter((_, i) => i !== index))
                  }}
                  color="secondary"
                >
                  <CancelIcon />
                </IconButton>
              )}
            </div>
          ))}
          <Button
            onClick={() => {
              setAssetClasses((prev) => [...prev, { code: '', name: '' }])
              setWeights((prev) => [...prev, ''])
            }}
            color="primary"
          >
            Agregar
          </Button>
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '18px',
            marginBottom: '18px',
            marginLeft: '24px',
            marginRight: '24px'
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleSubmitNewAssetAndCompositions}
              style={{
                backgroundColor: 'green',
                color: 'white',
                filter: 'saturate(50%)'
              }}
            >
              Guardar
            </Button>
          )}
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: 'red',
              color: 'white',
              filter: 'saturate(50%)'
            }}
          >
            Cancelar
          </Button>
        </div>
      </Dialog>
    </div>
  )
}
