import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CloseIcon from '@material-ui/icons/Close'
import { PlaidUpdateLink } from 'axios/requests/integrations'
import {
  GetPlaidConnectionMatchedAccounts,
  GetUserPlaidConnections,
  UpdatePlaidConnectionAccess
} from 'axios/requests/operations'
import { useCallback, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'
const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  },
  button: {
    margin: theme.spacing(1)
  }
}))

const PlaidUserConnections = () => {
  const classes = useStyles()
  const [linkToken, setLinkToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [openAccountsDialog, setOpenAccountsDialog] = useState(false)
  const [
    selectedConnectionToShowAccounts,
    setSelectedConnectionToShowAccounts
  ] = useState(null)
  const [
    selectedConnectionIdToUpdateAccess,
    setSelectedConnectionIdToUpdateAccess
  ] = useState(null)
  const [Connections, setConnections] = useState([])
  const [isLoadingConnections, setIsLoadingConnections] = useState(true)
  const [isLoadingMatchedAccounts, setIsLoadingMatchedAccounts] = useState(true)
  const [matchedAccounts, setMatchedAccounts] = useState([])
  const [loadingLinkToken, setLoadingLinkToken] = useState(false)
  const onSuccess = useCallback(async () => {
    UpdatePlaidConnectionAccess({
      connection_id: selectedConnectionIdToUpdateAccess
    })
      .then(async () => {
        setSelectedConnectionIdToUpdateAccess(null)
        await refreshConnections()
        successNotification('savedCorrectly')
      })
      .catch((error) => {
        errorNotification('generalError')
        console.error('Error updating connection access:', error)
      })
      .finally(() => {
        setLoading(false)
        setLoadingLinkToken(false)
      })
  }, [selectedConnectionIdToUpdateAccess])

  const onExit = useCallback(() => {
    setLoading(false)
    setLoadingLinkToken(false)
  }, [])

  const fetchUpdateLinkToken = async (connection_id) => {
    setSelectedConnectionIdToUpdateAccess(connection_id)
    setLoadingLinkToken(true)
    try {
      const response = await PlaidUpdateLink({ connection_id })
      setLinkToken(response.data.link_token)
    } catch (error) {
      console.error('Error updating link token:', error)
    }
  }
  const refreshConnections = async () => {
    const response = await GetUserPlaidConnections({})
    setConnections(response.data)
  }

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit
  })

  useEffect(() => {
    if (linkToken && ready) {
      open()
    }
  }, [linkToken, ready, open])

  useEffect(() => {
    setIsLoadingConnections(true)
    refreshConnections().then(() => {
      setIsLoadingConnections(false)
    })
  }, [])

  const handleOpenAccountsDialog = (connection) => {
    setSelectedConnectionToShowAccounts(connection)
    setOpenAccountsDialog(true)
  }
  useEffect(() => {
    setIsLoadingMatchedAccounts(true)
    if (selectedConnectionToShowAccounts !== null) {
      GetPlaidConnectionMatchedAccounts({
        connection_id: selectedConnectionToShowAccounts.id
      })
        .then((response) => {
          setMatchedAccounts(response.data)
        })
        .finally(() => {
          setIsLoadingMatchedAccounts(false)
        })
    } else {
      setMatchedAccounts([])
      setIsLoadingMatchedAccounts(false)
    }
  }, [selectedConnectionToShowAccounts])

  return (
    <>
      {isLoadingConnections ? (
        <CircularProgress />
      ) : Connections.length > 0 ? (
        <div>
          <div>
            <Typography>
              Para conectar una cuenta bancaria, por favor solicite a un
              administrador que te asigne credenciales.
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Institucion</TableCell>
                    <TableCell>Cuentas</TableCell>
                    <TableCell>Actualizar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Connections?.map((connection) => (
                    <TableRow key={connection.id}>
                      <TableCell>{connection.institution_name}</TableCell>
                      <TableCell>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => handleOpenAccountsDialog(connection)}
                          >
                            Ver Cuentas
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        {loadingLinkToken ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => fetchUpdateLinkToken(connection.id)}
                          >
                            Actualizar Credencial en Plaid
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Dialog open={openAccountsDialog}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <DialogTitle style={{ textAlign: 'center', width: '80%' }}>
                Cuentas
              </DialogTitle>
              <IconButton
                aria-label="close"
                style={{ width: '10%' }}
                onClick={() => {
                  setSelectedConnectionToShowAccounts(null)
                  setMatchedAccounts([])
                  setOpenAccountsDialog(false)
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <DialogContent>
              {isLoadingMatchedAccounts ? (
                <CircularProgress />
              ) : (
                <div>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Nombre Oficial</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {matchedAccounts.map((account) => (
                          <TableRow key={account.id}>
                            <TableCell>{account.plaid_account_name}</TableCell>
                            <TableCell>
                              {account.plaid_account_official_name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default PlaidUserConnections
