import {
  DuplicateDividendsRequest,
  ListDisabledDividendsRequest,
  ListValidatedDividendsRequest
} from 'axios/requests/operations'
import { useEffect, useMemo, useState } from 'react'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import Loading from 'utils/UI/Loading'
import { formatToCLP, formatToUSD } from 'utils/formatters/currencyFormatters'
import { decimalFormatter } from 'utils/formatters/numberFormatters'

import { Backdrop, Checkbox, Modal } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import Alert from '@material-ui/lab/Alert'
import {
  DisableDividendsRequest,
  InvalidateDividendsRequest,
  RecoverDividendsRequest,
  UnbalancedDividendsHoldingsResquest,
  ValidateDividendsRequest
} from 'axios/requests/operations'
import HoldingsDividendsTanstackTable from 'components/Graphs/DividendsHoldingsBalanceTable'
import CustomModeButtonGroup from 'utils/UI/Buttons/CustomModeButtonGroup'
import DefaultTable from 'utils/UI/Tables/DefaultTable'

const DuplicatedColsData = [
  {
    id: 'checkbox',
    label: 'Seleccionar'
  },
  {
    id: 'ticker',
    label: 'Ticker',
    bold: true
  },
  {
    id: 'date',
    label: 'Fecha'
  },
  {
    id: 'source',
    label: 'Fuente'
  },
  {
    id: 'amount',
    label: 'Cantidad'
  }
]

const DeletedColsData = [
  {
    id: 'ticker',
    label: 'Ticker',
    bold: true
  },
  {
    id: 'date',
    label: 'Fecha'
  },
  {
    id: 'source',
    label: 'Fuente'
  },
  {
    id: 'amount',
    label: 'Cantidad'
  },
  {
    id: 'action',
    label: 'Acción'
  }
]

const ValidatedColsData = [
  {
    id: 'ticker',
    label: 'Ticker',
    bold: true
  },
  {
    id: 'date',
    label: 'Fecha'
  },
  {
    id: 'source',
    label: 'Fuente'
  },
  {
    id: 'amount',
    label: 'Cantidad'
  },
  {
    id: 'action',
    label: 'Acción'
  }
]

const duplicatedDefaultModeList = [
  {
    key: 'duplicated',
    label: 'Duplicados'
  },
  {
    key: 'deleted',
    label: 'Eliminados'
  },
  {
    key: 'validated',
    label: 'Validados'
  }
]

// holdings dividends balance cols
const colsData = [
  {
    id: 'client',
    label: 'Cliente'
  },
  {
    id: 'account',
    label: 'Cuenta'
  },
  {
    id: 'asset',
    label: 'Asset'
  },
  {
    id: 'date',
    label: 'Fecha'
  },
  {
    id: 'dividend_currency',
    label: 'Moneda DIV'
  },
  {
    id: 'holding_dividends_clp',
    label: 'Dividendo en Holdings[CLP]'
  },
  {
    id: 'actual_dividends_clp',
    label: 'Dividendo real[CLP]'
  },
  {
    id: 'div_times_written_clp',
    label: 'Veces escrito[CLP]'
  },
  {
    id: 'holdings_dividends_usd',
    label: 'Dividendo en Holdings[USD]'
  },
  {
    id: 'actual_dividends_usd',
    label: 'Dividendo real[USD]'
  },
  {
    id: 'div_times_written_usd',
    label: 'Veces escrito[USD]'
  }
]

const balanceDefaultModeList = [
  {
    key: 'significant',
    label: 'Descalces significativos'
  },
  {
    key: 'all',
    label: 'Todos los descalces'
  }
]

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  duplicatedDivsContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(10)
  },
  holdingsDivsBalanceContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4)
  },
  fullscreenContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Set the background color to a light color
    borderRadius: 8, // Adjust as needed
    padding: theme.spacing(2), // Add padding as needed
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.drawer + 1 // Ensure the fullscreen component is above the sidebar
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function DuplicateDividends() {
  const classes = useStyles()
  const [rowsData, setRowsData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingValidateDividends, setLoadingValidateDividends] =
    useState(false)
  const [loadingDisableDividends, setLoadingDisableDividends] = useState(false)
  const [loadingInvalidateDividends, setLoadingInvalidateDividends] =
    useState(false)
  const [loadingRecoverDividends, setLoadingRecoverDividends] = useState(false)
  const [divsMode, setDivsMode] = useState('duplicated')

  // holdings dividends balance states
  const [specificRowsData, setSpecificRowsData] = useState(null)
  const [allRowsData, setAllRowsData] = useState(null)
  const [balanceMode, setBalanceMode] = useState('significant')
  const [loadingUnbalancedHoldings, setLoadingUnbalancedHoldings] =
    useState(true)
  const [fullscreenDividends, setDividendsFullscreen] = useState(false)
  const [fullscreenHoldings, setHoldingsFullscreen] = useState(false)

  const handleFullscreenToggleDivs = () => {
    setDividendsFullscreen(!fullscreenDividends)
  }

  const handleFullscreenToggleHoldings = () => {
    setHoldingsFullscreen(!fullscreenHoldings)
  }
  const setModeHandler = (targetMode) => {
    if (targetMode !== divsMode) {
      setRowsData(null)
    }
    setDivsMode(targetMode)
  }

  const handleChangeCheckbox = (id, checked) => {
    const updatedRowsData = rowsData.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          checked: checked
        }
      }
      return row
    })

    setRowsData(updatedRowsData)
  }

  const handleRecoverDividends = async (id) => {
    setLoadingRecoverDividends(true)

    try {
      await RecoverDividendsRequest({
        dividend_ids: id
      })
      successNotification('recoveredCorrectly')
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingRecoverDividends(false)
  }

  const handleInvalidateDividends = async (id) => {
    setLoadingInvalidateDividends(true)

    try {
      await InvalidateDividendsRequest({
        dividend_ids: id
      })
      successNotification('invalidatedCorrectly')
    } catch (err) {
      console.log(err)
      errorNotification('generalError')
    }
    setLoadingInvalidateDividends(false)
  }

  useEffect(() => {
    if (divsMode === 'duplicated') {
      setRowsData(null)
      let didCancel = false
      const fetchData = async () => {
        try {
          setLoading(true)
          const result = await DuplicateDividendsRequest()
          if (!didCancel) {
            const parsedData = result.data.map((item) => ({
              id: item.id,
              ticker: item.asset,
              date: item.date,
              amount: item.amount,
              source: item.source ? item.source : 'none',
              checked: item.valid
            }))
            setRowsData(parsedData)
            setLoading(false)
          }
        } catch (err) {
          console.log(err)
          errorNotification('generalError')
        }
      }

      if (!loadingValidateDividends) {
        fetchData()
      }

      return () => {
        didCancel = true
      }
    }
  }, [divsMode])

  useEffect(() => {
    if (divsMode === 'deleted') {
      setRowsData(null)
      let didCancel = false
      const fetchData = async () => {
        try {
          setLoading(true)
          const result = await ListDisabledDividendsRequest()
          if (!didCancel) {
            const parsedData = result.data.map((item) => ({
              id: item.id,
              ticker: item.asset,
              date: item.date,
              amount: item.amount,
              source: item.source ? item.source : 'none',
              action: !loadingRecoverDividends ? (
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => handleRecoverDividends(item.id)}
                >
                  Recuperar
                </Button>
              ) : (
                <CircularProgress />
              )
            }))

            setRowsData(parsedData)
            setLoading(false)
          }
        } catch (err) {
          console.log(err)
          errorNotification('generalError')
        }
      }

      if (!loadingRecoverDividends) {
        fetchData()
      }

      return () => {
        didCancel = true
      }
    }
  }, [loadingRecoverDividends, divsMode])

  useEffect(() => {
    if (divsMode === 'validated') {
      setRowsData(null)
      let didCancel = false
      const fetchData = async () => {
        try {
          setLoading(true)
          const result = await ListValidatedDividendsRequest()
          if (!didCancel) {
            const parsedData = result.data.map((item) => ({
              id: item.id,
              ticker: item.asset,
              date: item.date,
              amount: item.amount,
              source: item.source ? item.source : 'none',
              action: !loadingInvalidateDividends ? (
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => handleInvalidateDividends(item.id)}
                >
                  Invalidar
                </Button>
              ) : (
                <CircularProgress />
              )
            }))
            setRowsData(parsedData)
            setLoading(false)
          }
        } catch (err) {
          console.log(err)
          errorNotification('generalError')
        }
      }

      if (!loadingInvalidateDividends) {
        fetchData()
      }

      return () => {
        didCancel = true
      }
    }
  }, [loadingInvalidateDividends, divsMode])

  const updatedRowsData = useMemo(() => {
    if (rowsData && divsMode === 'duplicated') {
      return rowsData.map((item) => ({
        checkbox: (
          <Checkbox
            color="primary"
            size="small"
            checked={item.checked}
            onChange={(e) => {
              if (rowsData) {
                handleChangeCheckbox(item.id, e.target.checked)
              }
            }}
          />
        ),
        id: item.id,
        ticker: item.ticker,
        date: item.date,
        amount: item.amount,
        source: item.source ? item.source : 'none'
      }))
    }
    return []
  }, [rowsData])

  const saveValidDividendsHandler = async () => {
    setLoadingValidateDividends(true)

    try {
      await ValidateDividendsRequest({
        valid_dividend_ids: rowsData
          .filter((row) => row.checked)
          .map((row) => row.id)
          .join(',')
      })
      setRowsData(rowsData.filter((row) => !row.checked))
      successNotification('savedCorrectly')
    } catch (err) {
      console.log(err)
      errorNotification('generalError')
    }
    setLoadingValidateDividends(false)
  }

  const DisableDividendsHandle = async () => {
    setLoadingDisableDividends(true)

    try {
      await DisableDividendsRequest({
        dividend_ids: rowsData
          .filter((row) => row.checked)
          .map((row) => row.id)
          .join(',')
      })
      setRowsData(rowsData.filter((row) => !row.checked))
      successNotification('deletedCorrectly')
    } catch (err) {
      console.log(err)
      errorNotification('generalError')
    }
    setLoadingDisableDividends(false)
  }

  // holdings dividends balance data
  const invertDate = (date) => {
    const dateArray = date.split('-')
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
  }

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      try {
        setLoadingUnbalancedHoldings(true)
        const result = await UnbalancedDividendsHoldingsResquest()
        if (!didCancel) {
          // Initialize arrays to store all data and significant data
          const allData = []
          const significantData = []

          // Loop through result.data.holdings
          result.data.holdings.forEach((item) => {
            const parsedItem = {
              id: item.hid,
              client: item.client_code,
              account: item.account_code,
              asset: item.asset_ticker,
              date: invertDate(item.ex_dividend_date),
              dividend_currency: item.dividend_currency,
              holding_dividends_clp: formatToCLP(item.registered_dividends_clp),
              actual_dividends_clp: formatToCLP(
                item.actual_dividends_amount_clp
              ),
              div_times_written_clp: decimalFormatter(
                item.div_times_written_clp
              ),
              holdings_dividends_usd: formatToUSD(
                item.registered_dividends_usd
              ),
              actual_dividends_usd: formatToUSD(
                item.actual_dividends_amount_usd
              ),
              div_times_written_usd: decimalFormatter(
                item.div_times_written_usd
              )
            }

            // Add the item to the all data array
            allData.push(parsedItem)

            // Check conditions for significant data and add to the significant data array
            // discard minor errors in the dividends
            const isCLP = item.dividend_currency === 'CLP'
            const isUSD = item.dividend_currency === 'USD'

            if (
              (isCLP &&
                (item.div_times_written_clp <= 0.98 ||
                  item.div_times_written_clp >= 1.02)) ||
              (isUSD &&
                (item.div_times_written_usd <= 0.98 ||
                  item.div_times_written_usd >= 1.02))
            ) {
              significantData.push(parsedItem)
            }
          })

          // Set the state with the collected data
          setAllRowsData(allData)
          setSpecificRowsData(significantData)
          setLoadingUnbalancedHoldings(false)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [])

  const duplicatedTableHeader = divsMode === 'duplicated' && (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
      <Grid item>
        <Tooltip title={rowsData?.length ? 'Validar' : ''}>
          {loadingValidateDividends ? (
            <CircularProgress />
          ) : (
            <IconButton
              disabled={!rowsData?.length}
              onClick={saveValidDividendsHandler}
              color="primary"
            >
              <CheckIcon />
            </IconButton>
          )}
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={rowsData?.length ? 'Eliminar' : ''}>
          {loadingDisableDividends ? (
            <CircularProgress />
          ) : (
            <IconButton
              disabled={!rowsData?.length}
              onClick={DisableDividendsHandle}
              color="primary"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Tooltip>
      </Grid>
      {fullscreenDividends ? (
        <Grid item>
          <Tooltip title="Salir pantalla completa">
            <IconButton onClick={handleFullscreenToggleDivs} color="primary">
              <FullscreenExitIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item>
          <Tooltip title="Pantalla completa">
            <IconButton onClick={handleFullscreenToggleDivs} color="primary">
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )

  const duplicatedDivsTable = (
    <Grid container spacing={2} justifyContent="center">
      {loading ||
      (!updatedRowsData && divsMode === 'duplicated') ||
      (!rowsData && (divsMode === 'deleted' || divsMode === 'validated')) ? (
        <Loading />
      ) : (
        <>
          {divsMode === 'deleted' && (
            <Alert severity="warning">
              <strong>¡Atención!</strong> Los dividendos que esten en esta tabla
              no serán considerados para calcular los holdings.
            </Alert>
          )}
          {(!rowsData || rowsData.length === 0) && divsMode === 'deleted' && (
            <p>No hay dividendos eliminados.</p>
          )}
          {(!rowsData || rowsData.length === 0) && divsMode === 'validated' && (
            <p>No hay dividendos validados.</p>
          )}
          {rowsData && rowsData.length > 0 && (
            <>
              <DefaultTable
                header={duplicatedTableHeader}
                colsData={
                  divsMode === 'duplicated'
                    ? DuplicatedColsData
                    : divsMode === 'deleted'
                    ? DeletedColsData
                    : ValidatedColsData
                }
                rowsData={
                  divsMode === 'duplicated' ? updatedRowsData : rowsData
                }
                isFullscreen={fullscreenDividends}
              />
            </>
          )}
        </>
      )}
    </Grid>
  )

  const HoldingsDividendsUnbalancedTable = (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
      <CustomModeButtonGroup
        modeList={balanceDefaultModeList}
        currentValue={balanceMode}
        onClickHandler={setBalanceMode}
      />
      {fullscreenHoldings ? (
        <Grid item>
          <Tooltip title="Salir pantalla completa">
            <IconButton
              onClick={handleFullscreenToggleHoldings}
              color="primary"
            >
              <FullscreenExitIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item>
          <Tooltip title="Pantalla completa">
            <IconButton
              onClick={handleFullscreenToggleHoldings}
              color="primary"
            >
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      <Grid item xs={12}>
        {loadingUnbalancedHoldings || !allRowsData ? (
          <Loading />
        ) : (
          <>
            {balanceMode === 'all' && (
              <HoldingsDividendsTanstackTable
                colsData={colsData}
                rowsData={allRowsData}
                isFullscreen={fullscreenHoldings}
              />
            )}
            {balanceMode === 'significant' && (
              <HoldingsDividendsTanstackTable
                colsData={colsData}
                rowsData={specificRowsData}
                isFullscreen={fullscreenHoldings}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )

  return (
    <div className={classes.contentContainer}>
      <div className={classes.duplicatedDivsContainer}>
        <CardWrapper title="Dividendos duplicados">
          <CustomModeButtonGroup
            modeList={duplicatedDefaultModeList}
            currentValue={divsMode}
            onClickHandler={setModeHandler}
          />
          {fullscreenDividends ? (
            // Fullscreen render
            <>
              <Backdrop open={fullscreenDividends} />
              <Modal
                open={fullscreenDividends}
                onClose={handleFullscreenToggleDivs}
                className={classes.modal}
              >
                <div className={classes.fullscreenContainer}>
                  {duplicatedDivsTable}
                </div>
              </Modal>
            </>
          ) : (
            // Normal render. Same component as above, but without the fullscreen container
            duplicatedDivsTable
          )}
        </CardWrapper>
      </div>

      <div className={classes.holdingsDivsBalanceContainer}>
        <CardWrapper
          title="Holdings con dividendos desbalanceados"
          titleFeedback="Holdings con dividendos desbalanceados"
        >
          {fullscreenHoldings ? (
            // Fullscreen render
            <>
              <Backdrop open={fullscreenHoldings} />
              <Modal
                open={fullscreenHoldings}
                onClose={handleFullscreenToggleHoldings}
                className={classes.modal}
              >
                <div className={classes.fullscreenContainer}>
                  {HoldingsDividendsUnbalancedTable}
                </div>
              </Modal>
            </>
          ) : (
            // Normal render. Same component as above, but without the fullscreen container
            HoldingsDividendsUnbalancedTable
          )}
        </CardWrapper>
      </div>
    </div>
  )
}
