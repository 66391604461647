import { getRequest } from 'axios/request'

export function dashboardReportRequest(params) {
  const url = '/portfolios/data/dashboard-report/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function portfolioHoldingReportRequest(params) {
  const url = '/portfolios/data/portfolio-holdings-report/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function resumePdfReportRequest(params) {
  const url = '/portfolios/statementreport/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function unrealizedGainsReportRequest(params) {
  const url = '/portfolios/reports/unrealized-gains-report/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function realizedGainsReportRequest(params) {
  const url = '/portfolios/reports/realized-gains-report/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}
