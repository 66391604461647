import { useContext, useEffect, useMemo, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import HeaderContext from 'context/headerContext'
import { useTranslation } from 'react-i18next'
import Auxiliary from 'utils/Auxiliary/Auxiliary'
import baseStyles from 'utils/UI/BaseStyles'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import clsx from 'clsx'

import { StyledTableCell, StyledTableContainer } from 'assets/jss/Tables/Tables'
import Search from 'utils/UI/Inputs/Search'
import { percentageFormatter2 } from 'utils/formatters/percentageFormatters2'

const currentYear = new Date().getFullYear()
const lastYear = currentYear - 1
const twoYearsAgo = currentYear - 2
const threeYearsAgo = currentYear - 3

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
})

export default function InstrumentReturnsTable({ data }) {
  const { t } = useTranslation(['dashboard', 'common'])
  const colsData = [
    {
      id: 'ticker',
      label: t('Ticker')
    },
    {
      id: 'name',
      label: t('Nombre')
    },
    {
      id: 'custom',
      label: t('Custom'),
      align: 'right'
    },
    {
      id: 'cagr',
      label: t('CAGR'),
      align: 'right'
    },
    {
      id: 'vol',
      label: t('Vol'),
      align: 'right'
    },
    {
      id: 'm',
      label: t('1M'),
      align: 'right'
    },
    {
      id: 'three_m',
      label: t('3M'),
      align: 'right'
    },
    {
      id: 'ytd',
      label: t('YTD'),
      align: 'right'
    },
    {
      id: `${lastYear}`,
      label: `${lastYear}`,
      align: 'right'
    },
    {
      id: `${twoYearsAgo}`,
      label: `${twoYearsAgo}`,
      align: 'right'
    },
    {
      id: `${threeYearsAgo}`,
      label: `${threeYearsAgo}`,
      align: 'right'
    },
    {
      id: 'y',
      label: '1Y',
      align: 'right'
    },
    {
      id: 'three_y',
      label: '3Y',
      align: 'right'
    },
    {
      id: 'five_y',
      label: '5Y',
      align: 'right'
    }
  ]
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency } = headerState
  const [rowsData, setRowsData] = useState(null)
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(null)
  const baseClasses = baseStyles()
  const [search, setSearch] = useState('')
  const maxWidth = 40

  const tableData = useMemo(() => {
    if (search) {
      return rowsData.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase())
      })
    }
    if (!orderBy) return rowsData
    const sortedData = rowsData.sort((a, b) => {
      if (isNaN(a[orderBy])) {
        return order === 'asc'
          ? a[orderBy].localeCompare(b[orderBy])
          : b[orderBy].localeCompare(a[orderBy])
      } else {
        return order === 'asc'
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy]
      }
    })
    return sortedData
  }, [order, orderBy, rowsData, search])

  useEffect(() => {
    let mounted = true
    if (mounted && data) {
      const parsedData = data.map((item) => ({
        ticker: item.id,
        name: item['name'],
        custom: item.returns.Custom,
        m: item.returns['1M'],
        three_m: item.returns['3M'],
        ytd: item.returns.YTD,
        [lastYear]: item.returns[`${lastYear}`],
        [twoYearsAgo]: item.returns[`${twoYearsAgo}`],
        [threeYearsAgo]: item.returns[`${threeYearsAgo}`],
        y: item.returns['1Y'],
        three_y: item.returns['3Y'],
        five_y: item.returns['5Y'],
        cagr: parseFloat(item.cagr) + 1,
        vol: item.vol && parseFloat(item.vol) + 1
      }))
      setRowsData(parsedData)
    } else {
      setRowsData(null)
    }

    return () => {
      mounted = false
    }
  }, [currency, startDate, endDate, data])
  return (
    <CardWrapper
      title={t('Retornos de activos')}
      action={<Search onChange={setSearch} value={search} />}
    >
      {rowsData ? (
        <Auxiliary>
          <StyledTableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {colsData.map((column, index) => (
                    <StyledTableCell
                      key={column.id.toString() + index.toString()}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => {
                          const newOrder =
                            orderBy === column.id && order === 'asc'
                              ? 'desc'
                              : 'asc'
                          setOrder(newOrder)
                          setOrderBy(column.id)
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {colsData.map((column) => {
                        let value = row[column.id]
                        let displayValue = column.format
                          ? column.format(value)
                          : value
                        if (typeof value === 'number') {
                          value = Math.round(value * 1000) / 1000
                        }
                        if (column.id == 'ticker' || column.id == 'name') {
                          if (value.length > maxWidth) {
                            displayValue = value.substring(0, maxWidth) + '...'
                            return (
                              <StyledTableCell
                                key={column.id.toString() + index.toString()}
                                align={column.align}
                              >
                                <Tooltip title={value}>
                                  <span>{displayValue}</span>
                                </Tooltip>
                              </StyledTableCell>
                            )
                          } else {
                            return (
                              <StyledTableCell
                                key={column.id.toString() + index.toString()}
                                align={column.align}
                              >
                                {column.format ? column.format(value) : value}
                              </StyledTableCell>
                            )
                          }
                        } else {
                          return (
                            <StyledTableCell
                              key={column.id.toString() + index.toString()}
                              align={column.align}
                              className={clsx({
                                [baseClasses.errorColor]:
                                  parseFloat(value - 1) < 0,
                                [baseClasses.successColor]:
                                  parseFloat(value - 1) > 0
                              })}
                            >
                              {value
                                ? percentageFormatter2((value - 1) * 100)
                                : '-'}
                            </StyledTableCell>
                          )
                        }
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <div
            style={{
              textAlign: 'left'
            }}
          >
            <p>
              * {t('Datos para 3Y y 5Y son retornos promedios anualizados')}
            </p>
          </div>
        </Auxiliary>
      ) : (
        <div></div>
      )}
    </CardWrapper>
  )
}
