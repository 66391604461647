import { useCallback, useState } from 'react'
import { createChatMessageRequest } from 'axios/requests/message'

export default function usePostRequest(url) {
  const [result, setResult] = useState({
    data: null,
    error: null,
    isLoading: false // Fixed the typo here, should be "isLoading" instead of "isloading"
  })

  const post = useCallback(
    async (body) => {
      try {
        /* LOADING TRUE */
        setResult((prevState) => ({ ...prevState, isLoading: true }))
        const response = await createChatMessageRequest(body)
        setResult((prevState) => ({
          ...prevState,
          data: {
            status: response.status,
            json: response.data
          }
        }))
      } catch (error) {
        setResult((prevState) => ({ ...prevState, error: error }))
      } finally {
        /* LOADING FALSE */
        setResult((prevState) => ({ ...prevState, isLoading: false }))
      }
    },
    [url]
  )

  return [result, post]
}
