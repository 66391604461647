import { getRequest } from 'axios/request'

export function assetBenchmarkSeriesRequest(params) {
  const url = '/portfolios/data/tree-table/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}
