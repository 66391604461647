import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px' /* Set the desired height and width */,
    width: '40px'
  },
  loader: {
    border: '4px dotted rgba(0, 0, 0, 0.54)',
    borderRadius: '50%',
    width: '32px' /* Adjust the size as needed */,
    height: '32px',
    animation: '$spin 2s linear infinite'
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  }
})

const ChatLoader = () => {
  const classes = useStyles()

  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loader}></div>
    </div>
  )
}

export default ChatLoader
