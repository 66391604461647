import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import AbaqusLogo from 'assets/images/abaqus-logo-black.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  logo: {
    maxWidth: 150,
    maxHeight: 40,
    marginBottom: theme.spacing(2)
  }
}))

export default function NoMessage() {
  const { t } = useTranslation(['dashboard', 'common'])
  const classes = useStyles()

  return (
    <Grid container className={classes.container}>
      <img src={AbaqusLogo} alt="full-logo" className={classes.logo} />
      <p>{t('Inicia una conversación. Puedes preguntar lo que quieras.')}</p>
    </Grid>
  )
}
