// PortfolioActions.jsx
import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import GetAppIcon from '@material-ui/icons/GetApp'
import Grade from '@material-ui/icons/Grade'

const PortfolioActions = ({
  loadingSavedList,
  savedList,
  loadingDownload,
  portfolioWeightsDict,
  onSimulate,
  onSave,
  onDownload
}) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={9} container direction="row" spacing={1}></Grid>
      <Grid item xs={1}>
        <Tooltip title="Simular Portafolio">
          <IconButton color="primary" onClick={onSimulate}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs={1}>
        <Tooltip
          title={
            !loadingSavedList && savedList?.length ? 'Mis Portafolios' : ''
          }
        >
          {loadingSavedList ? (
            <CircularProgress />
          ) : (
            <IconButton
              disabled={!savedList?.length}
              onClick={onSave}
              color="primary"
            >
              <Grade />
            </IconButton>
          )}
        </Tooltip>
      </Grid>

      <Grid item xs={1}>
        <Tooltip
          title={Object.keys(portfolioWeightsDict).length ? 'Descargar' : ''}
        >
          {loadingDownload ? (
            <CircularProgress />
          ) : (
            <IconButton
              disabled={!Object.keys(portfolioWeightsDict).length}
              onClick={onDownload}
              color="primary"
            >
              <GetAppIcon />
            </IconButton>
          )}
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default PortfolioActions
