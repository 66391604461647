import { useEffect, useMemo, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { useTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const useStyles = makeStyles((theme) => ({
  barChart: {
    '& .highcharts-point': {
      animation: '$fadeIn 0.5s ease-in-out'
    }
  },
  goBackButton: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginLeft: 8
    }
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}))

export default function BarChart({
  requestData,
  sumTotal,
  title,
  savedCategory,
  setSavedCategory
}) {
  const theme = useTheme()
  const classes = useStyles()
  const [currentLevel, setCurrentLevel] = useState({ index: 0, names: [] }) // Counter to show current tree level
  const [currentStack, setCurrentStack] = useState([])
  const [baseElements, setBaseElements] = useState(null)
  const [drilldownSeries, setDrilldownSeries] = useState(null)

  useEffect(() => {
    setBaseElements(requestData[0])
    setDrilldownSeries(requestData[1])
    restoreLevelPicked()
  }, [requestData])

  const restoreLevelPicked = () => {
    if (drilldownSeries) {
      const drillDownObj = drilldownSeries.find((elem) => {
        return elem.name === savedCategory
      })
      if (drillDownObj?.data) {
        setCurrentTreePosition(drillDownObj, savedCategory)
      }
    }
  }

  const setCurrentTreePosition = (drillDownObj, name) => {
    const newStack = [...currentStack, drillDownObj.data]
    setCurrentStack(newStack)
    const newLevel = currentLevel.index + 1
    setCurrentLevel({
      index: newLevel,
      names: [...currentLevel.names, name]
    })
  }

  const handleListItemClick = (listItem) => {
    const drillDownObj = drilldownSeries.find((elem) => {
      return elem.name === listItem.name
    })
    if (drillDownObj?.data) {
      setSavedCategory(listItem.name)
      setCurrentTreePosition(drillDownObj, listItem.name)
    } else {
      setSavedCategory('')
    }
  }

  const handlePreviousItems = () => {
    const newStack = [...currentStack]
    newStack.pop()
    setCurrentStack(newStack)
    const newLevel = currentLevel.index - 1
    setCurrentLevel({
      index: newLevel,
      names: currentLevel.names.slice(0, newLevel)
    })
  }

  const chartOptions = useMemo(() => {
    if (!baseElements) return {}
    let series = [
      {
        data: []
      }
    ]
    let data = [...baseElements]
    if (baseElements) {
      if (currentStack.length > 0) {
        data = [...currentStack[currentStack.length - 1]]
        if (sumTotal) {
          data = [
            ...currentStack[currentStack.length - 1],
            {
              name: 'Total',
              y: 0,
              isNested: true
            }
          ]
        }
      } else if (sumTotal) {
        if (!data.some((x) => x.name === 'Total')) {
          data = [...baseElements, { name: 'Total', y: 0 }]
        }
      }
    }

    const categories = data.map((elem) => elem.name)
    series[0].data = data.map((element, i) => {
      const value = Number(element.y.toFixed(2))
      const options = {}
      if (data.length === i + 1 && sumTotal) {
        let sum = 0
        if (data[i].isNested) {
          sum = data.reduce((acc, curr) => acc + curr.y, 0)
        } else {
          sum = data
            .filter((d) => d.name === 'Total')
            .reduce((acc, curr) => acc + curr.y, 0)
        }
        options.y = Number(sum.toFixed(2))
        options.color =
          sum > 0 ? theme.palette.secondary.dark : theme.palette.secondary.light
      } else {
        options.y = value
        options.color =
          value > 0 ? theme.palette.primary.main : theme.palette.info.main
      }
      return {
        ...options
      }
    })

    return {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        spacing: [40, 0, 40, 0]
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category',
        categories: categories,
        startOnTick: true,
        endOnTick: true,
        labels: {
          formatter: function () {
            var tick = this.axis.ticks[this.pos]
            if (tick) {
              tick.label.element.onclick = (e) => {
                e.stopPropagation()
                handleListItemClick(data[this.pos])
              }
            }
            return drilldownSeries.some((elem) => {
              return elem.name === data[this.pos].name
            })
              ? `<span style='text-decoration:underline;cursor:pointer;'>${this.value}</span>`
              : `${this.value}`
          }
        }
      },
      tooltip: {
        shared: true,
        valueDecimals: 2,
        formatter: function () {
          const [point] = this.points
          const pointColor =
            point.y > 0 ? theme.palette.primary.main : theme.palette.info.main
          return (
            '<div>' +
            `<span style="color: ${pointColor}">● </span>` +
            `${point.x}: <b>${point.y.toFixed(2)}</b>` +
            '</div>'
          )
        }
      },
      yAxis: {
        title: {
          text: title
        }
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          borderRadius: 8,
          cursor: 'pointer',
          events: {
            click: (e) => {
              handleListItemClick(data[e.point.index])
            }
          }
        }
      },
      series: series,
      legend: {
        enabled: false
      }
    }
  }, [baseElements, currentStack, currentLevel.index])

  useEffect(() => {
    Highcharts.charts.forEach((chart) => {
      if (chart) {
        chart.reflow()
      }
    })
  }, [currentLevel.index])

  return (
    <div className={classes.barChart}>
      <Grid
        container
        alignItems="center"
        style={{ visibility: currentLevel.index > 0 ? 'visible' : 'hidden' }}
      >
        <IconButton
          onClick={handlePreviousItems}
          className={classes.goBackButton}
          color="primary"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography
          onClick={handlePreviousItems}
          style={{ paddingTop: 2, cursor: 'pointer' }}
          variant="overline"
        >
          {currentLevel.names[currentLevel.index - 1]}
        </Typography>
      </Grid>
      <HighchartsReact
        key={currentLevel.index}
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  )
}
