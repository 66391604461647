import { getRequest } from 'axios/request'

export function GetAllUsersRequest(params) {
  const url = 'users/list-all/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function GetHijackedUserTokenRequest(params) {
  const url = 'token/get-hijack/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}
