import { getRequest, postAuthRequest } from 'axios/request'

export function createChatMessageRequest(params) {
  const url = '/chats/messages/create/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function getUserConversationsRequest() {
  const url = '/chats/conversations/'
  return getRequest(url, {})
}

export function createUserCoversationRequest(params) {
  const url = '/chats/conversations/create/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function getConversationMessagesRequest(params) {
  const url = `/chats/conversations/${params.conversationId}/messages/`
  return getRequest(url, {})
}

export function deleteConversationRequest(params) {
  const url = '/chats/conversations/delete/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function updateConversationNameRequest(params) {
  const url = '/chats/conversations/update/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function resumePdfReportRequest(params) {
  const url = '/chats/message/statement-report/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}
