import { Box, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'

const PortfolioWeightsTableComponent = ({
  portfolioWeightsDict,
  selectedPortfolio,
  selectedTickersNamesDict,
  COLORS_GRAPH_ASSETS,
  rebalanceFrequencyDict
}) => {
  return (
    <Box my={2}>
      <Paper>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="subtitle1" align="left">
                <b>{selectedPortfolio}</b>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" align="right">
                <b>Peso</b>
              </Typography>
            </Grid>
          </Grid>

          {Object.keys(portfolioWeightsDict[selectedPortfolio]).map(
            (ticker, index) => (
              <Grid
                container
                spacing={2}
                key={ticker + index}
                style={{ marginTop: '0.5em' }}
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography variant="body1">
                      <b>{ticker}</b>
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {selectedTickersNamesDict[ticker] || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={9}>
                  <Box display="flex" alignItems="center" width="100%">
                    <Box flexGrow={1} mr={1}>
                      <Box
                        bgcolor={
                          COLORS_GRAPH_ASSETS[
                            index % COLORS_GRAPH_ASSETS.length
                          ] || 'grey'
                        }
                        height={8}
                        borderRadius={4}
                        style={{
                          width: `${
                            portfolioWeightsDict[selectedPortfolio][ticker] || 0
                          }%`,
                          transition: 'width 0.3s ease'
                        }}
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      style={{ minWidth: '40px', textAlign: 'right' }}
                    >
                      <b>
                        {portfolioWeightsDict[selectedPortfolio][ticker] || 0}%
                      </b>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )
          )}

          <Box padding={2} mt={2}>
            <Typography variant="subtitle1">
              <b>Frecuencia de Rebalanceo:</b>{' '}
              {rebalanceFrequencyDict[selectedPortfolio] || 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

const PortfolioWeightsTable = React.memo(PortfolioWeightsTableComponent)

export default PortfolioWeightsTable
