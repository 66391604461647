import { Grid, Hidden, ListItemAvatar, Tooltip } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import BarChart from '@material-ui/icons/BarChart'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FolderIcon from '@material-ui/icons/Folder'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import ListAltIcon from '@material-ui/icons/ListAlt'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import MenuIcon from '@material-ui/icons/Menu'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo'
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ShowChartOutlinedIcon from '@material-ui/icons/ShowChartOutlined'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import TocIcon from '@material-ui/icons/Toc'
import KeyIcon from '@material-ui/icons/VpnKey'
import WorkIcon from '@material-ui/icons/Work'
import clsx from 'clsx'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'

import AbaqusLogo from 'assets/images/abaqus-logo-black.svg'
import AuthContext from 'context/authContext'
import { FlagsContext } from 'context/flagsContext'
import { hijackLogout, logout } from 'utils/Auth/AuthProvider'
import Auxiliary from 'utils/Auxiliary/Auxiliary'
import baseStyles from 'utils/UI/BaseStyles'
import { SidebarContext } from './SidebarContext'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiListItemAvatar-root': {
      minWidth: 0
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.active': {
      '& span': {
        fontWeight: 'bold'
      },
      backgroundColor: '#4645401c'
    }
  },
  menuButtonContainer: {
    marginBottom: theme.spacing(5)
  },
  baseStyle: {
    color: theme.palette.secondary.main
  },
  selectedStyle: {
    color: theme.palette.primary.main
  },
  hide: {
    display: 'none'
  },
  drawerContainer: {
    height: '100%',
    overflow: 'hidden',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: 'red'
  },
  drawerOpen: {
    width: drawerWidth,
    margin: 0,
    padding: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: theme.palette.secondary.main
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(7) + 1
    },
    backgroundColor: theme.palette.secondary.main
  },
  iconList: {
    flexGrow: 1,
    overflowY: 'auto',
    '& > *': {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  bottomList: {
    flexShrink: 0,
    '& > *': {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0
      }
    }
  }
}))

const OperationsItemList = [
  {
    key: 'datasource',
    title: 'Data Sources',
    icon: <LocalHospitalIcon />,
    picker: '/dashboard/operations'
  },
  {
    key: 'holdings',
    title: 'Holdings',
    icon: <TocIcon />,
    picker: '/dashboard/operations/holdings'
  },
  {
    key: 'cash_holdings',
    title: 'Cash Monitor',
    icon: <PersonalVideoIcon />,
    picker: '/dashboard/operations/cash-holdings'
  },
  {
    key: 'dividends',
    title: 'Duplicated Dividends',
    icon: <MonetizationOnIcon />,
    picker: '/dashboard/operations/dividends'
  },
  {
    key: 'splits',
    title: 'Splits',
    icon: <CallSplitIcon />,
    picker: '/dashboard/operations/splits'
  },
  {
    key: 'reports',
    title: 'Reports',
    icon: <FolderIcon />,
    picker: '/dashboard/operations/reports'
  },
  {
    key: 'client_data',
    title: 'Client Data',
    icon: <AccountBoxIcon />,
    picker: '/dashboard/operations/clients'
  },
  {
    key: 'models_CRUD_tables',
    title: 'Models Manager',
    icon: <PlaylistAddIcon />,
    picker: '/dashboard/operations/models-CRUD-tables'
  },
  {
    key: 'portfolio_sort',
    title: 'Portfolio Sort',
    icon: <AutorenewIcon />,
    picker: '/dashboard/operations/portfolio-sort'
  },
  {
    key: 'transactions',
    title: 'Transactions',
    icon: <SwapHorizIcon />,
    picker: '/dashboard/operations/transactions'
  },
  {
    key: 'commands',
    title: 'Commands ',
    icon: <WorkIcon />,
    picker: '/dashboard/operations/commands'
  },
  {
    key: 'comparator',
    title: 'Comparador fondos',
    icon: <ShowChartOutlinedIcon />,
    picker: '/dashboard/operations/comparator',
    flag: null
  },
  {
    key: 'comparator',
    title: 'Simulador Portafolio',
    icon: <EqualizerIcon />,
    picker: '/dashboard/operations/rebalance',
    flag: null
  }
]

export default function SideBar() {
  const { t } = useTranslation(['sidebar'])

  const ItemList = [
    {
      key: 'summary',
      title: t('Resumen'),
      icon: <ListAltIcon />,
      picker: '/dashboard/summary',
      flag: null
    },
    {
      key: 'analysis',
      title: t('Análisis'),
      icon: <BarChart />,
      picker: '/dashboard/analysis',
      flag: null
    },
    {
      key: 'portafolio',
      title: t('Detalle Portafolio'),
      icon: <PieChartOutlinedIcon />,
      picker: '/dashboard/portfolio',
      flag: null
    },
    {
      key: 'assets',
      title: t('Comparador fondos'),
      icon: <ShowChartOutlinedIcon />,
      picker: '/dashboard/assets',
      flag: null
    },
    {
      key: 'movements',
      title: t('Movimientos'),
      icon: <SwapHorizIcon />,
      picker: '/dashboard/movements',
      flag: null
    },
    {
      key: 'documents',
      title: t('Documentación'),
      icon: <InsertDriveFileOutlinedIcon />,
      picker: '/dashboard/documents',
      flag: null
    },
    {
      key: 'reports',
      title: t('Reportes'),
      icon: <BusinessCenterIcon />,
      picker: '/dashboard/reports',
      flag: null
    },
    {
      key: 'chats',
      title: t('Chatbot'),
      icon: <ChatBubbleOutlineOutlinedIcon />,
      picker: '/dashboard/chats',
      flag: 'CHATBOT_FLAG'
    }
  ]

  const baseClasses = baseStyles()
  const { openDrawer: open, handleOpenDrawer: toggle } =
    useContext(SidebarContext)
  const classes = useStyles()
  const { userDetails } = useContext(AuthContext)
  const userFlags = useContext(FlagsContext)
  const handleLogOut = () => {
    if (window.localStorage.getItem('HIJACK_ADMIN_TOKENS')) {
      hijackLogout()
    } else {
      logout()
    }
  }

  const filterItemsByFlag = (itemList, userFlags) => {
    // returns the items in the itemList that have flag = true in the userFlags and the items that have no flag
    return itemList.filter((item) => {
      const flag = item.flag
      return userFlags['flags'][flag] === true || !flag
    })
  }

  const drawerContent =
    userDetails?.role === 'Operations' ? (
      <List className={classes.iconList}>
        <ListItem
          button
          disableRipple
          key="SideDrawerBtn"
          className={clsx(classes.iconContainer, {
            [classes.menuButtonContainer]: true
          })}
        >
          <img src={AbaqusLogo} width={120} alt="full-logo" hidden={!open} />
          <ListItemAvatar
            onClick={() => toggle()}
            className={clsx({
              [baseClasses.bold]: open
            })}
          >
            <MenuIcon />
          </ListItemAvatar>
        </ListItem>
        {OperationsItemList.map((item) => (
          <MenuItem
            button
            disableRipple
            key={item.key}
            component={NavLink}
            className={classes.iconContainer}
            activeClassName="active"
            to={item.picker}
            onClick={() => toggle()}
          >
            <Tooltip placement="bottom-end" title={!open ? item.title : ''}>
              <ListItemIcon>{item.icon}</ListItemIcon>
            </Tooltip>
            <ListItemText primary={item.title} />
          </MenuItem>
        ))}
        <MenuItem
          button
          disableRipple
          className={classes.iconContainer}
          component={Link}
          to="/dashboard/plaid"
        >
          <ListItemIcon>
            <CloudUploadIcon />
          </ListItemIcon>
          <ListItemText primary={t('Conectar a Plaid')} />
        </MenuItem>
        <MenuItem
          button
          disableRipple
          className={classes.iconContainer}
          component={Link}
          to="/change-password"
        >
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText primary={t('Change password')} />
        </MenuItem>
        {!!userDetails?.isStaff && (
          <MenuItem component={Link} to="/dashboard/admin-hijack">
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary="Hijack" />
          </MenuItem>
        )}
        <MenuItem
          onClick={handleLogOut}
          button
          disableRipple
          className={classes.iconContainer}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t('Cerrar Sesión')} />
        </MenuItem>
      </List>
    ) : (
      <>
        <List className={classes.iconList}>
          <ListItem
            button
            disableRipple
            key="SideDrawerBtn"
            className={clsx(classes.iconContainer, {
              [classes.menuButtonContainer]: true
            })}
          >
            <img src={AbaqusLogo} width={120} alt="full-logo" hidden={!open} />
            <ListItemAvatar
              onClick={() => toggle()}
              className={clsx({
                [baseClasses.bold]: open
              })}
            >
              <MenuIcon />
            </ListItemAvatar>
          </ListItem>
          {userFlags &&
            filterItemsByFlag(ItemList, userFlags).map((item) => (
              <MenuItem
                button
                disableRipple
                key={item.key}
                component={NavLink}
                className={classes.iconContainer}
                activeClassName="active"
                to={item.picker}
                onClick={() => toggle()}
              >
                <Tooltip placement="bottom-end" title={!open ? item.title : ''}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                </Tooltip>
                <ListItemText primary={item.title} />
              </MenuItem>
            ))}
        </List>
        <List className={classes.bottomList}>
          <MenuItem
            button
            disableRipple
            className={classes.iconContainer}
            component={Link}
            to="/dashboard/plaid"
          >
            <ListItemIcon>
              <CloudUploadIcon />
            </ListItemIcon>
            <ListItemText primary={t('Conectar a Plaid')} />
          </MenuItem>
          <MenuItem
            button
            disableRipple
            className={classes.iconContainer}
            component={Link}
            to="/change-password"
          >
            <ListItemIcon>
              <KeyIcon />
            </ListItemIcon>
            <ListItemText primary={t('Change password')} />
          </MenuItem>
          {!!userDetails?.isStaff && (
            <MenuItem component={Link} to="/dashboard/admin-hijack">
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary="Hijack" />
            </MenuItem>
          )}
          <MenuItem
            onClick={handleLogOut}
            button
            disableRipple
            className={classes.iconContainer}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={t('Cerrar Sesión')} />
          </MenuItem>
        </List>
      </>
    )

  return (
    <Auxiliary>
      <Hidden smDown implementation="css">
        <Drawer
          id="drawer"
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <Grid
            container
            direction="column"
            className={classes.drawerContainer}
          >
            {drawerContent}
          </Grid>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer open={open} variant="temporary" onClose={toggle}>
          <Grid
            container
            direction="column"
            className={classes.drawerContainer}
          >
            {drawerContent}
          </Grid>
        </Drawer>
      </Hidden>
    </Auxiliary>
  )
}
