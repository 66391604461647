import { getRequest } from 'axios/request'

export function treeTableRequest(params) {
  const url = '/portfolios/data/tree-table/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function treeReportRequest(params) {
  const url = '/portfolios/data/tree-report/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function treeContributionRequest(params) {
  const url = '/portfolios/data/asset-contribution/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function treeReturnsRequest(params) {
  const url = '/portfolios/data/asset-total-returns/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function monthlyReturnsRequest(params) {
  const url = '/portfolios/data/monthly-returns/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}
