let backendURL

if (window.location.hostname.includes('test')) {
  backendURL = 'https://test.wealthiq-api.abaqus.cl'
} else if (window.location.hostname.includes('localhost')) {
  backendURL = 'http://localhost:8000'
} else {
  backendURL = 'https://prod.wealthiq-api.abaqus.cl'
}

export default backendURL
