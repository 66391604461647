import { getRequest } from 'axios/request'

export function getUserPortfoliosRequest() {
  const url = '/portfolios/data/user-portfolios/'
  return getRequest(url, {}, {})
}

export function getCurrenciesRequest() {
  const url = '/portfolios/currencies/'
  return getRequest(url, {}, {})
}
