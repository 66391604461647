import { useContext } from 'react'

import { Redirect, Route } from 'react-router-dom'

import AuthContext from 'context/authContext'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isUserAuthenticated } = useContext(AuthContext)
  return (
    <Route
      render={() =>
        isUserAuthenticated ? <Component {...rest} /> : <Redirect to="/login" />
      }
    />
  )
}

export { PrivateRoute as default }
