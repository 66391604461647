import { useContext, useEffect, useState } from 'react'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import HeaderContext from 'context/headerContext'
import BarChart from 'utils/UI/Graphs/BarChart'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { treeContributionRequest } from 'axios/requests/analysis'

export default function TreeContributionGraph() {
  const { t } = useTranslation(['dashboard', 'common'])
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [requestData, setRequestData] = useState()
  const [loading, setLoading] = useState(false)
  const [savedCategory, setSavedCategory] = useState('')

  useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await treeContributionRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
          portfolio_id: currentPortfolio.id
        })
        setLoading(false)
        return result.data
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }

    if (mounted && currentPortfolio && currency) {
      fetchData().then((data) => {
        if (mounted && data?.contributionData) {
          setRequestData(data.contributionData)
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [startDate, endDate, currency, currentPortfolio])

  return (
    <CardWrapper
      title={t('Contribución por activo')}
      titleFeedback={t('Contribución por activo')}
    >
      {!loading && requestData ? (
        <BarChart
          requestData={requestData}
          sumTotal
          title={t('Contribución (%)')}
          savedCategory={savedCategory}
          setSavedCategory={setSavedCategory}
        />
      ) : (
        <Loading extraHeight={150} />
      )}
    </CardWrapper>
  )
}
