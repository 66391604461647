import { useEffect, useMemo, useState } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'

import {
  GetAllUsersRequest,
  GetHijackedUserTokenRequest
} from 'axios/requests/hijack'

import { Redirect } from 'react-router-dom'

import { hijackLogin } from 'utils/Auth/AuthProvider'

import { errorNotification } from 'utils/UI/Notifications/Notifications'

const colsData = [
  {
    id: 'code',
    label: 'Codigo'
  },
  {
    id: 'user_name',
    label: 'Nombre'
  },
  {
    id: 'user_email',
    label: 'Email'
  }
]

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: '1rem'
  },
  tableCellHead: {
    fontWeight: 700
  },
  tableCellBody: {
    fontWeight: 300
  }
}))

export default function Hijack() {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [rowsData, setRowsData] = useState()
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    let mounted = true

    const fetchData = async () => {
      try {
        const result = await GetAllUsersRequest()
        return result.data
      } catch (err) {
        console.log(err)
      }
    }

    if (mounted) {
      fetchData().then((data) => {
        if (data?.users && mounted) {
          setRowsData(data.users)
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [])

  const filteredData = useMemo(() => {
    if (!rowsData) return []
    return rowsData.filter((row) => {
      return (
        row.code.toLowerCase().includes(search.toLowerCase()) ||
        row.user_name.toLowerCase().includes(search.toLowerCase()) ||
        row.user_email.toLowerCase().includes(search.toLowerCase())
      )
    })
  }, [rowsData, search])

  const handleHijackRequest = async (email) => {
    try {
      const result = await GetHijackedUserTokenRequest({
        user_email: email
      })
      hijackLogin(result.data)
      setRedirect(true)
    } catch (err) {
      errorNotification('generalError')
    }
  }

  if (redirect) {
    return <Redirect to="/" />
  }

  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          size="small"
          style={{ marginBottom: '1rem', marginTop: '2rem' }}
          label="Buscar"
          variant="outlined"
          placeholder="Buscar por código, nombre o email"
          value={search}
          InputProps={{
            endAdornment: search && (
              <InputAdornment>
                <IconButton onClick={() => setSearch('')} size="small">
                  <CloseIcon position="end">kg</CloseIcon>
                </IconButton>
              </InputAdornment>
            )
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Grid>

      <TableContainer className={classes.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {colsData.map((col) => {
                return (
                  <TableCell className={classes.tableCellHead} key={col.id}>
                    {col.label}{' '}
                  </TableCell>
                )
              })}
              <TableCell className={classes.tableCellHead}>Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              ? filteredData.map((row, rowIndex) => {
                  return (
                    <TableRow key={row + rowIndex.toString()}>
                      {colsData.map((col, index) => {
                        const element = row[col.id]
                        return (
                          <TableCell
                            className={classes.tableCellBody}
                            key={element + index.toString()}
                          >
                            {element}
                          </TableCell>
                        )
                      })}
                      <TableCell key={'HijackBtn' + rowIndex.toString()}>
                        <Button
                          onClick={() => handleHijackRequest(row.user_email)}
                          variant="outlined"
                          size="small"
                        >
                          Hijack
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
