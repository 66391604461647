import { makeStyles } from '@material-ui/core/styles'

const baseStyles = makeStyles(
  (theme) => ({
    primaryColor: {
      color: theme.palette.primary.main
    },
    secondaryColor: {
      color: theme.palette.secondary.main
    },
    background: {
      backgroundColor: 'rgba(235, 229, 223, 0.2)'
    },
    whiteColor: {
      color: '#fff'
    },
    successColor: {
      color: theme.palette.success.main
    },
    errorColor: {
      color: theme.palette.error.main
    },
    bold: {
      fontWeight: 'bold',
      '& svg': {
        fontWeight: 'bold',
        color: 'black'
      }
    }
  }),
  { index: 1 }
)

export default baseStyles
