import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Autocomplete } from '@material-ui/lab'
import Pagination from '@material-ui/lab/Pagination'
import { useEffect, useState } from 'react'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'
import {
  GetPlaidSecurities,
  UpdatePlaidSecurity
} from '../../axios/requests/operations'
import AssetsLookup from './AssetsLookup'
const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  flexColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  },
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  assetsSelector: {
    width: '100%'
  },
  deleteIcon: {
    color: theme.palette.error.main
  }
}))

const PlaidAssets = () => {
  const classes = useStyles()
  const [securities, setSecurities] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [num_pages, setNumPages] = useState(0)
  const [selectedValidationState, setSelectedValidationState] = useState(null)
  const [filterSecurity, setFilterSecurity] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedSecurity, setSelectedSecurity] = useState(null)
  const [filterAsset, setFilterAsset] = useState(null)
  const [changeTickerOption, setChangeTickerOption] = useState(false)
  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }
  useEffect(() => {
    fetchSecurities()
  }, [currentPage])

  const fetchSecurities = async () => {
    try {
      setLoading(true)
      const response = await GetPlaidSecurities({
        page: currentPage,
        search_term: filterSecurity ? filterSecurity.name : null,
        is_ticker_validated: selectedValidationState
          ? selectedValidationState.value
          : null
      })
      setSecurities(response.data.items)
      setNumPages(response.data.num_pages)
      setFilterSecurity(null)
    } catch (error) {
      console.error('Error fetching securities:', error)
    } finally {
      setLoading(false)
    }
  }
  const handleFilter = () => {
    fetchSecurities()
  }

  const handleRowClick = (security) => {
    setSelectedSecurity(security)
    setOpenDialog(true)
  }
  const handleDialogClose = () => {
    setChangeTickerOption(false)
    setOpenDialog(false)
    setFilterAsset(null)
    setSelectedSecurity(null)
  }
  const handleSave = async () => {
    try {
      await UpdatePlaidSecurity({
        id: selectedSecurity.id,
        wiq_ticker: filterAsset?.ticker,
        is_ticker_validated: selectedSecurity?.is_ticker_validated
      })
      fetchSecurities()
      handleDialogClose()
      successNotification('savedCorrectly')
    } catch (error) {
      errorNotification('generalError')
    }
  }

  return (
    <>
      {loading ? (
        <div className={classes.reportContainer}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.flexColumnContainer}>
          <div className={classes.reportContainer} style={{ width: '80%' }}>
            <TextField
              label="Buscar por id/nombre/ticker"
              variant="outlined"
              style={{
                width: '100%',
                marginLeft: '10px',
                marginRight: '10px',
                marginBottom: '10px'
              }}
              onChange={(e) => setFilterSecurity({ name: e.target.value })}
            />
            <Autocomplete
              options={[
                { label: 'Validadas', value: true },
                { label: 'No Validadas', value: false }
              ]}
              value={selectedValidationState}
              onChange={(event, newValue) =>
                setSelectedValidationState(newValue)
              }
              getOptionLabel={(option) => option.label}
              style={{
                width: '100%',
                marginLeft: '10px',
                marginRight: '10px'
              }}
              renderInput={(params) => (
                <TextField {...params} label="Estado de Validación" />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleFilter}
            >
              Filtrar
            </Button>
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id en Plaid</TableCell>
                  <TableCell>Nombre de Asset en Plaid</TableCell>
                  <TableCell>Ticker en Plaid</TableCell>
                  <TableCell>Ticker asignado en WealthIQ</TableCell>
                  <TableCell>ISIN</TableCell>
                  <TableCell>CUSIP</TableCell>
                  <TableCell>Validado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {securities.map((security) => (
                  <TableRow
                    key={security.id}
                    onClick={() => handleRowClick(security)}
                    hover
                  >
                    <TableCell>{security.security_id}</TableCell>
                    <TableCell>{security.name}</TableCell>
                    <TableCell>{security.ticker}</TableCell>
                    <TableCell>{security.wiq_ticker}</TableCell>
                    <TableCell>{security.isin}</TableCell>
                    <TableCell>{security.cusip}</TableCell>
                    <TableCell>
                      {security.is_ticker_validated ? 'Si' : 'No'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={num_pages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            className={classes.pagination}
          />
        </div>
      )}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Editar Asset</DialogTitle>
        <DialogContent>
          <Typography variant="h6">{selectedSecurity?.name}</Typography>
          <div className={classes.reportContainer}>
            <Typography variant="h7">Ticker asignado en WealthIQ:</Typography>
            <Typography variant="h7">{selectedSecurity?.wiq_ticker}</Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {!changeTickerOption && (
              <Button onClick={() => setChangeTickerOption(true)}>
                Cambiar Ticker
              </Button>
            )}
            {changeTickerOption && (
              <>
                <Typography variant="h7">Cambiar ticker:</Typography>
                <AssetsLookup
                  filterAsset={filterAsset}
                  setFilterAsset={setFilterAsset}
                />
              </>
            )}
          </div>
          <div className={classes.reportContainer}>
            <Typography variant="h7">Validar ticker</Typography>
            <Switch
              checked={selectedSecurity?.is_ticker_validated}
              onChange={(e) => {
                const newSecurity = { ...selectedSecurity }
                newSecurity.is_ticker_validated = e.target.checked
                setSelectedSecurity(newSecurity)
              }}
              color="primary"
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Posible ticker en WealthIQ</TableCell>
                <TableCell>Cantidad de veces que fue asignado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedSecurity?.possible_securities?.map((security) => (
                <TableRow key={security.id}>
                  <TableCell>{security.ticker}</TableCell>
                  <TableCell>{security.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PlaidAssets
