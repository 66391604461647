import { useCallback, useEffect, useMemo, useState } from 'react'

import { Input, Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import { rankItem } from '@tanstack/match-sorter-utils'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  StyledTanstackCell,
  StyledTanstackContainer
} from 'assets/jss/Tables/TanstackTables'
import 'components/DatePicker/overrideDatePicker.css'
import Search from 'utils/UI/Inputs/Search'
import TanstackFilter from 'utils/UI/Inputs/TanstackFilter'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  hideSortIcon: {
    '& .MuiTableSortLabel-icon': {
      display: 'none',
      visibility: 'hidden'
    }
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      maxHeight: 640
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: 460
    }
  },
  containerFullscreen: {
    maxHeight: '78vh'
  }
}))

const defaultColumn = {
  cell: ({ getValue, row: { index }, column: { id }, table }) => {
    const initialValue = getValue()
    const [value, setValue] = useState(initialValue)
    const [focused, setFocused] = useState(false)

    const onBlur = () => {
      table.options.meta?.updateData(index, id, value)
      setFocused(false)
    }

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    return (
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        fullWidth
        disableUnderline={!focused}
        onFocus={() => setFocused(true)}
      />
    )
  }
}

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

function dateFromString(str) {
  const [day, month, year] = str.split('-').map((part) => parseInt(part, 10))
  // Month is zero-indexed, that's why we need `month - 1`.
  return new Date(year, month - 1, day)
}

function dateRangeFilterFn(row, columnId, filterValue) {
  let [startDateString, endDateString] = filterValue || []
  let startDate = startDateString ? dateFromString(startDateString) : null
  let endDate = endDateString ? dateFromString(endDateString) : null
  // Check for invalid dates
  if (
    (startDateString && isNaN(startDate?.getTime())) ||
    (endDateString && isNaN(endDate?.getTime()))
  ) {
    console.error('Invalid date format. Dates should be in dd-mm-yyyy format.')
    return false
  }

  // If no dates are set in the filter, don't filter anything
  if (!filterValue || (!startDate && !endDate)) {
    return true
  }
  const rowValue = row.getValue(columnId)
  const rowDate = dateFromString(rowValue)
  // If only one date is specified, act as greater/less than filter
  if (startDate && !endDate) {
    return rowDate >= startDate
  }
  if (!startDate && endDate) {
    return rowDate <= endDate
  }
  // If both dates are specified, check if the row date is within the range
  return rowDate >= startDate && rowDate <= endDate
}

const numericFilterFn = (row, columnId, filterValue) => {
  const rowValue = row.getValue(columnId)
  const rowNumber = parseFloat(rowValue)

  // Function to check if a value is effectively not set (undefined, empty, or non-numeric)
  const isNotSet = (value) => {
    return value === undefined || value === '' || isNaN(parseFloat(value))
  }

  // If the rowValue is not a valid number, exclude it
  if (isNaN(rowNumber)) return false

  // Extract min and max values, treating undefined and empty strings as no filter
  const [min, max] = filterValue.map((val) =>
    isNotSet(val) ? undefined : parseFloat(val)
  )

  // If both min and max are not set, include all rows
  if (isNotSet(min) && isNotSet(max)) return true

  // Check if rowNumber falls within the specified range
  return (
    (isNotSet(min) || rowNumber >= min) && (isNotSet(max) || rowNumber <= max)
  )
}

export default function HoldingsDividendsTanstackTable({
  colsData,
  rowsData,
  isFullscreen
}) {
  const classes = useStyles()
  const [data, setData] = useState(rowsData)
  const [sorting, setSorting] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState([])

  const getColumnAligment = (column) => {
    let columnAlignment = 'center' // Default alignment
    let columnPadding = 0
    const isCenteredAlignCell =
      column.id === 'date' ||
      column.id === 'asset' ||
      column.id === 'account' ||
      column.id === 'client' ||
      column.id === 'dividend_currency'
    const isRightAlignCell =
      column.id === 'holding_dividends_clp' ||
      column.id === 'actual_dividends_clp' ||
      column.id === 'div_times_written_clp' ||
      column.id === 'holdings_dividends_usd' ||
      column.id === 'actual_dividends_usd' ||
      column.id === 'div_times_written_usd'

    if (isCenteredAlignCell) {
      columnAlignment = 'center'
    }
    if (isRightAlignCell) {
      columnAlignment = 'right'
      columnPadding = 30
    }
    return [columnAlignment, columnPadding]
  }

  const getCellColor = (cell) => {
    let cellInputColor = null
    const cellValue = cell.getValue()
    let fontWeight = 'normal'
    if (
      cell.column.id === 'div_times_written_clp' ||
      cell.column.id === 'div_times_written_usd'
    ) {
      fontWeight = 'bold'
      if (cellValue === '0,5') {
        cellInputColor = 'orange'
      } else if (/,0+$/.test(cellValue)) {
        cellInputColor = 'green'
      } else {
        cellInputColor = 'red'
      }
    }
    return [cellInputColor, fontWeight]
  }

  const updateData = useCallback((rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          }
        }
        return row
      })
    )
  }, [])

  const columnHelper = createColumnHelper()

  const columns = useMemo(() => {
    return colsData.map((col) => {
      const [columnAlignment, columnPadding] = getColumnAligment(col)
      const column = columnHelper.accessor(col.id, {
        header: () => <div>{col.label}</div>,
        cell: (cell) => {
          const [cellColor, fontWeight] = getCellColor(cell)
          const cellValue = cell.getValue() // Get the cell value
          return (
            <div
              style={{
                textAlign: columnAlignment,
                color: cellColor,
                fontWeight: fontWeight,
                paddingRight: columnPadding
              }}
            >
              {cellValue}
            </div>
          )
        }
      })

      // Check if the column ID contains 'date' and apply date range filter
      if (col.id === 'date') {
        return {
          ...column,
          filterFn: 'dateRangeFilter' // Assuming 'dateRangeFilter' is a predefined filter function
        }
      }

      // Check if the column ID contains 'price' or 'quantity' and apply numeric filter
      if (
        col.id.toLowerCase().includes('price') ||
        col.id.toLowerCase().includes('quantity')
      ) {
        return {
          ...column,
          filterFn: 'numericFilter' // Replace with your custom numeric filter function identifier
        }
      }

      if (
        col.id === 'holding_dividends_clp' ||
        col.id === 'actual_dividends_clp' ||
        col.id === 'holdings_dividends_usd' ||
        col.id === 'actual_dividends_usd'
      ) {
        return {
          ...column,
          enableColumnFilter: false
        }
      }

      // Default return if no specific filter is needed
      return column
    })
  }, [colsData, columnHelper])

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    filterFns: {
      fuzzy: fuzzyFilter,
      dateRangeFilter: dateRangeFilterFn,
      numericFilter: numericFilterFn
    },
    meta: {
      updateData
    },
    state: {
      sorting,
      globalFilter,
      columnFilters
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  })

  return (
    <CardWrapper>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Search
            onChange={(value) => setGlobalFilter(String(value))}
            value={globalFilter}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledTanstackContainer
            className={
              isFullscreen ? classes.containerFullscreen : classes.container
            }
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const hideSortIcon = !header.column.getCanSort()
                      return (
                        <StyledTanstackCell
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                              }}
                            >
                              <div>
                                {' '}
                                {/* Wrapper for the sort label */}
                                <TableSortLabel
                                  className={
                                    hideSortIcon ? classes.hideSortIcon : ''
                                  }
                                  active={header.column.getIsSorted() !== false}
                                  direction={
                                    header.column.getIsSorted() === 'asc'
                                      ? 'asc'
                                      : 'desc'
                                  }
                                  onClick={header.column.getToggleSortingHandler()}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </TableSortLabel>
                              </div>
                              {header.column.getCanFilter() && (
                                <div>
                                  {' '}
                                  {/* Wrapper for the filter component */}
                                  <TanstackFilter
                                    column={header.column}
                                    table={table}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </StyledTanstackCell>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      // let [cellInputColor, cellInputAlign] = getCellFormat(cell)
                      return (
                        <StyledTanstackCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </StyledTanstackCell>
                      )
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTanstackContainer>
        </Grid>
      </Grid>
    </CardWrapper>
  )
}
