export const initialState = {
  maxDate: null,
  minDate: null,
  startDate: null,
  endDate: null,
  isITD: false,
  currencyList: [],
  currency: null,
  portfolios: [],
  portfoliosDict: {}
}

function headerReducer(state, action) {
  /* We define the helper variables that will allow us to set states */
  let partsStartDate
  let startDateObj
  let partsEndDate
  let endDateObj
  let currentPortfolio
  let periodStartDate
  let maxStartDate

  switch (action.type) {
    case 'PORTFOLIOS_LIST_CHANGE':
      currentPortfolio = action.portfolio_list[0]
      partsStartDate = currentPortfolio.start_date.split('-')
      startDateObj = new Date(
        partsStartDate[0],
        partsStartDate[1] - 1,
        partsStartDate[2]
      )

      partsEndDate = currentPortfolio.end_date.split('-')
      endDateObj = new Date(
        partsEndDate[0],
        partsEndDate[1] - 1,
        partsEndDate[2]
      )
      periodStartDate = new Date(endDateObj.getFullYear() - 1, 11, 31)
      maxStartDate = new Date(
        Math.max(startDateObj.getTime(), periodStartDate.getTime())
      )

      return {
        ...state,
        portfolios: action.portfolio_list,
        portfoliosDict: action.portfolio_list.reduce((dict, portfolio) => {
          dict[portfolio.code] = portfolio
          return dict
        }, {}),
        currentPortfolio: currentPortfolio,
        minDate: startDateObj,
        maxDate: endDateObj,
        startDate: maxStartDate,
        endDate: endDateObj
      }
    case 'CURRENT_PORTFOLIO_CHANGE': {
      currentPortfolio = action.currentPortfolio
      partsStartDate = currentPortfolio.start_date.split('-')
      startDateObj = new Date(
        partsStartDate[0],
        partsStartDate[1] - 1,
        partsStartDate[2]
      )

      partsEndDate = currentPortfolio.end_date.split('-')
      endDateObj = new Date(
        partsEndDate[0],
        partsEndDate[1] - 1,
        partsEndDate[2]
      )

      const dates = {
        minDate: startDateObj,
        maxDate: endDateObj,
        startDate: startDateObj,
        endDate: endDateObj
      }

      return {
        ...state,
        currentPortfolio: action.currentPortfolio,
        minDate: startDateObj,
        ...(state.isITD ? dates : {})
      }
    }
    case 'CURRENCY_LIST_CHANGE':
      return {
        ...state,
        currencyList: action.currencyList.map((currency) => ({
          value: currency.code,
          key: currency.code,
          label: currency.code
        }))
      }
    case 'CURRENCY_CHANGE':
      return {
        ...state,
        currency: action.currency
      }
    case 'DATE_CHANGE':
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        isITD: action.isITD
      }
    case 'STARTDATE_CHANGE':
      return {
        ...state,
        startDate: action.startDate
      }
    case 'ENDDATE_CHANGE':
      return {
        ...state,
        endDate: action.endDate
      }
    case 'SET_MAX_DATE':
      return {
        ...state,
        maxDate: action.maxDate,
        minDate: action.minDate,
        startDate:
          action.startDate ||
          new Date(action.maxDate.getFullYear() - 1, 11, 31),
        endDate: action.maxDate
      }
    default:
      throw new Error()
  }
}

export default headerReducer
