import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import OperationsTransactionsTable from 'components/Graphs/OperationsTransactionsTable'
import useResponsive from 'hooks/useResponsive'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

const useStyles = makeStyles((theme) => ({
  graphSelectorsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    width: '100%',
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    }
  }
}))

export default function Transactions() {
  const classes = useStyles()
  const { isMobile } = useResponsive()

  return (
    <Auxiliary>
      <Box className={classes.graphSelectorsContainer}>
        <Box className={classes.graphDataType}>
          <Grid container={isMobile}></Grid>
        </Box>
      </Box>
      <OperationsTransactionsTable />
    </Auxiliary>
  )
}
