import { useEffect, useMemo, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import clsx from 'clsx'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import useResponsive from 'hooks/useResponsive'
import baseStyles from 'utils/UI/BaseStyles'
import Loading from 'utils/UI/Loading'
import { COLORS_MULTI_SELECT } from 'utils/UI/Theme'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'
import { bigNumberFormatter } from 'utils/formatters/currencyFormatters'
import { numberFormatter } from 'utils/formatters/numberFormatters'
import { percentageFormatter } from 'utils/formatters/percentageFormatters'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginLeft: 0
    }
  },
  gridContainer: {
    marginBottom: theme.spacing(2)
  },
  returnCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  comparatorCell: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  comparatorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  returnIconContainer: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    height: '100%'
  }
}))

export default function Chart({ chartData }) {
  const baseClasses = baseStyles()
  const classes = useStyles()
  const theme = useTheme()
  const { isMobile } = useResponsive()
  const [loading, setLoading] = useState(true)

  const [chartOptions, setChartOptions] = useState({
    credits: false,
    plotOptions: {
      line: {
        marker: {
          enabled: false
        },
        events: {
          legendItemClick: function () {
            return false
          }
        }
      }
    },
    chart: {
      zoomType: 'x',
      style: {
        fontFamily: 'InconsolataRegular'
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      crosshair: true,
      labels: {
        style: {
          color: theme.palette.text.primary
        }
      }
    },
    yAxis: {
      title: {
        text: null
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: theme.palette.text.primary
        }
      ],
      labels: {
        style: {
          color: theme.palette.text.primary
        }
      }
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
      formatter: function () {
        let points = ''
        this.points
          .sort((a, b) => {
            return b.point.y - a.point.y
          })
          .forEach((point) => {
            // eslint-disable-next-line prettier/prettier
            points += `<span style="color: ${point.series.color}">●</span> ${
              point.series.name
            } : ${point.y.toFixed(2)}<br>`
          })
        return (
          '<b style="font-size: 11px  ">' +
          Highcharts.dateFormat('%d/%m/%Y', this.x) +
          '</b>' +
          '<br>' +
          '<div style="margin-top: 20px">' +
          points +
          '</div>'
        )
      }
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      itemStyle: {
        fontFamily: 'InconsolataRegular',
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.palette.text.primary
      }
    },
    lang: {
      noData: ''
    },
    series: [
      {
        name: '',
        color: theme.palette.primary.main,
        data: [],
        turboThreshold: 0
      }
    ]
  })

  function getTooltipFormatter() {
    return function () {
      let points = ''
      this.points
        .sort((a, b) => {
          return b.point.y - a.point.y
        })
        .forEach((point) => {
          points += `<span style='color: ${point.series.color}'>●</span>
          ${point.series.name} : 
          ${
            chartData.mode === 'total-account-value'
              ? numberFormatter(point.y.toFixed(2))
              : point.y.toFixed(2)
          }<br>`
        })
      return (
        '<b style="font-size: 11px  ">' +
        Highcharts.dateFormat('%d/%m/%Y', this.x) +
        '</b>' +
        '<br>' +
        '<div style="margin-top: 20px">' +
        points +
        '</div>'
      )
    }
  }

  useEffect(() => {
    let mounted = true

    window.addEventListener('transitionend', () => {
      Highcharts.charts.forEach((chart) => {
        if (chart) {
          chart.reflow()
        }
      })
    })
    setLoading(true)
    if (mounted && chartData?.portfolioData) {
      const portfolioData = []
      const comparatorsDict = {}
      for (let index = 0; index < chartData.portfolioData.length; index++) {
        const element = chartData.portfolioData[index]
        portfolioData.push([Date.parse(element[0]), element[1]])
      }
      if (chartData?.comparatorData) {
        chartData.comparatorData.forEach(({ comparator, data }) => {
          const comparatorData = []

          /* We add the portfolio data points for the performance series */
          for (let index = 0; index < data.length; index++) {
            const element = data[index]
            comparatorData.push([Date.parse(element[0]), element[1]])
          }
          comparatorsDict[comparator] = comparatorData
        })
      }

      if (
        chartData.comparatorData?.length &&
        chartData.mode === 'valor-cuota'
      ) {
        setChartOptions({
          series: [
            {
              name: 'Portafolio ' + chartData.portfolioName,
              data: portfolioData
            },
            ...Object.keys(comparatorsDict).map((comparator, i) => ({
              name: comparator.toUpperCase(),
              data: comparatorsDict[comparator],
              color: COLORS_MULTI_SELECT[i]
            }))
          ],
          tooltip: {
            shared: true,
            valueDecimals: 2,
            formatter: getTooltipFormatter()
          }
        })
      } else {
        setChartOptions({
          series: [
            {
              name: 'Portafolio ' + chartData.portfolioName,
              data: portfolioData
            }
          ],
          tooltip: {
            shared: true,
            valueDecimals: 2,
            formatter: getTooltipFormatter()
          }
        })
      }
    }
    setLoading(false)

    return () => {
      mounted = false
      window.removeEventListener('transitionend', () => {
        Highcharts.charts.forEach((chart) => {
          if (chart) {
            chart.reflow()
          }
        })
      })
    }
  }, [chartData])

  const returnValue = useMemo(() => {
    let value
    let valueFormatted
    if (chartData.mode === 'total-account-value') {
      value = chartData.portfolioReturn
      valueFormatted = bigNumberFormatter(chartData.portfolioReturn)
    } else {
      value = chartData.portfolioReturn
      valueFormatted = percentageFormatter(chartData.portfolioReturn)
    }
    return {
      value,
      valueFormatted
    }
  }, [chartData])

  return (
    <CardWrapper title="Evolución" shadow={false} width="100%">
      {chartData.portfolioReturn ? (
        <>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.gridContainer}
          >
            {chartData && (
              <Grid item xs={isMobile ? 4 : 3} className={classes.returnCell}>
                {!loading ? (
                  <>
                    <span
                      className={clsx(classes.returnPercentage, {
                        [baseClasses.errorColor]: returnValue.value < 0,
                        [baseClasses.successColor]: returnValue.value > 0
                      })}
                    >
                      {returnValue.valueFormatted}
                    </span>
                    <div className={classes.returnIconContainer}>
                      {returnValue.value > 0 ? (
                        <ArrowUpwardIcon
                          className={clsx({
                            [baseClasses.errorColor]: returnValue.value < 0,
                            [baseClasses.successColor]: returnValue.value > 0
                          })}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          className={clsx({
                            [baseClasses.errorColor]: returnValue.value < 0,
                            [baseClasses.successColor]: returnValue.value > 0
                          })}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  '-'
                )}
              </Grid>
            )}
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </Grid>
        </>
      ) : (
        <Loading />
      )}
    </CardWrapper>
  )
}
