import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { useVirtualizer } from '@tanstack/react-virtual'
import { StyledTableCell, StyledTableContainer } from 'assets/jss/Tables/Tables'
import { useMemo, useRef, useState } from 'react'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  hideSortIcon: {
    '& .MuiTableSortLabel-icon': {
      display: 'none',
      visibility: 'hidden'
    }
  },
  fontBold: {
    fontFamily: 'InconsolataSemiBold'
  },
  tableContainer: {
    overflowX: 'auto',
    height: '150em', // Adjust this height as needed
    position: 'relative'
  },
  tableRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start', // Align text to the start
    whiteSpace: 'nowrap', // Prevent text wrapping
    overflow: 'hidden', // Hide overflow text
    textOverflow: 'ellipsis', // Show ellipsis for overflow text
    fontSize: '0.875rem' // Reduce font size to prevent crowding
  },
  headerCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start', // Align text to the start
    borderBottom: '1px solid whitesmoke'
  }
})

function getColumnWidths(colsData, rowsData) {
  const viewportWidth = window.outerWidth
  const priorityColumns = ['checkbox', 'date']
  // const numericColumns = ['fee', 'price', 'quantity', 'valuation']

  const fixedWidths = priorityColumns.reduce((acc, col) => {
    if (col === 'checkbox') {
      acc[col] = viewportWidth / 24 // Fixed small width for checkbox
    } else {
      acc[col] = viewportWidth / 12 // Fixed width for date
    }
    return acc
  }, {})

  fixedWidths['asset'] = 300 // Fixed width for asset column

  const maxWidths = colsData.map((col) => {
    if (fixedWidths[col.id]) {
      return fixedWidths[col.id]
    }
    const maxWidth = rowsData.reduce((max, row) => {
      const length = row[col.id]?.toString().length || 0
      return length > max ? length : max
    }, col.label.length)
    return Math.min(
      Math.max(maxWidth * 10, viewportWidth / 18),
      viewportWidth / 11
    )
  })

  return maxWidths
}

export default function VirtualizedTable({
  colsData,
  rowsData,
  size = 'medium'
}) {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(null)
  const [hideSortIcon] = useState(true)

  const data = useMemo(() => {
    if (!orderBy) return rowsData
    const sortedData = rowsData.sort((a, b) => {
      if (typeof a[orderBy] === 'string') {
        return order === 'asc'
          ? a[orderBy].localeCompare(b[orderBy])
          : b[orderBy].localeCompare(a[orderBy])
      } else {
        return order === 'asc'
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy]
      }
    })
    return sortedData
  }, [order, orderBy, rowsData])

  const columnWidths = useMemo(
    () => getColumnWidths(colsData, rowsData),
    [colsData, rowsData]
  )

  const tableContainerRef = useRef(null)

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 50, // Adjust the row height if necessary
    overscan: 5
  })

  return (
    <Auxiliary>
      <StyledTableContainer
        className={classes.tableContainer}
        ref={tableContainerRef}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          size={size}
          style={{ display: 'block', minWidth: '100%' }}
        >
          <TableHead
            style={{
              display: 'block',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white' // Ensure the header has a background
            }}
          >
            <TableRow style={{ display: 'flex', width: '100%' }}>
              {colsData.map((column, index) => (
                <StyledTableCell
                  key={column.id.toString() + index.toString()}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    width: `${columnWidths[index]}px`
                  }} // Set dynamic width
                  sortDirection={orderBy === column.id ? order : false}
                  className={classes.headerCell}
                >
                  <TableSortLabel
                    className={hideSortIcon ? classes.hideSortIcon : ''}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => {
                      const newOrder =
                        orderBy === column.id && order === 'asc'
                          ? 'desc'
                          : 'asc'
                      setOrder(newOrder)
                      setOrderBy(column.id)
                    }}
                  >
                    {column.label}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              display: 'block',
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative'
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = data[virtualRow.index]
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={virtualRow.index}
                  data-index={virtualRow.index}
                  ref={(node) => rowVirtualizer.measureElement(node)}
                  className={classes.tableRow}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    transform: `translateY(${virtualRow.start}px)`
                  }}
                >
                  {colsData.map((column, colIndex) => {
                    const value = row[column.id]
                    let displayValue = column.render
                      ? column.render(row)
                      : column.format
                      ? column.format(value)
                      : value
                    const tooltipValue = column.tooltipValue
                      ? column.tooltipValue(row)
                      : null
                    const numericColumns = [
                      'fee',
                      'price',
                      'quantity',
                      'valuation'
                    ]
                    const isNumeric =
                      typeof value === 'number' ||
                      numericColumns.includes(column.id)

                    return (
                      <StyledTableCell
                        key={`${column.id.toString()}${virtualRow.index.toString()}`}
                        align={column.align}
                        className={`${classes.tableCell} ${
                          column.bold ? classes.fontBold : ''
                        }`}
                        style={{ width: `${columnWidths[colIndex]}px` }}
                      >
                        {isNumeric && !tooltipValue ? (
                          displayValue
                        ) : (
                          <Tooltip
                            title={tooltipValue || displayValue}
                            placement="top"
                          >
                            <span
                              style={
                                column.ellipsis
                                  ? {
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: 'block'
                                    }
                                  : {}
                              }
                            >
                              {displayValue}
                            </span>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Auxiliary>
  )
}
