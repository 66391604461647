import { useContext } from 'react'

import { Redirect, Route } from 'react-router-dom'

import AuthContext from 'context/authContext'

const LoginRoute = ({ component: Component, ...rest }) => {
  const { isUserAuthenticated, userDetails } = useContext(AuthContext)
  return (
    <Route
      render={() =>
        isUserAuthenticated ? (
          userDetails.role == 'Operations' ? (
            <Redirect to="/dashboard/operations" />
          ) : (
            <Redirect to="/dashboard/summary" />
          )
        ) : (
          <Component {...rest} />
        )
      }
    />
  )
}

export { LoginRoute as default }
