import { getRequest } from 'axios/request'

export function StatementsRequest(params) {
  const url = '/portfolios/data/statements/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function InvoicesRequest(params) {
  const url = '/portfolios/data/invoices/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function ContractsRequest(params) {
  const url = '/portfolios/data/contracts/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function OtherDocumentsRequest(params) {
  const url = '/portfolios/data/other-documents/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}
