import React, { useEffect, useState } from 'react'
import { createFlagsProvider } from 'utils/Auth/FlagsProvider'

const FlagsContext = React.createContext()

const FlagsProvider = ({ children }) => {
  const flagsProvider = createFlagsProvider()
  const [userFlags, setUserFlags] = useState(
    JSON.parse(localStorage.getItem('USER_FLAGS')) || null
  )

  useEffect(() => {
    if (!userFlags) {
      flagsProvider.getUserFlags() // Fetch user flags when the component mounts
    }
    const listener = (newFlags) => {
      setUserFlags(newFlags)
      localStorage.setItem('USER_FLAGS', JSON.stringify(newFlags))
    }
    flagsProvider.subscribeFlags(listener)
    return () => {
      flagsProvider.unsubscribeFlags(listener)
    }
  }, [])

  return (
    <FlagsContext.Provider value={userFlags}>{children}</FlagsContext.Provider>
  )
}

export { FlagsContext, FlagsProvider }
