import { useContext, useState } from 'react'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import HeaderContext from 'context/headerContext'
import useResponsive from 'hooks/useResponsive'
import DownloadButton from 'utils/UI/Buttons/DownloadButton'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { errorNotification } from 'utils/UI/Notifications/Notifications'

import {
  portfolioHoldingReportRequest,
  realizedGainsReportRequest,
  resumePdfReportRequest,
  unrealizedGainsReportRequest
} from 'axios/requests/reports'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  }
}))

export default function Reports() {
  const { t } = useTranslation(['dashboard', 'common'])
  const classes = useStyles()
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const { isMobile } = useResponsive()
  const [loadingPortfolio, setLoadingPortfolio] = useState(false)
  const [loadingPDF, setLoadingPDF] = useState(false)
  const [loadingUnrealizedGains, setLoadingUnrealizedGains] = useState(false)
  const [loadingRealizedGains, setLoadingRealizedGains] = useState(false)

  const downloadPortfolioHoldingReportHandler = async () => {
    setLoadingPortfolio(true)
    try {
      const response = await portfolioHoldingReportRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency: currency,
        portfolio_id: currentPortfolio.id
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'Abaqus - Holdings - ' + currentPortfolio.name + '.xlsx'
      ) // or any other extension
      document.body.appendChild(link)
      link.click()
      setLoadingPortfolio(false)
    } catch (err) {
      console.log(err)
    }
  }

  const downloadResumePdfHandler = async () => {
    setLoadingPDF(true)
    try {
      const response = await resumePdfReportRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency: currency,
        portfolio_id: currentPortfolio.id
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'Abaqus - Resumen - ' + currentPortfolio.name + '.pdf'
      ) // or any other extension
      document.body.appendChild(link)
      link.click()
      setLoadingPDF(false)
    } catch (err) {
      console.log(err)
    }
  }

  const downloadUnrealizedGainsReportHandler = async () => {
    setLoadingUnrealizedGains(true)
    try {
      const response = await unrealizedGainsReportRequest({
        portfolio_id: currentPortfolio.id,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'Abaqus - UnrealizedGains - ' + currentPortfolio.name + '.xlsx'
      ) // or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingUnrealizedGains(false)
  }

  const downloadRealizedGainsReportHandler = async () => {
    setLoadingRealizedGains(true)
    try {
      const response = await realizedGainsReportRequest({
        portfolio_id: currentPortfolio.id,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'Abaqus - RealizedGains - ' + currentPortfolio.name + '.xlsx'
      ) // or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingRealizedGains(false)
  }

  return (
    <div className={classes.contentContainer}>
      <div className={classes.reportContainer}>
        <CardWrapper className={classes.reportCard} width="100%">
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.cardReportName}>
              {t('Reporte Posiciones')}
            </Typography>
            {loadingPortfolio ? (
              <CircularProgress />
            ) : (
              <DownloadButton
                onClickHandler={downloadPortfolioHoldingReportHandler}
              />
            )}
          </Grid>
        </CardWrapper>
      </div>
      <div className={classes.reportContainer}>
        <CardWrapper className={classes.reportCard} width="100%">
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.cardReportName}>
              {t('Resumen PDF')}
            </Typography>
            {loadingPDF ? (
              <CircularProgress />
            ) : (
              <DownloadButton onClickHandler={downloadResumePdfHandler} />
            )}
          </Grid>
        </CardWrapper>
      </div>
      <div className={classes.reportContainer}>
        <CardWrapper className={classes.reportCard} width="100%">
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.cardReportName}>
              {t('Reporte Ganancias no realizadas')}
            </Typography>
            {loadingUnrealizedGains ? (
              <CircularProgress />
            ) : (
              <DownloadButton
                onClickHandler={downloadUnrealizedGainsReportHandler}
              />
            )}
          </Grid>
        </CardWrapper>
      </div>
      <div className={classes.reportContainer}>
        <CardWrapper className={classes.reportCard} width="100%">
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.cardReportName}>
              {t('Reporte Ganancias realizadas')}
            </Typography>
            {loadingRealizedGains ? (
              <CircularProgress />
            ) : (
              <DownloadButton
                onClickHandler={downloadRealizedGainsReportHandler}
              />
            )}
          </Grid>
        </CardWrapper>
      </div>
    </div>
  )
}
