import { getRequest } from 'axios/request'

export function ClientAccountsCashHoldingsRequest(params) {
  const url = '/accounts/user-cash-holdings/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DownloadClientAccountsCashHoldingsRequest(params) {
  const url = '/accounts/user-download-cash-holdings/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}
