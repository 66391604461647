import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  PlaidExchangeToken,
  PlaidLinkToken,
  PlaidUpdateLink
} from 'axios/requests/integrations'
import {
  DeletePlaidConnection,
  GetPlaidConnectionAccounts,
  GetPlaidConnections,
  GetPlaidMatchedAccounts,
  UpdatePlaidConnection,
  UpdatePlaidConnectionAccess,
  UpdatePlaidConnectionDisplayInUserDashboard
} from 'axios/requests/operations'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useAuth } from 'utils/Auth/AuthProvider'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'
import PlaidAccounts from './accounts'
import PlaidAssets from './assets'
import PlaidInstitutions from './institutions'
import PlaidUserConnections from './plaidUserConnections'
const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  },
  button: {
    margin: theme.spacing(1)
  }
}))

const PlaidIntegration = () => {
  const classes = useStyles()
  const [linkToken, setLinkToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [connectionName, setConnectionName] = useState('')
  const [currentPublicToken, setCurrentPublicToken] = useState('')
  const { userDetails } = useAuth()

  const [loadingDialog, setLoadingDialog] = useState(false)
  const [loadingDialogText, setLoadingDialogText] = useState('')

  const [allConnections, setAllConnections] = useState({
    active: [],
    inactive: []
  })
  const [Connections, setConnections] = useState([])
  const [connectionAccounts, setConnectionAccounts] = useState([])
  const [isLoadingConnections, setIsLoadingConnections] = useState(true)
  const [isLoadingConnectionAccounts, setIsLoadingConnectionAccounts] =
    useState(false)
  const [isActiveConnectionStatus, setIsActiveConnectionStatus] = useState(true)
  const [resultDialog, setResultDialog] = useState({
    open: false,
    success: false
  })
  const [plaidError, setPlaidError] = useState({
    error: false,
    message: ''
  })
  const [editDialog, setEditDialog] = useState({
    open: false,
    connection: null
  })
  const [editedName, setEditedName] = useState('')
  const [displyedTable, setDisplyedTable] = useState('connections')
  const [matchedAccounts, setMatchedAccounts] = useState([])
  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [selectedValidationState, setSelectedValidationState] = useState(null)
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true)
  const [isUpdatingConnection, setIsUpdatingConnection] = useState(false)
  const [updatingConnectionId, setUpdatingConnectionId] = useState(null)

  const onSuccess = useCallback(
    async (public_token) => {
      if (isUpdatingConnection) {
        await UpdatePlaidConnectionAccess({
          connection_id: updatingConnectionId
        })
        setIsLoadingConnectionAccounts(true)
        setUpdatingConnectionId(null)
        const updatedConnection = {
          ...editDialog.connection,
          update_required: false
        }
        setEditDialog({
          open: true,
          connection: updatedConnection
        })
        handlePlaidConnectionAccounts(updatingConnectionId)
        refreshConnections()
        setIsUpdatingConnection(false)
        setLoading(false)
      } else {
        setCurrentPublicToken(public_token)
        setLoading(false)
        handle_admin(public_token)
      }
    },
    [isUpdatingConnection, updatingConnectionId]
  )

  const handle_admin = (public_token) => {
    if (userDetails?.isStaff) {
      setOpenDialog(true)
    } else {
      handleSubmitToken(public_token)
    }
  }

  const onExit = useCallback(() => {
    setLoading(false)
    if (isUpdatingConnection) {
      setEditDialog({
        ...editDialog,
        open: true
      })
    }
  }, [])

  const fetchLinkToken = async () => {
    setLoading(true)
    setIsUpdatingConnection(false)
    try {
      const response = await PlaidLinkToken({})
      setLinkToken(response.data.link_token)
    } catch (error) {
      console.error('Error creating link token:', error)
      setLoading(false)
    }
  }

  const fetchUpdateLinkToken = async (connection_id) => {
    try {
      setLoading(true)
      setUpdatingConnectionId(connection_id)
      setIsUpdatingConnection(true)
      const response = await PlaidUpdateLink({ connection_id })
      setLinkToken(response.data.link_token)
      setEditDialog({
        ...editDialog,
        open: false
      })
    } catch (error) {
      console.error('Error updating link token:', error)
      setLoading(false)
    }
  }
  const refreshConnections = async () => {
    setIsLoadingConnections(true)
    const response = await GetPlaidConnections({})
    setAllConnections(response.data)
    setConnections(
      isActiveConnectionStatus ? response.data.active : response.data.inactive
    )
    setIsLoadingConnections(false)
  }
  const handleSubmitToken = async (public_token) => {
    setLoadingDialogText('Creando credenciales...')
    setLoadingDialog(true)
    try {
      await PlaidExchangeToken({
        public_token: public_token || currentPublicToken,
        connection_name: connectionName,
        is_created_by_staff: userDetails?.isStaff
      })
      setOpenDialog(false)
      setLoadingDialog(false)
      setResultDialog({ open: true, success: true })
      setConnectionName('')
      setCurrentPublicToken('')
    } catch (error) {
      console.error('Error exchanging public token:', error)
      setLoadingDialog(false)
      setResultDialog({ open: true, success: false })
      setConnectionName('')
      setCurrentPublicToken('')
    }
  }
  const handlePlaidConnectionAccounts = async (connection_id) => {
    setIsLoadingConnectionAccounts(true)
    try {
      const res = await GetPlaidConnectionAccounts({ connection_id })
      setConnectionAccounts(res.data)
      setPlaidError({
        error: false,
        message: ''
      })
    } catch (error) {
      if (error.response?.status === 400) {
        console.error('Bad request error:', error)
        setPlaidError({
          error: true,
          message: error.response?.data?.message
        })
      }
      if (error.response?.status === 500) {
        console.error('Error fetching connection accounts:', error.response)
      }
    } finally {
      setIsLoadingConnectionAccounts(false)
    }
  }
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit
  })

  useEffect(() => {
    if (linkToken && ready) {
      open()
    }
  }, [linkToken, ready, open])

  useEffect(() => {
    if (userDetails?.isStaff) {
      refreshConnections()
    }
  }, [userDetails?.isStaff])

  useEffect(() => {
    setConnections(
      isActiveConnectionStatus ? allConnections.active : allConnections.inactive
    )
  }, [isActiveConnectionStatus, allConnections])

  useEffect(() => {
    if (userDetails?.isStaff) {
      setIsLoadingAccounts(true)
      GetPlaidMatchedAccounts({
        institution_id: selectedInstitution?.id,
        validation_state: selectedValidationState?.value
      }).then((res) => {
        setMatchedAccounts(res.data)
        setIsLoadingAccounts(false)
      })
    }
  }, [userDetails?.isStaff])

  const handleEditConnection = (connection) => {
    if (!loading) {
      setEditDialog({
        open: true,
        connection: connection
      })
      setConnectionAccounts([])
      setEditedName(connection.name)
      handlePlaidConnectionAccounts(connection.id)
    }
  }

  const handleDeleteConnection = async (connection_id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este elemento?')) {
      try {
        await DeletePlaidConnection({ connection_id: connection_id })
        successNotification('deletedCorrectly')
        await refreshConnections()
        setEditDialog({ open: false, connection: null })
        setLoadingDialog(false)
      } catch (error) {
        errorNotification('generalError')
      }
    }
  }

  const handleUpdateConnection = async (connection_id, name, is_active) => {
    setLoadingDialogText('Actualizando credencial...')
    setLoadingDialog(true)
    try {
      await UpdatePlaidConnection({ connection_id, name, is_active })
      await refreshConnections()
      setEditDialog({ open: false, connection: null })
      setLoadingDialog(false)
    } catch (error) {
      console.error('Error updating connection:', error)
      setLoadingDialog(false)
    }
  }

  const handleShowInUserDashboard = () => {
    setLoadingDialogText('Actualizando credencial...')
    setLoadingDialog(true)
    UpdatePlaidConnectionDisplayInUserDashboard({
      connection_id: editDialog.connection.id,
      display_in_user_dashboard: true
    })
      .then(async () => {
        await refreshConnections()
        setEditDialog({
          ...editDialog,
          connection: {
            ...editDialog.connection,
            display_in_user_dashboard: true
          }
        })
        successNotification('savedCorrectly')
        setLoadingDialog(false)
      })
      .catch((error) => {
        errorNotification('generalError')
        console.error('Error updating connection:', error)
        setLoadingDialog(false)
      })
  }

  const handleHideInUserDashboard = () => {
    setLoadingDialogText('Actualizando credencial...')
    setLoadingDialog(true)
    UpdatePlaidConnectionDisplayInUserDashboard({
      connection_id: editDialog.connection.id,
      display_in_user_dashboard: false
    })
      .then(async () => {
        await refreshConnections()
        setEditDialog({
          ...editDialog,
          connection: {
            ...editDialog.connection,
            display_in_user_dashboard: false
          }
        })
        successNotification('savedCorrectly')
        setLoadingDialog(false)
      })
      .catch((error) => {
        errorNotification('generalError')
        console.error('Error updating connection:', error)
        setLoadingDialog(false)
      })
  }

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.reportContainer}>
          <Card className={classes.reportCard}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography className={classes.cardReportName}>
                Plaid Integration
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={fetchLinkToken}
                >
                  Connect a bank account
                </Button>
              )}
            </Grid>
          </Card>
        </div>
      </div>
      <div>
        {userDetails?.isStaff && (
          <div>
            <div className={classes.navbar}>
              <Button
                onClick={() => {
                  setDisplyedTable('connections')
                  setIsActiveConnectionStatus(true)
                }}
                variant={
                  displyedTable === 'connections' && isActiveConnectionStatus
                    ? 'contained'
                    : 'text'
                }
                color={
                  displyedTable === 'connections' && isActiveConnectionStatus
                    ? 'primary'
                    : 'default'
                }
              >
                Active Connections
              </Button>
              <Button
                onClick={() => {
                  setDisplyedTable('connections')
                  setIsActiveConnectionStatus(false)
                }}
                variant={
                  displyedTable === 'connections' && !isActiveConnectionStatus
                    ? 'contained'
                    : 'text'
                }
                color={
                  displyedTable === 'connections' && !isActiveConnectionStatus
                    ? 'primary'
                    : 'default'
                }
              >
                Inactive Connections
              </Button>
              <Button
                onClick={() => {
                  setDisplyedTable('accounts')
                }}
                variant={displyedTable === 'accounts' ? 'contained' : 'text'}
                color={displyedTable === 'accounts' ? 'primary' : 'default'}
              >
                Matched Accounts
              </Button>
              <Button
                onClick={() => {
                  setDisplyedTable('assets')
                }}
                variant={displyedTable === 'assets' ? 'contained' : 'text'}
                color={displyedTable === 'assets' ? 'primary' : 'default'}
              >
                Assets
              </Button>
              <Button
                onClick={() => {
                  setDisplyedTable('institutions')
                }}
                variant={
                  displyedTable === 'institutions' ? 'contained' : 'text'
                }
                color={displyedTable === 'institutions' ? 'primary' : 'default'}
              >
                Institutions
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              {displyedTable === 'connections' && (
                <div>
                  {isLoadingConnections ? (
                    <div className={classes.reportContainer}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Código de Usuario</TableCell>
                            <TableCell>Nombre de Credenciales</TableCell>
                            <TableCell>Institución</TableCell>
                            <TableCell>
                              Nombre de Institución en Plaid
                            </TableCell>
                            <TableCell>ID de Institución en Plaid</TableCell>
                            <TableCell>Creado el</TableCell>
                            <TableCell>Actualizado el</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Connections.map((connection) => (
                            <TableRow
                              key={connection.id}
                              onClick={() => handleEditConnection(connection)}
                              style={{
                                cursor: loading ? 'default' : 'pointer',
                                backgroundColor: connection.update_required
                                  ? '#edbebe'
                                  : 'inherit'
                              }}
                              hover
                            >
                              <TableCell>{connection.user_code}</TableCell>
                              <TableCell>{connection.name}</TableCell>
                              <TableCell>
                                {connection.institution_name
                                  ? connection.institution_name
                                  : 'Institución no creada'}
                              </TableCell>
                              <TableCell>
                                {connection.plaid_institution_name}
                              </TableCell>
                              <TableCell>
                                {connection.plaid_institution_id}
                              </TableCell>
                              <TableCell>
                                {format(
                                  new Date(connection.created_at),
                                  'dd/MM/yyyy HH:mm:ss'
                                )}
                              </TableCell>
                              <TableCell>
                                {format(
                                  new Date(connection.updated_at),
                                  'dd/MM/yyyy HH:mm:ss'
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              )}
              {displyedTable === 'accounts' && (
                <PlaidAccounts
                  matchedAccounts={matchedAccounts}
                  setMatchedAccounts={setMatchedAccounts}
                  isLoadingAccounts={isLoadingAccounts}
                  setIsLoadingAccounts={setIsLoadingAccounts}
                  selectedInstitution={selectedInstitution}
                  setSelectedInstitution={setSelectedInstitution}
                  selectedValidationState={selectedValidationState}
                  setSelectedValidationState={setSelectedValidationState}
                />
              )}
              {displyedTable === 'institutions' && <PlaidInstitutions />}
              {displyedTable === 'assets' && <PlaidAssets />}
            </div>
          </div>
        )}
      </div>
      <div>{!userDetails?.isStaff && <PlaidUserConnections />}</div>

      {/* Ingresar nombre Credenciales */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Nombre de las credenciales</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Ingrese nombre para las credenciales"
            fullWidth
            value={connectionName}
            onChange={(e) => setConnectionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCurrentPublicToken('')
              setOpenDialog(false)
              setConnectionName('')
            }}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleSubmitToken(currentPublicToken)}
            color="primary"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading Dialog Text*/}
      <Dialog open={loadingDialog}>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <CircularProgress />
            <Typography>{loadingDialogText}</Typography>
          </div>
        </DialogContent>
      </Dialog>

      {/* Result Dialog */}
      <Dialog
        open={resultDialog.open}
        onClose={() => setResultDialog({ ...resultDialog, open: false })}
      >
        <DialogTitle>{resultDialog.success ? 'Éxito' : 'Error'}</DialogTitle>
        <DialogContent>
          <Typography>
            {resultDialog.success
              ? '¡Las credenciales se ingresaron correctamente!'
              : 'Error al crear las credenciales. Por favor intente nuevamente y asegurese de poner "Finish without saving"'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setResultDialog({ ...resultDialog, open: false })}
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Editar Credenciales */}
      <Dialog
        open={editDialog.open}
        onClose={() => {
          setConnectionAccounts([])
          setPlaidError({
            error: false,
            message: ''
          })
          setEditDialog({ open: false, connection: null })
        }}
      >
        <DialogTitle>Editar Credencial</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              width: '100%'
            }}
          >
            {editDialog.connection?.update_required && (
              <>
                <div
                  style={{
                    flex: '1 1 50%',
                    maxWidth: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      fullWidth
                      onClick={() =>
                        fetchUpdateLinkToken(editDialog.connection?.id)
                      }
                    >
                      Actualizar Credencial en Plaid
                    </Button>
                  )}
                </div>
                <div
                  style={{
                    flex: '1 1 50%',
                    maxWidth: '50%'
                  }}
                >
                  {!editDialog.connection?.display_in_user_dashboard && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      fullWidth
                      onClick={handleShowInUserDashboard}
                    >
                      Mostrar en Usuario
                    </Button>
                  )}
                  {editDialog.connection?.display_in_user_dashboard && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      fullWidth
                      onClick={handleHideInUserDashboard}
                    >
                      Ocultar en Usuario
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre de las credenciales"
            fullWidth
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
          <Typography variant="body2" style={{ marginTop: 16 }}>
            Institución: {editDialog.connection?.institution_name}
          </Typography>
          <Typography variant="body2">
            Usuario: {editDialog.connection?.user_code}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={editDialog.connection?.is_active}
                onChange={(e) => {
                  setEditDialog({
                    ...editDialog,
                    connection: {
                      ...editDialog.connection,
                      is_active: e.target.checked
                    }
                  })
                }}
                color="primary"
              />
            }
            label="Activo"
            style={{ marginTop: 16 }}
          />
          {isLoadingConnectionAccounts ? (
            <div className={classes.reportContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {plaidError.error && (
                <Typography variant="body2" color="error">
                  {plaidError.message}
                </Typography>
              )}
              {!plaidError.error && (
                <>
                  <Typography variant="h6">Cuentas:</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre de la cuenta</TableCell>
                          <TableCell>Nombre Oficial</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {connectionAccounts.map((account) => (
                          <TableRow key={account.id}>
                            <TableCell>{account.name}</TableCell>
                            <TableCell>{account.official_name}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteConnection(editDialog.connection?.id)}
            color="secondary"
          >
            Eliminar
          </Button>
          <Button
            onClick={() => setEditDialog({ open: false, connection: null })}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() =>
              handleUpdateConnection(
                editDialog.connection?.id,
                editedName,
                editDialog.connection?.is_active
              )
            }
            color="primary"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PlaidIntegration
