import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import MuiAlert from '@material-ui/lab/Alert'
import React, { useState } from 'react'

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const PortfolioSelectionTableComponent = ({
  portfolioWeightsDict,
  COLORS_GRAPH_ASSETS,
  setSelectedPortfolio,
  handleEditPortfolio,
  handleSavePortfolio,
  handleDeletePortfolio
}) => {
  const [savingPortfolio, setSavingPortfolio] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const handleSaveClick = async (portfolioKey) => {
    setSavingPortfolio(true)
    await handleSavePortfolio(portfolioKey)
    setSavingPortfolio(false)
    setShowSuccessMessage(true)
  }

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false)
  }

  return (
    <Box my={2}>
      <Paper>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" align="left">
                <b>Portafolios</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                align="right"
                style={{ marginRight: '8px' }}
              >
                <b>Acciones</b>
              </Typography>
            </Grid>
          </Grid>

          {Object.keys(portfolioWeightsDict).map((portfolioKey, index) => (
            <Grid
              container
              spacing={2}
              key={portfolioKey + index}
              style={{ marginTop: '0.5em' }}
              alignItems="center"
            >
              <Grid item xs={9}>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => setSelectedPortfolio(portfolioKey)}
                  style={{ cursor: 'pointer' }}
                >
                  <Box
                    width={10}
                    height={10}
                    borderRadius="50%"
                    bgcolor={
                      COLORS_GRAPH_ASSETS[index % COLORS_GRAPH_ASSETS.length]
                    }
                    marginRight={1}
                  />
                  <Typography variant="body1">
                    <b>{portfolioKey}</b>
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={3}
                align="right"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'right'
                }}
              >
                <Tooltip title="Guardar" arrow>
                  <IconButton
                    onClick={() => handleSaveClick(portfolioKey)}
                    size="small"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                    disabled={savingPortfolio}
                  >
                    {savingPortfolio ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SaveIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>

                <Tooltip title="Editar" arrow>
                  <IconButton
                    onClick={() => handleEditPortfolio(portfolioKey)}
                    size="small"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Remover" arrow>
                  <IconButton
                    onClick={() => handleDeletePortfolio(portfolioKey)}
                    size="small"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = 'red'
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = ''
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ))}

          <Snackbar
            open={showSuccessMessage}
            autoHideDuration={3000}
            onClose={handleCloseSuccessMessage}
          >
            <Alert onClose={handleCloseSuccessMessage} severity="success">
              ¡Portafolio guardado con éxito!
            </Alert>
          </Snackbar>
        </Box>
      </Paper>
    </Box>
  )
}

const PortfolioSelectionTable = React.memo(PortfolioSelectionTableComponent)

export default PortfolioSelectionTable
