import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { COLORS_GRAPH_ASSETS, getTextColor } from 'utils/UI/Theme'

const useStyles = makeStyles((theme) => ({
  assetsSelectorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  assetsSelector: {},
  saveButton: {
    marginLeft: 'auto'
  },
  deleteIcon: {
    color: '#EAEAEA'
  }
}))

const RebalanceDialog = ({
  open,
  setOpen,
  portfolioName,
  loadingSearch,
  openRebalanceDialog,
  setOpenRebalanceDialog,
  setPortfolioName,
  optionsTickersDict,
  optionsTickersIds,
  tickers,
  setOptionsTickers,
  handleOnInputChange,
  handleTickerSelection,
  tickersWeightsDict,
  handleWeightChange,
  portfolioInfoError,
  rebalanceFrequency,
  setRebalanceFrequency,
  handleNewPortfolioInfo,
  setpreviousPortfolioName,
  selectedTickersNamesDict
}) => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openRebalanceDialog}
      onClose={() => {
        setOpenRebalanceDialog(false)
      }}
    >
      <DialogTitle>Selecciona Activos</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            label="Nombre del Portafolio"
            variant="outlined"
            fullWidth
            value={portfolioName}
            onChange={(e) => setPortfolioName(e.target.value)}
            placeholder="Ej: Portafolio 1"
          />
        </Box>
        <Box mb={2}>
          <Autocomplete
            loading={loadingSearch}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            multiple
            getOptionLabel={(option) =>
              `${optionsTickersDict[option]} (${option})`
            }
            filterSelectedOptions
            getOptionSelected={(option, value) => value === option}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return (
                  <Tooltip title={option} key={index}>
                    <Chip
                      style={{
                        marginRight: 12,
                        backgroundColor: COLORS_GRAPH_ASSETS[0],
                        color: getTextColor(COLORS_GRAPH_ASSETS[0])
                      }}
                      size="small"
                      label={option}
                      classes={{ deleteIcon: classes.deleteIcon }}
                      {...getTagProps({ index })}
                    />
                  </Tooltip>
                )
              })
            }
            loadingText={
              loadingSearch ? 'Buscando...' : 'No se encontraron resultados'
            }
            onBlur={() => setOptionsTickers([])}
            options={loadingSearch ? [] : optionsTickersIds}
            onInputChange={(e) => handleOnInputChange(e)}
            value={tickers}
            size="small"
            onChange={(_, _tickers) => {
              handleTickerSelection(_tickers)
            }}
            className={classes.assetsSelector}
            noOptionsText="No se encontraron resultados"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecciona uno o más activos:"
                variant="outlined"
                placeholder="Activos"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>
                }}
              />
            )}
          />
        </Box>

        <Box mb={2}>
          <Grid container spacing={2}>
            {tickers.map((ticker) => (
              <Grid item container key={ticker} alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Chip
                      label={ticker}
                      style={{
                        backgroundColor:
                          COLORS_GRAPH_ASSETS[0] || COLORS_GRAPH_ASSETS[0],
                        color: getTextColor(
                          COLORS_GRAPH_ASSETS[0] || COLORS_GRAPH_ASSETS[0]
                        )
                      }}
                    />
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{ display: 'block', textAlign: 'left' }}
                    >
                      {selectedTickersNamesDict[ticker] || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Peso (%)"
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 100,
                      step: 1,
                      style: { textAlign: 'right' }
                    }}
                    style={{ width: 80 }}
                    value={tickersWeightsDict[ticker] || ''}
                    onChange={(e) => handleWeightChange(ticker, e.target.value)}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>

        {portfolioInfoError && (
          <Typography color="error">{portfolioInfoError}</Typography>
        )}

        <Box mt={2}>
          <Autocomplete
            options={[
              'No Rebalance',
              'Weekly',
              'Monthly',
              'Quarterly',
              'Annually'
            ]}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Frecuencia de rebalanceo"
                variant="outlined"
                placeholder="Selecciona una opción"
              />
            )}
            value={rebalanceFrequency}
            onChange={(event, value) => setRebalanceFrequency(value)}
            style={{ marginTop: 16 }}
          />
        </Box>

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            onClick={() => {
              handleNewPortfolioInfo()
            }}
            color="primary"
          >
            Enviar
          </Button>
          <Button
            onClick={() => {
              setOpenRebalanceDialog(false)
              setpreviousPortfolioName('')
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default RebalanceDialog
