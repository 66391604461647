import React, { useEffect } from 'react'

import { FormControl, InputLabel, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import useResponsive from 'hooks/useResponsive'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    '& .MuiTabs-indicator': {
      height: 2
    },
    '& .MuiTab-root': {
      minWidth: 100,
      padding: '0 10px',
      fontSize: '0.8rem',
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.text.primary
      }
    }
  },
  selected: {
    // fontWeight: 'bold',
  },
  formControl: {
    margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
    minWidth: 120
  }
}))

export default function CustomButtonGroup({
  modeList,
  currentValue,
  onClickHandler
}) {
  const classes = useStyles()
  const { isMobile } = useResponsive()
  const [value, setValue] = React.useState(currentValue)

  const handleChange = (event, newValue) => {
    if (isMobile) {
      setValue(event.target.value)
      return
    }
    setValue(newValue)
  }

  useEffect(() => {
    onClickHandler(value)
  }, [value, onClickHandler])

  if (isMobile) {
    return (
      <FormControl
        variant="outlined"
        size="small"
        className={classes.formControl}
      >
        <InputLabel id="custom-mode-selector" htmlFor="custom-mode-selector">
          Seleccionar
        </InputLabel>
        <Select
          native
          value={value}
          onChange={handleChange}
          label="Seleccionar"
          labelId="custom-mode-selector"
          inputProps={{
            name: 'mode-selector',
            id: 'custom-mode-selector'
          }}
        >
          {modeList.map((mode, i) => {
            return (
              <option key={i} value={mode.key}>
                {mode.label}
              </option>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  return (
    <Tabs
      className={classes.root}
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      centered
      scrollButtons="off"
    >
      {modeList.map((mode, i) => {
        return (
          <Tab
            key={i}
            classes={{ selected: classes.selected }}
            label={mode.label}
            value={mode.key}
          />
        )
      })}
    </Tabs>
  )
}
