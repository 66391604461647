import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import AbaqusLogo from 'assets/images/abaqus-logo-black.svg'
import LoginForm from 'components/LoginPage/LoginForm'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    }
  },
  LoginPageContainer: {
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    padding: 0,
    background: theme.palette.secondary.light,
    overflow: 'hidden'
  },
  LoginPageGridContainer: {
    height: 'inherit',
    width: 'inherit',

    backgroundRepeat: 'repeat',
    maxHeight: '100%',
    maxWidth: '100%',
    padding: 0
  },
  LoginPageImage: {
    height: 'inherit',
    width: 'inherit',
    maxHeight: '100%',
    maxWidth: '100%'
  },
  LoginPageFormContainer: {
    '& h6': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      padding: 5
    },
    [theme.breakpoints.down('xs')]: {
      padding: 1
    }
  },
  LoginPageAbaqusLogo: {
    width: '35%',
    maxWidth: 300,
    minWidth: 200,
    marginBottom: theme.spacing(3)
  }
}))

export default function LoginPage() {
  const classes = useStyles()
  const { t } = useTranslation(['auth'])

  return (
    <Container maxWidth={false} className={classes.LoginPageContainer}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.LoginPageGridContainer}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <CardWrapper className={classes.card}>
            <Container
              maxWidth={false}
              className={classes.LoginPageFormContainer}
            >
              <img
                src={AbaqusLogo}
                className={classes.LoginPageAbaqusLogo}
                alt="logo-abq"
              />
              <Typography variant="subtitle2" gutterBottom>
                {t('Bienvenido, inicia sesión para continuar')}
              </Typography>
              <LoginForm />
            </Container>
          </CardWrapper>
        </Grid>
      </Grid>
    </Container>
  )
}
