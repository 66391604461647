import ContractsTable from 'components/Graphs/ContractsTable'
import InvoicesTable from 'components/Graphs/InvoicesTable'
import OtherDocumentsTable from 'components/Graphs/OtherDocumentsTable'
import StatementsTable from 'components/Graphs/StatementsTable'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

export default function Documents() {
  return (
    <Auxiliary>
      <StatementsTable />
      <InvoicesTable />
      <ContractsTable />
      <OtherDocumentsTable />
    </Auxiliary>
  )
}
