import DateFnsUtils from '@date-io/date-fns'
import {
  Box,
  Button,
  Divider,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import SendIcon from '@material-ui/icons/Send'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import AbaqusLogo from 'assets/images/abaqus-logo-black.svg'
import useDebounce from 'hooks/useDebounce'
import { useEffect, useMemo, useState } from 'react'

import {
  AssetRequest,
  AssetSearchRequest,
  ClientsRequest,
  CommandExecuteRequest,
  GetHoldingInstitutionRequest
} from 'axios/requests/operations'
import { format } from 'date-fns'
import { errorNotification } from 'utils/UI/Notifications/Notifications'

const useStyles = makeStyles((theme) => ({
  chatPage: {
    display: 'flex',
    gap: '10px',
    padding: '5px',
    height: '80vh',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',
    borderRadius: '8px'
  },
  sidebar: {
    flex: '0 0 25%',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    paddingTop: theme.spacing(3),
    borderRadius: theme.spacing(1),
    overflowY: 'auto'
  },
  messageListContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: theme.spacing(1),
    backgroundColor: '#f0f0f0'
  },
  messageList: {
    flex: 1,
    overflowY: 'auto',
    padding: '20px'
  },
  inputButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    marginLeft: 'auto'
  },
  commands: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  commandsTitle: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold'
  },
  logoContainer: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    height: '40px'
  },
  commandButtonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  commandButton: {
    flex: '0 1 calc(50% - 10px)',
    margin: '5px',
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    },
    '&.active': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    }
  },
  messageCard: {
    backgroundColor: '#ffffff',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word'
  },
  secondaryInputBar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#f0f0f0',
    gap: theme.spacing(1), // Add gap between items for better spacing
    justifyContent: 'space-between'
  },
  autocomplete: {
    flexGrow: 2, // Allow the Autocomplete to grow
    minWidth: '0', // Ensure it can shrink appropriately
    marginRight: theme.spacing(1),
    display: 'flex' // Ensure flex properties apply
  },
  optionButton: {
    flexGrow: 0.5, // Allow the Button to grow
    minWidth: '0', // Ensure it can shrink appropriately
    margin: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    }
  },
  autocompleteInput: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.dark
      },
      '&.Mui-error fieldset': {
        borderColor: theme.palette.error.main
      }
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: theme.palette.primary.main
    },
    '& .MuiInputLabel-outlined.Mui-error': {
      color: theme.palette.error.main
    }
  },
  datePickerInput: {
    flexGrow: 1, // Allow the Date Picker to grow
    minWidth: '0', // Ensure it can shrink appropriately
    marginRight: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.dark
      }
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: theme.palette.primary.main
    }
  },
  autocompleteRoot: {
    display: 'flex',
    flexGrow: 4
  },
  chipStyle: {
    marginRight: 12,
    backgroundColor: theme.palette.orange.lighten1,
    color: theme.palette.getContrastText(theme.palette.orange.lighten1)
  },
  assetsSelector: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'orange'
      },
      '&:hover fieldset': {
        borderColor: 'orange'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'orange'
      }
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: 'orange'
    }
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1.75)
  },
  commandDescription: {
    backgroundColor: '#ffffff',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word'
  },
  requiredField: {
    fontWeight: 'bold',
    color: theme.palette.text.primary
  }
}))

const periodsList = ['1Y', '2Y', '3Y', 'latest', 'inception']

const commandOptions = {
  'Write Client Data': [
    { name: 'user_code', type: 'autocomplete', optionType: 'client' },
    { name: 'start_date', type: 'date' },
    { name: 'overwrite', type: 'button' }
  ],
  'Update Market Data': [
    { name: 'tickers', type: 'autocomplete', optionType: 'ticker' },
    { name: 'period', type: 'autocomplete', optionType: 'period' }
  ],
  'Update Quotes': [
    { name: 'tickers', type: 'autocomplete', optionType: 'ticker' },
    { name: 'period', type: 'autocomplete', optionType: 'period' }
  ],
  'Update Dividends': [
    { name: 'tickers', type: 'autocomplete', optionType: 'ticker' },
    { name: 'period', type: 'autocomplete', optionType: 'period' }
  ],
  'Update Splits': [
    { name: 'tickers', type: 'autocomplete', optionType: 'ticker' },
    { name: 'period', type: 'autocomplete', optionType: 'period' }
  ],
  'Fill Quotes': [
    { name: 'assets', type: 'autocomplete', optionType: 'ticker' },
    { name: 'start_date', type: 'date' }
  ],
  'Load Dividends': [],
  'Load Documents': [
    { name: 'client', type: 'autocomplete', optionType: 'client' }
  ],
  'Validate Holdings': [
    { name: 'date', type: 'date' },
    {
      name: 'institution',
      type: 'autocomplete',
      optionType: 'institution'
    }
  ],
  'Get Holdings': [],
  'Load Assets': [{ name: 'staging', type: 'button' }],
  'Load Institutions': [],
  'Generate Transactions': [
    {
      name: 'institution',
      type: 'autocomplete',
      optionType: 'institution'
    },
    { name: 'start_date', type: 'date' },
    { name: 'end_date', type: 'date' }
  ],
  'Add Daps': [
    { name: 'start_date', type: 'date' },
    { name: 'end_date', type: 'date' }
  ],
  'Load Quotes': [
    { name: 'assets', type: 'autocomplete', optionType: 'ticker' }
  ],
  'Update Quotes Bcs_Pdf Task': [
    { name: 'tickers', type: 'autocomplete', optionType: 'ticker' },
    { name: 'period', type: 'autocomplete', optionType: 'period' }
  ],
  'Update System Data Task': [
    { name: 'clients', type: 'autocomplete', optionType: 'clients' },
    { name: 'start_date', type: 'date' }
  ],
  'Write Client Data Task': [
    { name: 'clients', type: 'autocomplete', optionType: 'clients' },
    { name: 'start_date', type: 'date' },
    { name: 'overwrite', type: 'button' }
  ],
  'Send Monthly Email': [
    { name: 'month_code', type: 'date' },
    { name: 'official', type: 'button' }
  ],
  'Send Monthly Invoice Email': [
    { name: 'month_code', type: 'date' },
    { name: 'official', type: 'button' }
  ]
}

const commandsWithoutOptions = [
  'Load Dividends',
  'Load Assets',
  'Load Institutions',
  'Update System Data Task'
]

const requiredFields = {
  'Write Client Data': ['user_code'],
  'Load Documents': ['client'],
  'Validate Holdings': ['date', 'institution'],
  'Generate Transactions': ['institution', 'start_date', 'end_date'],
  'Add Daps': ['start_date', 'end_date'],
  'Write Client Data Task': ['clients'],
  'Update Quotes': ['tickers'],
  'Update Market Data': ['tickers'],
  'Update Dividends': ['tickers'],
  'Update Splits': ['tickers'],
  'Update Quotes Bcs_Pdf Task': ['tickers'],
  'Load Quotes': ['assets']
}

const commandDescriptions = {
  'Write Client Data': 'Actualiza los datos de un cliente específico.',
  'Update Market Data':
    'Actualiza datos de mercado para los tickers seleccionados. Por defecto el periodo es "latest".',
  'Update Quotes':
    'Actualiza precios para los tickers seleccionados. Por defecto el periodo es "latest".',
  'Update Dividends':
    'Actualiza dividendos para los tickers seleccionados. Por defecto el periodo es "latest".',
  'Update Splits':
    'Actualiza splits para los tickers seleccionados. Por defecto el periodo es "latest".',
  'Fill Quotes':
    'Rellena precios para los activos seleccionados desde una fecha específica. Por defecto la fecha inicial es Hoy - 31 días.',
  'Load Dividends': 'Carga la información de dividendos en el sistema.',
  'Load Documents': 'Carga documentos para un cliente específico.',
  'Validate Holdings':
    'Valida las posiciones para una institución y fecha específica.',
  'Get Holdings': 'Obtiene información de las posiciones actuales.',
  'Load Assets': 'Carga información de activos en el sistema.',
  'Load Institutions': 'Carga información de instituciones en el sistema.',
  'Generate Transactions':
    'Genera transacciones para una institución en un rango de fechas. Por defecto la fecha inicial es igual a la fecha final.',
  'Add Daps': 'Agrega DAPs en un rango de fechas específico.',
  'Load Quotes': 'Carga precios para los activos seleccionados.',
  'Update Quotes Bcs_Pdf Task':
    'Actualiza precios desde PDF de BCS para los tickers seleccionados.',
  'Update System Data Task':
    'Actualiza información diaria de multiples clientes.',
  'Write Client Data Task': 'Actualiza datos de multiples clientes.',
  'Send Monthly Email':
    'Envía correos mensuales de reportes para un mes específico.',
  'Send Monthly Invoice Email':
    'Envía correos mensuales de facturas para un mes específico.'
}

export default function ConsolePage() {
  const classes = useStyles()
  const theme = useTheme()
  const [messages, setMessages] = useState([])
  const [showCommandOptions, setShowCommandOptions] = useState(false)
  const [activeCommand, setActiveCommand] = useState('')
  const [isWorking, setIsWorking] = useState(false)
  const [optionValues, setOptionValues] = useState({})
  const [clients, setClients] = useState([])
  const [selectedButtons, setSelectedButtons] = useState({})
  const [search, setSearch] = useState('')
  const [loadingAssetSearch, setLoadingAssetSearch] = useState(false)
  const [optionsTickers, setOptionsTickers] = useState([])
  const [tickers, setTickers] = useState([])
  const [assetsNamesDict, setAssetsNamesDict] = useState({})
  const debouncedSearch = useDebounce(search, 500)
  const [assetOpen, setAssetOpen] = useState(false)
  const [clientOpen, setClientOpen] = useState(false)
  const [institutions, setInstitutions] = useState([])
  const [institutionOpen, setInstitutionOpen] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const commands = Object.keys(commandOptions)

  const handleSend = async () => {
    const requiredForCommand = requiredFields[activeCommand] || []
    const newErrors = {}
    let hasErrors = false

    requiredForCommand.forEach((fieldName) => {
      const value = optionValues[fieldName]

      if (fieldName === 'tickers' || fieldName === 'assets') {
        if (!tickers || tickers.length === 0) {
          newErrors[fieldName] = true
          hasErrors = true
        }
      } else if (fieldName === 'institution') {
        if (!value || !value.code) {
          newErrors[fieldName] = true
          hasErrors = true
        }
      } else if (!value || (Array.isArray(value) && value.length === 0)) {
        newErrors[fieldName] = true
        hasErrors = true
      }
    })

    setFieldErrors(newErrors)

    if (hasErrors) {
      return // Stop execution if there are validation errors
    }

    const command = activeCommand.toLowerCase().replace(/ /g, '_')
    const options = commandOptions[activeCommand].reduce((acc, arg) => {
      let value = optionValues[arg.name]
      if (arg.type === 'autocomplete' && arg.optionType === 'client' && value) {
        value = value.code
      }
      if (
        arg.type === 'autocomplete' &&
        arg.optionType === 'clients' &&
        value
      ) {
        value = value.map((client) => client.code).join(',')
      }
      if (arg.type === 'autocomplete' && arg.optionType === 'ticker') {
        value = tickers.join(',')
      }
      if (
        arg.type === 'autocomplete' &&
        arg.optionType === 'institution' &&
        value
      ) {
        value = value.code
      }
      if (arg.type === 'date' && value) {
        value = format(new Date(value), 'yyyy-MM-dd')
      }
      if (typeof value === 'boolean') {
        value = value ? 'true' : 'false'
      }
      if (value) {
        acc[arg.name] = value
      }
      return acc
    }, {})

    const payload = { command: command, args: options }

    if (
      Object.keys(options).length > 0 ||
      commandsWithoutOptions.includes(activeCommand)
    ) {
      const formattedArgs = Object.entries(options)
        .map(([key, value]) => `${key}=${value}`)
        .join(' ')

      const fullMessage = formattedArgs
        ? `${command} with args: ${formattedArgs}`
        : command

      setMessages((prevMessages) => [
        ...prevMessages,
        `Command sent: ${fullMessage}`
      ])
      setShowCommandOptions(false)
      setIsWorking(true)

      try {
        const response = await CommandExecuteRequest(payload)
        const { status, message, logs } = response.data
        if (logs && logs.length > 0) {
          setMessages((prevMessages) => [
            ...prevMessages,
            ...logs.map((log) => `[${log.level}]: ${log.message}`)
          ])
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          `Command executed with ${status} : ${message}`
        ])
      } catch (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          `Error sending command: ${error.message}`
        ])
      } finally {
        setIsWorking(false)
        setActiveCommand('')
      }
    }
  }

  const handleCommandClick = (commandName) => {
    if (!isWorking) {
      setActiveCommand(commandName)
      setShowCommandOptions(true)
      setOptionValues({})
      setSelectedButtons({})
      setFieldErrors({})
    }
  }

  const handleOptionChange = (name, value) => {
    setOptionValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }))
  }

  const handleButtonClick = (name) => {
    setOptionValues((prevValues) => ({
      ...prevValues,
      [name]: !prevValues[name]
    }))
    setSelectedButtons((prevButtons) => ({
      ...prevButtons,
      [name]: !prevButtons[name]
    }))
  }

  useEffect(() => {
    let didCancel = false
    const fetchClients = async () => {
      try {
        const result = await ClientsRequest()
        if (!didCancel) {
          setClients(result.data)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchClients()

    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        await AssetRequest()
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchAssets().then((result) => {
      if (result) {
        const assetsDict = result.reduce((acc, asset) => {
          acc[asset.id] = asset.name
          return acc
        }, {})
        setAssetsNamesDict(assetsDict)
      }
    })

    return () => {}
  }, [])

  useEffect(() => {
    const assetSearchTickers = async () => {
      try {
        const result = await AssetSearchRequest({
          search: debouncedSearch
        })
        return result.data
      } catch (err) {
        errorNotification('generalError')
      }
    }

    if (search.length > 0) {
      setLoadingAssetSearch(true)
      assetSearchTickers().then((tickersFounded) => {
        setOptionsTickers(tickersFounded || [])
        setLoadingAssetSearch(false)
      })
    } else {
      setLoadingAssetSearch(false)
      setOptionsTickers([])
    }
  }, [debouncedSearch])

  const handleAssetChange = (e) => {
    setSearch(e?.target?.value || '')
  }

  const optionsTickersDict = useMemo(() => {
    return optionsTickers.reduce((acc, ticker) => {
      acc[ticker.ticker] = ticker.name
      return acc
    }, {})
  }, [optionsTickers])

  const optionsTickersIds = useMemo(() => {
    return [...tickers, ...optionsTickers.map((ticker) => ticker.ticker)]
  }, [tickers, optionsTickers])

  useEffect(() => {
    let didCancel = false
    const fetchHoldingInstitutions = async () => {
      try {
        const response = await GetHoldingInstitutionRequest()
        if (!didCancel) {
          const data = response.data.map((row) => ({
            code: row.code,
            name: row.name
          }))
          setInstitutions(data)
        }
      } catch (error) {
        if (!didCancel) {
          errorNotification('generalError')
        }
      }
    }
    fetchHoldingInstitutions()
    return () => {
      didCancel = true
    }
  }, [])

  return (
    <div className={classes.chatPage}>
      <div className={classes.sidebar}>
        <div className={classes.commands}>
          <Typography variant="h6" className={classes.commandsTitle}>
            Commands
          </Typography>
        </div>
        <div className={classes.commandButtonContainer}>
          {commands.map((command, index) => (
            <Button
              key={index}
              variant="outlined"
              className={`${classes.commandButton} ${
                command === activeCommand ? 'active' : ''
              }`}
              onClick={() => handleCommandClick(command)}
              disabled={isWorking}
            >
              {command}
            </Button>
          ))}
        </div>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={classes.messageListContainer}>
        <div className={classes.logoContainer}>
          <img src={AbaqusLogo} alt="Abaqus Logo" className={classes.logo} />
        </div>
        <div className={classes.messageList}>
          {messages.map((msg, index) => (
            <div key={index} className={classes.messageCard}>
              <ListItemText primary={msg} />
            </div>
          ))}
        </div>
        {showCommandOptions && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box className={classes.commandDescription}>
              <ListItemText
                primary={commandDescriptions[activeCommand]}
                secondary={
                  requiredFields[activeCommand]?.length > 0 ? (
                    <>
                      Campos requeridos:{' '}
                      <span className={classes.requiredField}>
                        {(requiredFields[activeCommand] || [])
                          .map((field) => {
                            const option = commandOptions[activeCommand]?.find(
                              (opt) => opt.name === field
                            )
                            return option?.optionType || field
                          })
                          .join(', ')}
                      </span>
                    </>
                  ) : null
                }
              />
            </Box>
            <Box className={classes.secondaryInputBar}>
              {commandOptions[activeCommand].map((arg, index) => {
                if (
                  arg.type === 'autocomplete' &&
                  arg.optionType === 'client'
                ) {
                  return (
                    <Autocomplete
                      key={index}
                      open={clientOpen}
                      onOpen={() => setClientOpen(true)}
                      onClose={() => setClientOpen(false)}
                      options={clients}
                      getOptionLabel={(client) =>
                        client
                          ? `[${client.code}] ${client.first_name} ${client.last_name}`
                          : 'Select Client'
                      }
                      getOptionSelected={(option, value) =>
                        option.code === value.code
                      }
                      value={optionValues[arg.name] || null}
                      onChange={(event, newValue) => {
                        handleOptionChange(arg.name, newValue)
                        if (newValue) {
                          setFieldErrors((prev) => ({
                            ...prev,
                            [arg.name]: false
                          }))
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client"
                          variant="outlined"
                          className={`${classes.autocomplete} ${classes.autocompleteInput}`}
                          placeholder="Seleccionar Cliente"
                          error={fieldErrors[arg.name]}
                          helperText={
                            fieldErrors[arg.name]
                              ? 'This field is required'
                              : ''
                          }
                          required={requiredFields[activeCommand]?.includes(
                            arg.name
                          )}
                        />
                      )}
                      classes={{
                        root: classes.autocompleteRoot
                      }}
                    />
                  )
                } else if (
                  arg.type === 'autocomplete' &&
                  arg.optionType === 'clients'
                ) {
                  return (
                    <Autocomplete
                      key={index}
                      multiple
                      open={clientOpen}
                      onOpen={() => setClientOpen(true)}
                      onClose={() => setClientOpen(false)}
                      options={clients}
                      getOptionLabel={(client) =>
                        client
                          ? `[${client.code}] ${client.first_name} ${client.last_name}`
                          : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.code === value.code
                      }
                      value={optionValues[arg.name] || []}
                      onChange={(event, newValue) => {
                        handleOptionChange(arg.name, newValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Clients"
                          variant="outlined"
                          className={`${classes.autocomplete} ${classes.autocompleteInput}`}
                          placeholder="Seleccionar Clientes"
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            style={{
                              marginRight: 12,
                              backgroundColor: theme.palette.orange.lighten1,
                              color: theme.palette.getContrastText(
                                theme.palette.orange.lighten1
                              )
                            }}
                            label={option.code}
                            key={option.code}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      classes={{
                        root: classes.autocompleteRoot
                      }}
                    />
                  )
                } else if (
                  arg.type === 'autocomplete' &&
                  arg.optionType === 'ticker'
                ) {
                  return (
                    <Autocomplete
                      key={index}
                      label="Selecciona uno o más activos:"
                      variant="outlined"
                      placeholder="Activos"
                      multiple
                      style={{ width: '70%' }}
                      open={assetOpen}
                      onOpen={() => {
                        setAssetOpen(true)
                      }}
                      onClose={() => {
                        setAssetOpen(false)
                      }}
                      getOptionLabel={(option) => {
                        return `${optionsTickersDict[option]} (${option})`
                      }}
                      filterSelectedOptions
                      getOptionSelected={(option, value) => value === option}
                      renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                          <Tooltip
                            title={assetsNamesDict[option] || 'No name'}
                            key={index}
                          >
                            <Chip
                              style={{
                                marginRight: 12,
                                backgroundColor: theme.palette.orange.lighten1,
                                color: theme.palette.getContrastText(
                                  theme.palette.orange.lighten1
                                )
                              }}
                              size="small"
                              label={option}
                              key={option}
                              classes={{ deleteIcon: classes.deleteIcon }}
                              {...getTagProps({ index })}
                            />
                          </Tooltip>
                        ))
                      }}
                      loadingText={
                        loadingAssetSearch
                          ? 'Buscando...'
                          : 'No se encontraron resultados'
                      }
                      onBlur={() => setOptionsTickers([])}
                      options={loadingAssetSearch ? [] : optionsTickersIds}
                      onInputChange={(e) => handleAssetChange(e)}
                      value={tickers}
                      onChange={(_, _tickers) => {
                        setTickers(_tickers)
                      }}
                      className={`${classes.assetsSelector} ${classes.autocomplete}`}
                      noOptionsText="No se encontraron resultados"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Selecciona uno o más activos:"
                            variant="outlined"
                            placeholder="Activos"
                            error={
                              fieldErrors['tickers'] || fieldErrors['assets']
                            }
                            helperText={
                              fieldErrors['tickers'] || fieldErrors['assets']
                                ? 'This field is required'
                                : ''
                            }
                            required={
                              requiredFields[activeCommand]?.includes(
                                'tickers'
                              ) ||
                              requiredFields[activeCommand]?.includes('assets')
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>{params.InputProps.endAdornment}</>
                              )
                            }}
                          />
                        )
                      }}
                      classes={{
                        root: classes.autocompleteRoot
                      }}
                    />
                  )
                } else if (
                  arg.type === 'autocomplete' &&
                  arg.optionType === 'period'
                ) {
                  return (
                    <Autocomplete
                      key={index}
                      options={periodsList}
                      getOptionLabel={(option) => option}
                      getOptionSelected={(option, value) => option === value}
                      value={optionValues[arg.name] || null}
                      onChange={(event, newValue) => {
                        handleOptionChange(arg.name, newValue)
                      }}
                      style={{ width: '30%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Period"
                          className={`${classes.autocomplete} ${classes.autocompleteInput}`}
                          placeholder="Select Period"
                        />
                      )}
                      classes={{
                        root: classes.autocompleteRoot
                      }}
                    />
                  )
                } else if (
                  arg.type === 'autocomplete' &&
                  arg.optionType === 'institution'
                ) {
                  return (
                    <Autocomplete
                      key={index}
                      open={institutionOpen}
                      onOpen={() => setInstitutionOpen(true)}
                      onClose={() => setInstitutionOpen(false)}
                      options={institutions}
                      getOptionLabel={(institution) =>
                        institution
                          ? `[${institution.code}] ${institution.name}`
                          : 'Select Institution'
                      }
                      getOptionSelected={(option, value) =>
                        option.code === value.code
                      }
                      value={optionValues[arg.name] || null}
                      onChange={(event, newValue) => {
                        handleOptionChange(arg.name, newValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Institution"
                          variant="outlined"
                          className={`${classes.autocomplete} ${classes.autocompleteInput}`}
                          placeholder="Seleccionar Institución"
                          error={fieldErrors['institution']}
                          helperText={
                            fieldErrors['institution']
                              ? 'This field is required'
                              : ''
                          }
                          required={requiredFields[activeCommand]?.includes(
                            'institution'
                          )}
                        />
                      )}
                      classes={{
                        root: classes.autocompleteRoot
                      }}
                    />
                  )
                } else if (arg.type === 'button') {
                  return (
                    <Button
                      key={index}
                      variant="outlined"
                      className={`${classes.optionButton} ${
                        selectedButtons[arg.name] ? 'selected' : ''
                      }`}
                      onClick={() => handleButtonClick(arg.name)}
                    >
                      {arg.name.toUpperCase()}
                    </Button>
                  )
                } else if (arg.type === 'date') {
                  return (
                    <KeyboardDatePicker
                      key={index}
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      label={arg.name}
                      value={optionValues[arg.name] || null}
                      onChange={(date) => {
                        handleOptionChange(arg.name, date)
                        if (date) {
                          setFieldErrors((prev) => ({
                            ...prev,
                            [arg.name]: false
                          }))
                        }
                      }}
                      className={classes.datePickerInput}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      disableToolbar
                      error={fieldErrors[arg.name]}
                      helperText={
                        fieldErrors[arg.name] ? 'This field is required' : ''
                      }
                      required={requiredFields[activeCommand]?.includes(
                        arg.name
                      )}
                      onAccept={() => {
                        const datePicker =
                          document.activeElement.closest('.MuiDialog-root')
                        if (datePicker) {
                          datePicker.style.display = 'none'
                        }
                      }}
                    />
                  )
                }
                return null
              })}
              <Button
                className={classes.inputButton}
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                onClick={handleSend}
                disabled={isWorking}
              >
                Send
              </Button>
            </Box>
          </MuiPickersUtilsProvider>
        )}
      </div>
    </div>
  )
}
