import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { StyledTableCell, StyledTableContainer } from 'assets/jss/Tables/Tables'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  hideSortIcon: {
    pointerEvents: 'none',
    '& .MuiTableSortLabel-icon': {
      display: 'none',
      visibility: 'hidden'
    }
  },
  fontBold: {
    fontFamily: 'InconsolataSemiBold'
  },
  tableContainer: {
    overflowX: 'auto',
    position: 'relative',
    maxWidth: '100%'
  },
  tableContainerFullscreen: {
    maxHeight: '90vh'
  },
  tableRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  tableCell: {
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.875rem',
    textAlign: 'left', // Alineación por defecto
    '&.clickable': {
      cursor: 'pointer'
    },
    '& .cellContent': {
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  headerCell: {
    borderBottom: '1px solid whitesmoke',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '5rem',
    lineHeight: '1.5rem',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  fullscreenContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.drawer + 1
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function getColumnWidths(colsData) {
  const totalWidth = window.innerWidth
  const columnWidth = totalWidth / colsData.length
  return Array(colsData.length).fill(columnWidth)
}

export default function DefaultTable({
  colsData,
  rowsData,
  header = null,
  size = 'medium',
  classes,
  isFullscreen,
  onRowClick,
  maxHeight = '400px',
  maxWidth = '100%',
  minWidth = '100%',
  sorteable = true,
  cellPadding = 16
}) {
  const defaultClasses = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(null)

  const data = useMemo(() => {
    if (!orderBy) return rowsData
    const sortedData = rowsData.sort((a, b) => {
      if (typeof a[orderBy] === 'string') {
        return order === 'asc'
          ? a[orderBy].localeCompare(b[orderBy])
          : b[orderBy].localeCompare(a[orderBy])
      } else {
        return order === 'asc'
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy]
      }
    })
    return sortedData
  }, [order, orderBy, rowsData])

  const columnWidths = useMemo(() => getColumnWidths(colsData), [colsData])

  const combinedClasses = { ...defaultClasses, ...classes }

  const tableContent = (
    <StyledTableContainer
      className={
        isFullscreen
          ? combinedClasses.tableContainerFullscreen
          : combinedClasses.tableContainer
      }
      style={{ maxHeight, maxWidth }} // Aplicar el maxWidth al contenedor
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        size={size}
        style={{
          display: 'block',
          minWidth: minWidth,
          overflowX: 'auto',
          maxWidth: '100%'
        }}
        scrollable
      >
        <TableHead
          style={{
            display: 'block',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'white'
          }}
        >
          <TableRow style={{ display: 'flex', width: '100%' }}>
            {colsData.map((column, index) => (
              <StyledTableCell
                key={column.id.toString() + index.toString()}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: `${columnWidths[index]}px`,
                  textAlign: column.align // Asegurar textAlign en el header
                }}
                sortDirection={
                  sorteable && orderBy === column.id ? order : false
                }
                className={combinedClasses.headerCell}
              >
                <TableSortLabel
                  // column.hideSortIcon
                  className={
                    sorteable && column.sorteable !== false
                      ? ''
                      : combinedClasses.hideSortIcon
                  }
                  active={orderBy === column.id && sorteable}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={() => {
                    if (!sorteable) return
                    const newOrder =
                      orderBy === column.id && order === 'asc' ? 'desc' : 'asc'
                    setOrder(newOrder)
                    setOrderBy(column.id)
                  }}
                >
                  {column.label}
                </TableSortLabel>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            display: 'block',
            height: '100%',
            position: 'relative',
            overflowX: 'auto'
          }}
        >
          {data.map((row, index) => (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={index}
              onClick={onRowClick && (() => onRowClick(row))}
              className={combinedClasses.tableRow}
            >
              {colsData.map((column, colIndex) => {
                const value = row[column.id]
                let displayValue = column.render
                  ? column.render(row)
                  : column.format
                  ? column.format(value)
                  : value
                const tooltipValue = column.tooltipValue
                  ? column.tooltipValue(row)
                  : null
                const numericColumns = ['fee', 'price', 'quantity', 'valuation']
                const isNumeric =
                  typeof value === 'number' ||
                  numericColumns.includes(column.id)

                return (
                  <StyledTableCell
                    key={`${column.id.toString()}${index.toString()}`}
                    align={column.align}
                    className={clsx(combinedClasses.tableCell, {
                      [combinedClasses.fontBold]: column.bold,
                      clickable: onRowClick
                    })}
                    style={{
                      minWidth: column.minWidth,
                      width: `${columnWidths[colIndex]}px`,
                      padding: cellPadding,
                      textAlign: column.align // Asegurar textAlign en las celdas del body
                    }}
                  >
                    {column.id === 'check' ? (
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => {
                          row[column.id] = !value // Actualizar el estado checked
                        }}
                      />
                    ) : isNumeric && !tooltipValue ? (
                      <span className="cellContent">{displayValue}</span>
                    ) : tooltipValue ? (
                      <Tooltip
                        title={tooltipValue || displayValue}
                        placement="top"
                      >
                        <span
                          className="cellContent"
                          style={
                            column.ellipsis
                              ? {
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: 'block'
                                }
                              : {}
                          }
                        >
                          {displayValue}
                        </span>
                      </Tooltip>
                    ) : (
                      <span
                        className="cellContent"
                        style={
                          column.ellipsis
                            ? {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'block'
                              }
                            : {}
                        }
                      >
                        {displayValue}
                      </span>
                    )}
                  </StyledTableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )

  return (
    <Auxiliary>
      {isFullscreen ? (
        <>
          <Backdrop open={isFullscreen} />
          <Modal open={isFullscreen} className={combinedClasses.modal}>
            <div className={combinedClasses.fullscreenContainer}>
              {header}
              {tableContent}
            </div>
          </Modal>
        </>
      ) : (
        <>
          {header}
          {tableContent}
        </>
      )}
    </Auxiliary>
  )
}
