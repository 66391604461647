import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import {
  Clear as ClearIcon,
  Done as DoneIcon,
  Warning as WarningIcon
} from '@material-ui/icons'
import { StyledTableCell, StyledTableContainer } from 'assets/jss/Tables/Tables'
import {
  DataSourceHealthRequest,
  DataSourceLogsRequest,
  USDAndUFHealthRequest
} from 'axios/requests/operations'
import { useEffect, useState } from 'react'
import Loading from 'utils/UI/Loading'
import { errorNotification } from 'utils/UI/Notifications/Notifications'
import DefaultTable from 'utils/UI/Tables/DefaultTable'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'
import { formatToCLP2decimals } from 'utils/formatters/currencyFormatters'

const useStyles = makeStyles((theme) => ({
  dataSource: {
    '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    }
  },
  cell: {
    '&:hover $dataSource': {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    }
  }
}))

const commonCellStyle = { minWidth: 250, textAlign: 'left' }

const colsData = [
  { id: 'data_source', label: 'Fuente' },
  {
    id: 'quotes_count',
    label: 'Errores Precios',
    align: 'left'
  },
  {
    id: 'capital_count',
    label: 'Errores Eventos de Capital',
    align: 'left'
  },
  { id: 'status', label: 'Estado', render: ({ status }) => STATUSES[status] }
]

const currencyColsData = [
  { id: 'currency', label: 'Moneda', style: commonCellStyle },
  { id: 'price', label: 'Precio', style: commonCellStyle },
  { id: 'date', label: 'Ultima Fecha no duplicada', style: commonCellStyle },
  {
    id: 'spacer',
    label: '',
    style: { ...commonCellStyle, visibility: 'hidden' }
  }
]

const ERROR_QUOTES = 1 << 0 // 1
const ERROR_DIVIDENDS = 1 << 1 // 2
const ERROR_SPLITS = 1 << 2 // 4

const subtractOneDay = () => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return date.toISOString().split('T')[0]
}

const STATUSES = {
  green: <DoneIcon style={{ color: 'green' }} />,
  yellow: <WarningIcon style={{ color: 'gold' }} />,
  red: <ClearIcon style={{ color: 'red' }} />
}

export default function DataSourceHealthTable() {
  const classes = useStyles()
  const [rowsData, setRowsData] = useState(null)
  const [currencyData, setCurrencyData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [loading, setLoading] = useState(false)
  const selectedDate = subtractOneDay()

  const fetchData = async (requestFunc, setDataFunc, parseDataFunc) => {
    let didCancel = false
    try {
      const result = await requestFunc()
      if (!didCancel) {
        const parsedData = parseDataFunc(result.data)
        setDataFunc(parsedData)
      }
    } catch (err) {
      errorNotification('generalError')
    }
    return () => {
      didCancel = true
    }
  }

  const handleRowClick = (dataSource) => {
    setSelectedData(null)
    setModalOpen(true)
    setLoading(true)
    const parseLogsData = (data) =>
      data.map((item) => ({
        ticker: item.ticker,
        error_state: item.error_state, // Include error_state in the modal data
        holdings_count: item.holdings_count
      }))
    fetchData(
      () => DataSourceLogsRequest({ source: dataSource.data_source }),
      setSelectedData,
      parseLogsData
    ).finally(() => setLoading(false))
  }

  useEffect(() => {
    const parseRowsData = (data) =>
      data.map((item) => ({
        data_source: item.data_source,
        total_count: item.total_count,
        quotes_count: item.quotes_count,
        capital_count: item.capital_count,
        status: item.health_status
      }))
    fetchData(DataSourceHealthRequest, setRowsData, parseRowsData)
  }, [])

  useEffect(() => {
    const parseCurrencyData = (data) => {
      const usdData = data.usd_status
        ? {
            currency: 'USD',
            date: data.usd_status.date,
            price: formatToCLP2decimals(data.usd_status.price)
          }
        : null
      const ufData = data.uf_status
        ? {
            currency: 'UF',
            date: data.uf_status.date,
            price: formatToCLP2decimals(data.uf_status.price)
          }
        : null
      return [usdData, ufData].filter(Boolean)
    }
    fetchData(USDAndUFHealthRequest, setCurrencyData, parseCurrencyData)
  }, [])

  return (
    <CardWrapper
      title="Data Source Health Status"
      titleFeedback="Data Source Health Status"
    >
      {rowsData ? (
        <DefaultTable
          colsData={colsData}
          rowsData={rowsData}
          onRowClick={handleRowClick}
          classes={classes}
          maxHeight={false}
        />
      ) : (
        <Loading />
      )}
      {/* New Table for currencyData */}
      {currencyData.length > 0 && (
        <DefaultTable
          colsData={currencyColsData}
          rowsData={currencyData}
          sorteable={false}
        />
      )}
      {/* Modal for additional data */}
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} fullWidth>
        <DialogTitle>Assets con error - {selectedDate}</DialogTitle>
        <DialogContent>
          {loading ? (
            <Loading />
          ) : selectedData && selectedData.length > 0 ? (
            <StyledTableContainer component={Paper}>
              <Table aria-label="detail table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ textAlign: 'left' }}>
                      Ticker
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      Quotes
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      Dividends
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      Splits
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      N°Cuentas
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedData.map((item, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <StyledTableCell style={{ textAlign: 'left' }}>
                        {item.ticker}
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'center' }}>
                        {item.error_state & ERROR_QUOTES ? (
                          <ClearIcon style={{ color: 'red' }} />
                        ) : (
                          <DoneIcon style={{ color: 'green' }} />
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'center' }}>
                        {item.error_state & ERROR_DIVIDENDS ? (
                          <ClearIcon style={{ color: 'red' }} />
                        ) : (
                          <DoneIcon style={{ color: 'green' }} />
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'center' }}>
                        {item.error_state & ERROR_SPLITS ? (
                          <ClearIcon style={{ color: 'red' }} />
                        ) : (
                          <DoneIcon style={{ color: 'green' }} />
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'center' }}>
                        {item.holdings_count}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          ) : (
            <div>No data available</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </CardWrapper>
  )
}
