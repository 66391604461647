import { useContext, useEffect, useState } from 'react'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import HeaderContext from 'context/headerContext'
import Loading from 'utils/UI/Loading'
import PaginatedTable from 'utils/UI/Tables/PaginatedTable'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { ContractsRequest } from 'axios/requests/documents'

export default function DividendsTable() {
  const { t } = useTranslation(['dashboard', 'common'])
  const colsData = [
    {
      id: 'date',
      label: t('Fecha')
    },
    {
      id: 'description',
      label: t('Descripción')
    },
    {
      id: 'url',
      label: t('Link de Descarga'),
      format: (value) => {
        return (
          <a href={value} download>
            {t('Descargar')}
          </a>
        )
      }
    }
  ]
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [loading, setLoading] = useState(false)
  const [rowsData, setRowsData] = useState()

  useEffect(() => {
    setLoading(true)
    let mounted = true
    const fetchData = async () => {
      try {
        const result = await ContractsRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
          portfolio_id: currentPortfolio.id
        })
        return result.data
      } catch (err) {
        console.log(err)
      }
    }

    if (mounted && currentPortfolio && currency) {
      fetchData().then((data) => {
        if (mounted && data?.tableData) {
          setRowsData(data.tableData)
          setLoading(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [currency, currentPortfolio, startDate, endDate])

  return (
    <CardWrapper title={t('Contratos')}>
      {rowsData && !loading ? (
        <PaginatedTable colsData={colsData} rowsData={rowsData} />
      ) : (
        <Loading />
      )}
    </CardWrapper>
  )
}
