export const numberFormatter = (value) => {
  return parseFloat(value).toLocaleString('de-DE', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  })
}

const lan = 'es'

export const decimalFormatter = (value) => {
  if (!value) {
    return '-'
  } else if (lan === 'es') {
    return parseFloat(value).toLocaleString('de-DE', {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1
    })
  } else {
    return parseFloat(value).toLocaleString('en-US', {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1
    })
  }
}

export const fourdecimalFormatter = (value) => {
  if (!value) {
    return '-'
  } else if (lan === 'es') {
    return parseFloat(value).toLocaleString('de-DE', {
      maximumFractionDigits: 4,
      minimumFractionDigits: 4
    })
  } else {
    return parseFloat(value).toLocaleString('en-US', {
      maximumFractionDigits: 4,
      minimumFractionDigits: 4
    })
  }
}
