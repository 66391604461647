import {
  Box,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import GetAppIcon from '@material-ui/icons/GetApp'
import SaveIcon from '@material-ui/icons/Save'
import { format, parseISO } from 'date-fns'
import { useState } from 'react'
import { formatToCLP, formatToUSD } from 'utils/formatters/currencyFormatters'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {},
  dateCellStyle: {
    fontWeight: 'bold',
    borderRight: '1px solid #ccc',
    padding: '8px',
    textAlign: 'left',
    minWidth: '6vw',
    whiteSpace: 'nowrap'
  },
  quantityCellStyle: {
    textAlign: 'right',
    padding: '0 10px',
    flex: '1 1 auto'
  },
  adjustedQuantityCellStyle: {
    textAlign: 'right',
    padding: '0 10px',
    flex: '1 1 auto',
    color: theme.palette.primary.main
  },
  headerCellStyle: {
    border: 'transparent',
    fontFamily: 'InconsolataSemiBold',
    fontSize: 16,
    paddingRight: 5,
    paddingLeft: 5,
    paddingBottom: 8,
    borderBottom: '1px solid' + theme.palette.secondary.main,
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    textAlign: 'right'
  },
  dateHeaderCellStyle: {
    border: 'transparent',
    fontFamily: 'InconsolataSemiBold',
    fontSize: 16,
    paddingRight: 5,
    paddingLeft: 5,
    paddingBottom: 8,
    borderBottom: '1px solid' + theme.palette.secondary.main,
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    textAlign: 'left'
  },
  accountHeaderCellStyle: {
    fontFamily: 'InconsolataSemiBold',
    fontSize: 16,
    paddingBottom: 8,
    whiteSpace: 'nowrap',
    textAlign: 'center'
  },
  noBorder: {
    border: 'none'
  },
  '@global': {
    'input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    'input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  Container: {
    [theme.breakpoints.up('lg')]: {
      maxHeight: 640
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: 460
    }
  },
  ContainerFullscreen: {
    maxHeight: '100vh'
  },
  stickyTableCell: {
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: theme.palette.background.paper
  },
  currencyColumn: {
    textAlign: 'right',
    cursor: 'pointer',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    width: (props) => props.columnWidth || 'auto'
  }
}))

export function CashHoldingsTable({
  cashHoldingsData,
  handleRealQuantityChange,
  handleSave,
  handleDownload,
  isSaving,
  isDownloading,
  onToggleFullscreen = () => {},
  isFullscreen = false
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [editingValue, setEditingValue] = useState('')
  const [currentEdit, setCurrentEdit] = useState({
    dateIndex: null,
    accIndex: null
  })

  const currencyPairCount =
    new Set(cashHoldingsData.map((data) => data.account_code)).size * 2
  const columnWidth = `${94 / currencyPairCount}%`

  const classes = useStyles({ columnWidth })
  const dates = cashHoldingsData[0].dates
  const accountCodeCurrencies = cashHoldingsData.map(
    (data) => `${data.account_code}  [${data.cash_currency}]`
  )
  const accounts = [
    ...new Set(cashHoldingsData.map((data) => data.account_code))
  ]

  const fullscreenTable = (
    <>
      {isFullscreen ? (
        <Grid item xs={false}>
          <Tooltip title="Salir pantalla completa">
            <IconButton
              onClick={onToggleFullscreen}
              color="primary"
              padding={0}
            >
              <FullscreenExitIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item xs={false}>
          <Tooltip title="Pantalla completa">
            <IconButton
              onClick={onToggleFullscreen}
              color="primary"
              padding={0}
            >
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </>
  )

  const handleClick = (event, dateIndex, accIndex, currentValue) => {
    setAnchorEl(event.currentTarget)
    setCurrentEdit({ dateIndex, accIndex })
    setEditingValue(currentValue)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (event) => {
    setEditingValue(event.target.value)
  }

  const handleSaveEdit = () => {
    const { dateIndex, accIndex } = currentEdit
    handleRealQuantityChange(
      dateIndex,
      accIndex,
      editingValue === '' ? null : Number(editingValue)
    )
    handleClose()
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
        style={{ marginBottom: 8 }}
      >
        <Grid item>
          <Tooltip title={'Guardar Holding Adjustments'}>
            {isSaving ? (
              <CircularProgress />
            ) : (
              <IconButton
                variant="contained"
                onClick={handleSave}
                color="primary"
              >
                <SaveIcon />
              </IconButton>
            )}
          </Tooltip>
        </Grid>
        <Grid item xs={false}>
          <Tooltip title={'Descargar'}>
            {isDownloading ? (
              <CircularProgress />
            ) : (
              <IconButton onClick={handleDownload} color="primary" padding={0}>
                <GetAppIcon />
              </IconButton>
            )}
          </Tooltip>
        </Grid>
        {fullscreenTable}
      </Grid>
      <TableContainer
        className={
          isFullscreen ? classes.ContainerFullscreen : classes.Container
        }
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.accountHeaderCellStyle} />
              {accounts.map((account, index) => {
                const accountCurrencies = cashHoldingsData
                  .filter((data) => data.account_code === account)
                  .map((data) => data.cash_currency)
                return (
                  <TableCell
                    key={index}
                    className={classes.accountHeaderCellStyle}
                    colSpan={accountCurrencies.length}
                  >
                    {account}
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <TableCell className={classes.dateHeaderCellStyle}>
                Fecha
              </TableCell>
              {cashHoldingsData.map((data, index) => (
                <TableCell
                  key={index}
                  className={`${classes.headerCellStyle} ${classes.currencyColumn}`}
                >
                  {data.cash_currency}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dates.map((date, dateIndex) => (
              <TableRow key={dateIndex}>
                <TableCell className={classes.dateCellStyle}>
                  {format(parseISO(date), 'dd/MM/yyyy')}
                </TableCell>
                {accountCodeCurrencies.map((accCodeCurrency, accIndex) => {
                  const data = cashHoldingsData.find(
                    (d) =>
                      `${d.account_code}  [${d.cash_currency}]` ===
                      accCodeCurrency
                  )

                  const quantity = data.quantity[dateIndex]
                  const realQuantity = data.real_quantity[dateIndex]

                  let formattedQuantity = quantity

                  if (data.cash_currency === 'CLP') {
                    formattedQuantity = formatToCLP(quantity)
                  } else {
                    formattedQuantity = formatToUSD(quantity)
                  }

                  const displayQuantity = realQuantity
                    ? `${
                        data.cash_currency === 'CLP'
                          ? formatToCLP(realQuantity)
                          : formatToUSD(realQuantity)
                      }`
                    : `${
                        data.cash_currency === 'CLP'
                          ? formatToCLP(quantity)
                          : formatToUSD(quantity)
                      }`

                  return (
                    <TableCell
                      key={accIndex}
                      className={
                        realQuantity
                          ? `${classes.adjustedQuantityCellStyle} ${classes.currencyColumn}`
                          : `${classes.quantityCellStyle} ${classes.currencyColumn}`
                      }
                      onClick={(e) =>
                        handleClick(
                          e,
                          dateIndex,
                          accIndex,
                          realQuantity || quantity
                        )
                      }
                    >
                      <Tooltip
                        title={
                          realQuantity
                            ? `Original: ${formattedQuantity}`
                            : 'Presiona para ajustar'
                        }
                        placement="top-end"
                      >
                        <Typography>{displayQuantity}</Typography>
                      </Tooltip>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box style={{ padding: 15 }} display="flex" alignItems="center">
          <TextField
            type="number"
            value={editingValue}
            onChange={handleChange}
            variant="outlined"
            size="small"
            helperText="Presiona Enter para guardar"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSaveEdit()
              }
            }}
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' }
              }
            }}
          />
        </Box>
      </Popover>
    </div>
  )
}
