import { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const useStyles = makeStyles(() => ({
  filterItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 26,
    marginBotton: 5
  },
  paper: {
    overflow: 'unset'
  },
  IconStyle: {
    padding: 0,
    marginBotton: 5
  }
}))

export default function PopoverMobile({
  selectedPortfolio,
  renderOptionsCard,
  vertical
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles({ vertical })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const opena = Boolean(anchorEl)
  const id = opena ? 'simple-popover' : undefined

  return (
    <Hidden only={['lg', 'md', 'xl']}>
      <Grid item>
        <div className={classes.filterItem}>{selectedPortfolio.name}</div>
      </Grid>
      <IconButton onClick={handleClick} className={classes.IconStyle}>
        <ArrowDropDownIcon />
      </IconButton>
      <div>
        <Popover
          id={id}
          open={opena}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          classes={{
            paper: classes.paper
          }}
        >
          <Grid item>{renderOptionsCard}</Grid>
        </Popover>
      </div>
    </Hidden>
  )
}
