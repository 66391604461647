import axios from 'axios'
import backendURL from '../config'
import i18n from '../i18n'

const instance = axios.create({
  baseURL: backendURL,
  timeout: 60 * 2 * 1000 // 2 minutos
})

instance.interceptors.request.use((config) => {
  const currentLanguage = i18n.language
  config.headers['Accept-Language'] = currentLanguage
  return config
})

export default instance
