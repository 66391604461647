import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '10px' // Adjust the margin as needed
  },
  dot: {
    width: '16px',
    height: '16px',
    backgroundColor: 'grey',
    borderRadius: '50%',
    marginRight: '8px',
    animation: '$blink 2s infinite' // animation speed
  },
  '@keyframes blink': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}))

const BotResponseLoader = () => {
  const classes = useStyles()

  return (
    <div className={classes.loader}>
      <div className={classes.dot}></div>
    </div>
  )
}

export default BotResponseLoader
