import { useEffect, useState } from 'react'

import { successNotification } from 'utils/UI/Notifications/Notifications'

import { refreshAccessTokenRequest } from 'axios/requests/auth'

const createTokenProvider = () => {
  let _token =
    JSON.parse(window.localStorage.getItem('REACT_TOKEN_AUTH')) || null
  const _adminToken =
    JSON.parse(window.localStorage.getItem('HIJACK_ADMIN_TOKENS')) || null
  let _user_details =
    JSON.parse(window.localStorage.getItem('REACT_USER_DETAILS')) || null
  let observers = []

  const isLoggedIn = () => {
    return {
      isLogged: !!_token,
      userDetails: _user_details
    }
  }

  const getExpirationDate = (jwtToken) => {
    if (!jwtToken) {
      return null
    }
    const jwt = JSON.parse(window.atob(jwtToken.split('.')[1]))

    // multiply by 1000 to convert seconds into milliseconds
    return (jwt && jwt.exp && jwt.exp * 1000) || null
  }

  const isExpired = (exp) => {
    if (!exp) {
      return false
    }

    const expiryDate = new Date(exp)

    return Date.now() > expiryDate
  }

  const notify = () => {
    const isLogged = isLoggedIn()
    observers.forEach((observer) => {
      observer(isLogged)
    })
  }

  const subscribe = (observer) => {
    observers.push(observer)
  }

  const unsubscribe = (observer) => {
    observers = observers.filter((_observer) => _observer !== observer)
  }

  const setLocalStorageVariables = (token) => {
    /* get the access token */
    const accessToken = JSON.parse(window.atob(token.access.split('.')[1]))

    /* Set the tokens and the User details in the local storage */
    window.localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(token))
    window.localStorage.setItem(
      'REACT_USER_DETAILS',
      JSON.stringify({
        firstName: accessToken.name,
        isStaff: accessToken.is_staff,
        role: accessToken.role
      })
    )
  }

  const removeLocalStorageVariables = () => {
    window.localStorage.removeItem('REACT_TOKEN_AUTH')
    window.localStorage.removeItem('REACT_USER_DETAILS')
    window.localStorage.removeItem('USER_FLAGS')
  }

  const setToken = (token) => {
    if (token) {
      setLocalStorageVariables(token)
    } else {
      removeLocalStorageVariables()
    }
    _token = token
    _user_details = JSON.parse(
      window.localStorage.getItem('REACT_USER_DETAILS')
    )
    notify()
  }

  const getToken = async () => {
    if (!_token) {
      return null
    }

    if (isExpired(getExpirationDate(_token.access))) {
      try {
        const updatedToken = await refreshAccessTokenRequest(_token.refresh)
        _token.access = updatedToken.data.access
        setToken(_token)
      } catch (err) {
        if (_adminToken) {
          setLocalStorageVariables(_adminToken)
          window.localStorage.removeItem('HIJACK_ADMIN_TOKENS')
        } else {
          removeLocalStorageVariables()
        }
        _token = null
        notify()
      }
    }
    return _token
  }

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe
  }
}

const createAuthProvider = () => {
  const tokenProvider = createTokenProvider()

  const login = (newTokens) => {
    tokenProvider.setToken(newTokens)
  }

  const hijackLogin = (newTokens) => {
    // We save the admin tokens into a new window local Storage
    window.localStorage.setItem(
      'HIJACK_ADMIN_TOKENS',
      window.localStorage.getItem('REACT_TOKEN_AUTH')
    )

    // Login with the hijacked user
    tokenProvider.setToken(newTokens)

    successNotification('hijackLogin')
  }

  const hijackLogout = () => {
    // We retrieve the admin tokens
    const adminTokens = JSON.parse(
      window.localStorage.getItem('HIJACK_ADMIN_TOKENS')
    )

    // Login back with the admin user
    tokenProvider.setToken(adminTokens)

    // Remove the local storage admin tokens
    window.localStorage.removeItem('HIJACK_ADMIN_TOKENS')
    successNotification('hijackLogout')
  }

  const logout = () => {
    tokenProvider.setToken(null)
  }

  const retrieveToken = async () => {
    const token = await tokenProvider.getToken()
    return token
  }

  const useAuth = () => {
    const [authData, setAuthData] = useState(tokenProvider.isLoggedIn())

    useEffect(() => {
      const listener = (newAuthData) => {
        setAuthData(newAuthData)
      }
      tokenProvider.subscribe(listener)
      return () => {
        tokenProvider.unsubscribe(listener)
      }
    }, [])

    return {
      isUserAuthenticated: authData.isLogged,
      userDetails: authData.userDetails
    }
  }

  return {
    useAuth,
    retrieveToken,
    login,
    logout,
    hijackLogin,
    hijackLogout
  }
}
export const {
  useAuth,
  retrieveToken,
  login,
  logout,
  hijackLogin,
  hijackLogout
} = createAuthProvider()
