import { useState } from 'react'

import AssetsSeriesReturns from 'components/Graphs/AssetsSeriesReturns'
import InstrumentReturnsTable from 'components/Graphs/instrumentReturnsTable'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

export default function AssetsComparator() {
  const [data, setData] = useState()
  const handleData = (value) => {
    setData(value)
  }

  return (
    <Auxiliary>
      <AssetsSeriesReturns handleData={handleData} />
      <InstrumentReturnsTable data={data} />
    </Auxiliary>
  )
}
