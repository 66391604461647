import MonthlyReturns from 'components/Graphs/MonthlyReturns'
import TreeTable from 'components/Graphs/TreeTable'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

export default function Portfolio() {
  return (
    <Auxiliary>
      <TreeTable />
      <MonthlyReturns />
    </Auxiliary>
  )
}
