import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#F1AD3D',
      light: '#F8D7A3',
      dark: '#B58A1F'
    },
    secondary: {
      main: '#DDD9CA',
      light: '#d3cec9'
    },
    text: {
      primary: 'rgb(42,42,42,0.75)'
    },
    success: {
      main: '#507255',
      light: '#4F9001'
    },
    error: {
      main: '#9e2b25',
      light: '#DA3322'
    },
    info: {
      main: '#263b53',
      dark: '#0f1f2b'
    },
    orange: {
      lighten1: '#F1AD3D',
      lighten2: '#F9D6A3',
      lighten3: '#F9EBD9',
      darken1: '#FD8E01'
    },
    beige: {
      lighten1: '#DDD9CA',
      lighten2: '#CEC3A0',
      darken1: '#C1AE74',
      darken2: '#A28731'
    },
    green: {
      lighten1: '#507255',
      lighten2: '#649E6C',
      lighten3: '#55bf64',
      darken1: '#33d249',
      darken2: '#CEECD3'
    },
    gray: {
      lighten1: '#676767',
      lighten2: '#A2A4A2',
      darken1: '#E4E4E4',
      darken2: '#353535'
    },
    olive: {
      lighten1: '#4F9001',
      darken1: '#325E01',
      darken2: '#637F45'
    }
  },
  typography: {
    fontFamily: 'InconsolataRegular',
    h1: {
      fontSize: 24,
      fontWeight: 400,
      color: 'rgb(42,42,42,0.75)',
      fontFamily: 'InconsolataSemiBold'
    },
    h2: {
      fontSize: 20,
      fontWeight: 400,
      color: 'rgb(42,42,42,0.75)',
      fontFamily: 'InconsolataSemiBold'
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    button: {
      color: '#516282'
    }
  }
})

export const COLORS_MULTI_SELECT = [
  theme.palette.info.main,
  theme.palette.secondary.main,
  theme.palette.success.main,
  theme.palette.text.primary,
  theme.palette.success.light
]

export const COLORS_GRAPH_ASSETS = [
  theme.palette.orange.lighten1,
  theme.palette.beige.lighten1,
  theme.palette.green.lighten1,
  theme.palette.gray.lighten1,
  theme.palette.olive.lighten1,
  theme.palette.orange.lighten2,
  theme.palette.beige.lighten2,
  theme.palette.green.lighten2,
  theme.palette.gray.lighten2,
  theme.palette.olive.darken1,
  theme.palette.orange.lighten3,
  theme.palette.beige.darken1,
  theme.palette.green.lighten3,
  theme.palette.gray.darken1,
  theme.palette.olive.darken2,
  theme.palette.orange.darken1,
  theme.palette.beige.darken2,
  theme.palette.green.darken1,
  theme.palette.gray.darken2,
  theme.palette.green.darken2
]

// change text-color determined by the background color
export const getTextColor = (color) => {
  const colorValue = color.replace('#', '')
  const r = parseInt(colorValue.substr(0, 2), 16)
  const g = parseInt(colorValue.substr(2, 2), 16)
  const b = parseInt(colorValue.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? '#000000' : '#ffffff'
}
