import React from 'react'

export const SidebarContext = React.createContext('sidebar')

export default function SidebarContextWrapper({ children }) {
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [openSettingsDrawer, setOpenSettingsDrawer] = React.useState(false)

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleOpenSettingsDrawer = () => {
    setOpenSettingsDrawer(!openSettingsDrawer)
  }

  return (
    <SidebarContext.Provider
      value={{
        handleOpenDrawer,
        openDrawer,
        handleOpenSettingsDrawer,
        openSettingsDrawer
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}
