import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback) => {
    const storedLang = localStorage.getItem('i18nextLng')
    const userLang = storedLang || 'es'
    callback(userLang)
  },
  init: () => {},
  cacheUserLanguage: (language) => {
    localStorage.setItem('i18nextLng', language)
  }
}

i18n
  .use(Backend) // Load translation using http
  .use(languageDetector) // Use custom language detector
  .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    fallbackLng: 'es',
    // debug: true,
    interpolation: {
      escapeValue: false // Not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json' // Path to your translation files
    },
    ns: ['auth', 'common', 'dashboard', 'asset_classes', 'sidebar'], // Specify the namespaces you are using
    defaultNS: 'common' // Default namespace
  })

export default i18n
