import { useCallback, useEffect, useMemo, useState } from 'react'

import { Input, Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import { rankItem } from '@tanstack/match-sorter-utils'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  StyledTanstackCell,
  StyledTanstackContainer
} from 'assets/jss/Tables/TanstackTables'
import 'components/DatePicker/overrideDatePicker.css'
import Search from 'utils/UI/Inputs/Search'
import TanstackFilter from 'utils/UI/Inputs/TanstackFilter'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import SaveIcon from '@material-ui/icons/Save'
import { formatToCLP, formatToUSD } from 'utils/formatters/currencyFormatters'
import { fourdecimalFormatter } from 'utils/formatters/numberFormatters'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  hideSortIcon: {
    '& .MuiTableSortLabel-icon': {
      display: 'none',
      visibility: 'hidden'
    }
  },
  tableContainer: {
    [theme.breakpoints.up('lg')]: {
      maxHeight: 640
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: 460
    }
  },
  tableContainerFullscreen: {
    maxHeight: '82vh'
  }
}))

const defaultColumn = {
  cell: ({ getValue, row: { index }, column: { id }, table }) => {
    const initialValue = getValue() ?? ''
    const [value, setValue] = useState(initialValue)

    const onBlur = () => {
      if (value !== initialValue) {
        table.options.meta?.updateData(index, id, value)
      }
    }

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    // Determine the alignment
    const columnAlignment = getAlignment(id)

    // Format value if it's numeric and not focused (not editable)
    let displayValue = isNumeric(initialValue)
      ? formatNumber(initialValue, id)
      : initialValue

    // make only editable the Ajusted Quantity Column
    const isReadOnly = id !== 'adjusted_quantity'

    return (
      <Input
        value={isReadOnly ? displayValue : value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        fullWidth
        disableUnderline={isReadOnly}
        inputProps={{
          readOnly: isReadOnly,
          style: { textAlign: columnAlignment }
        }}
        placeholder="-"
      />
    )
  }
}

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

function dateFromString(str) {
  const [day, month, year] = str.split('-').map((part) => parseInt(part, 10))
  // Month is zero-indexed, that's why we need `month - 1`.
  return new Date(year, month - 1, day)
}

function dateRangeFilterFn(row, columnId, filterValue) {
  let [startDateString, endDateString] = filterValue || []
  let startDate = startDateString ? dateFromString(startDateString) : null
  let endDate = endDateString ? dateFromString(endDateString) : null
  // Check for invalid dates
  if (
    (startDateString && isNaN(startDate?.getTime())) ||
    (endDateString && isNaN(endDate?.getTime()))
  ) {
    console.error('Invalid date format. Dates should be in dd-mm-yyyy format.')
    return false
  }

  // If no dates are set in the filter, don't filter anything
  if (!filterValue || (!startDate && !endDate)) {
    return true
  }
  const rowValue = row.getValue(columnId)
  const rowDate = dateFromString(rowValue)
  // If only one date is specified, act as greater/less than filter
  if (startDate && !endDate) {
    return rowDate >= startDate
  }
  if (!startDate && endDate) {
    return rowDate <= endDate
  }
  // If both dates are specified, check if the row date is within the range
  return rowDate >= startDate && rowDate <= endDate
}

const numericFilterFn = (row, columnId, filterValue) => {
  const rowValue = row.getValue(columnId)
  const rowNumber = parseFloat(rowValue)

  // Function to check if a value is effectively not set (undefined, empty, or non-numeric)
  const isNotSet = (value) => {
    return value === undefined || value === '' || isNaN(parseFloat(value))
  }

  // Extract min and max values, treating undefined and empty strings as no filter
  const [min, max] = filterValue.map((val) =>
    isNotSet(val) ? undefined : parseFloat(val)
  )

  // If both min and max are not set, include all rows
  if (isNotSet(min) && isNotSet(max)) return true

  // If the rowValue is not a valid number, exclude it
  if (isNaN(rowNumber)) return false

  // Check if rowNumber falls within the specified range
  return (
    (isNotSet(min) || rowNumber >= min) && (isNotSet(max) || rowNumber <= max)
  )
}

const getAlignment = (columnId) => {
  if (columnId.toLowerCase().includes('date')) {
    return 'left' // Dates usually aligned to the left
  } else if (
    columnId.toLowerCase().includes('price') ||
    columnId.toLowerCase().includes('quantity') ||
    columnId.toLowerCase().includes('value')
  ) {
    return 'right' // Numbers often aligned to the right
  }
  return 'center' // Default for text and other data types
}

const formatNumber = (value, columnId) => {
  if (columnId.toLowerCase().includes('clp')) {
    return formatToCLP(value)
  } else if (columnId.toLowerCase().includes('usd')) {
    return formatToUSD(value)
  } else {
    return fourdecimalFormatter(value)
  }
}

const isNumeric = (str) => {
  if (typeof str !== 'string') return false

  // Replace commas with empty strings and then check for numeric validity
  const sanitizedStr = str.replace(/,/g, '')
  return !isNaN(sanitizedStr) && !isNaN(parseFloat(sanitizedStr))
}

export default function TanstackTable({
  colsData,
  rowsData,
  onSave = () => {},
  showSaveButton = false,
  loadingSave = false,
  showFullscreenButton = false,
  onTogleFullscreen = () => {},
  isFullscreen = false,
  filterExcludedColumns = [],
  showDateOnTopCorner = false
}) {
  const classes = useStyles()
  const [data, setData] = useState(rowsData)
  const [sorting, setSorting] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState([])

  const updateData = useCallback((rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          }
        }
        return row
      })
    )
  }, [])

  const handleSave = () => {
    onSave(data)
  }

  const columnHelper = createColumnHelper()

  const columns = useMemo(() => {
    return colsData.map((col) => {
      let column = columnHelper.accessor(col.id, {
        header: () => <div>{col.label}</div>,
        enableFiltering: !filterExcludedColumns.includes(col.id.toLowerCase())
      })
      if (!column.enableFiltering) {
        column = {
          ...column,
          enableColumnFilter: false
        }
      }

      // set columns sizes
      if (col.id === 'account') {
        column = {
          ...column,
          minSize: 300
        }
      }
      if (col.id === 'asset') {
        column = {
          ...column,
          minSize: 580
        }
      }

      if (col.id === 'ticker') {
        column = {
          ...column,
          minSize: 240
        }
      }

      if (col.id === 'currency' || col.id === 'data_source') {
        column = {
          ...column,
          maxSize: 120
        }
      }

      return column
    })
  }, [colsData, columnHelper])

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    filterFns: {
      fuzzy: fuzzyFilter,
      dateRangeFilter: dateRangeFilterFn,
      numericFilter: numericFilterFn
    },
    meta: {
      updateData
    },
    state: {
      sorting,
      globalFilter,
      columnFilters
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false
  })

  const fullscreenIcons = (
    <>
      {showDateOnTopCorner && (
        <Grid item>
          <Typography variant="h6">
            {rowsData?.length ? rowsData[0].date : ''}
          </Typography>
        </Grid>
      )}
      {isFullscreen ? (
        <Grid item>
          <Tooltip title="Salir pantalla completa">
            <IconButton onClick={onTogleFullscreen} color="primary">
              <FullscreenExitIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item>
          <Tooltip title="Pantalla completa">
            <IconButton onClick={onTogleFullscreen} color="primary">
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </>
  )

  return (
    <CardWrapper>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item xs={true} style={{ display: 'flex', alignItems: 'center' }}>
          <Search
            onChange={(value) => setGlobalFilter(String(value))}
            value={globalFilter}
          />
          {showSaveButton && (
            <Tooltip title={rowsData?.length ? 'Guardar' : ''}>
              {loadingSave ? (
                <CircularProgress />
              ) : (
                <IconButton
                  disabled={!rowsData?.length}
                  onClick={handleSave}
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
              )}
            </Tooltip>
          )}
        </Grid>
        {showFullscreenButton && fullscreenIcons}
        <Grid item xs={12}>
          <StyledTanstackContainer
            className={
              isFullscreen
                ? classes.tableContainerFullscreen
                : classes.tableContainer
            }
          >
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const hideSortIcon = !header.column.getCanSort()
                      return (
                        <StyledTanstackCell
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                              }}
                            >
                              <div>
                                {' '}
                                {/* Wrapper for the sort label */}
                                <TableSortLabel
                                  className={
                                    hideSortIcon ? classes.hideSortIcon : ''
                                  }
                                  active={header.column.getIsSorted() !== false}
                                  direction={
                                    header.column.getIsSorted() === 'asc'
                                      ? 'asc'
                                      : 'desc'
                                  }
                                  onClick={header.column.getToggleSortingHandler()}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </TableSortLabel>
                              </div>
                              {header.column.getCanFilter() && (
                                <div>
                                  <TanstackFilter
                                    column={header.column}
                                    table={table}
                                    increaseSize={true}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </StyledTanstackCell>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow hover key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <StyledTanstackCell key={cell.column.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </StyledTanstackCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTanstackContainer>
        </Grid>
      </Grid>
    </CardWrapper>
  )
}
