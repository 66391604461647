import { useState } from 'react'

import { Redirect } from 'react-router-dom'

import {
  Button,
  Container,
  FormControl,
  Input,
  InputLabel,
  Paper
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AbaqusLogo from 'assets/images/abaqus-logo-black.svg'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'

import {
  passwordChangeRequest,
  passwordResetRequest
} from 'axios/requests/auth'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  InputWrapper: {
    width: '100%',
    margin: theme.spacing(1)
  },
  LoginPageAbaqusLogo: {
    width: '40%'
  },
  btnSubmit: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: '0.8em'
  }
}))

export default function ChangePasswordForm({ token }) {
  const classes = useStyles()
  const [password, setPassword] = useState('')
  const [reEnteredPassword, setReEnteredPassword] = useState('')
  const [redirect, setRedirect] = useState(false)

  const validatePasswords = () => {
    if (password === reEnteredPassword && password.length > 8) {
      return true
    } else {
      return false
    }
  }

  const handleResetSubmit = async (event) => {
    if (event) {
      event.preventDefault()
    }
    if (validatePasswords) {
      try {
        if (token) {
          await passwordResetRequest(password, token)
        } else {
          await passwordChangeRequest(password)
        }
        successNotification('passwordChange')
        setRedirect(true)
      } catch (err) {
        errorNotification('generalError')
      }
    }
  }

  return (
    <Container
      component={Paper}
      className={classes.formContainer}
      maxWidth={false}
    >
      {redirect && <Redirect exact to="/" />}
      <a href="/">
        <img
          src={AbaqusLogo}
          className={classes.LoginPageAbaqusLogo}
          alt="logo-abq"
        />
      </a>
      <form onSubmit={handleResetSubmit} className={classes.form}>
        <FormControl color="secondary" className={classes.InputWrapper}>
          <InputLabel htmlFor="email-input">Nueva contraseña</InputLabel>
          <Input
            id="new-password-input"
            type="password"
            value={password}
            autoFocus
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <FormControl color="secondary" className={classes.InputWrapper}>
          <InputLabel htmlFor="password-input">
            Escribe nuevamente tu nueva contraseña
          </InputLabel>
          <Input
            id="new-reentered-password-input"
            type="password"
            value={reEnteredPassword}
            onChange={(e) => setReEnteredPassword(e.target.value)}
          />
        </FormControl>
        <Button className={classes.btnSubmit} type="submit">
          Aceptar
        </Button>
      </form>
    </Container>
  )
}
