import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Input,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import { rankItem } from '@tanstack/match-sorter-utils'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { StyledTanstackCell } from 'assets/jss/Tables/TanstackTables'
import 'components/DatePicker/overrideDatePicker.css'
import AssetCompositionsCreateDialog from 'utils/UI/Dialogs/AssetCompositionsCreate'
import AssetCompositionsEditDialog from 'utils/UI/Dialogs/AssetCompositionsEdit.jsx'
import Search from 'utils/UI/Inputs/Search'
import TanstackFilter from 'utils/UI/Inputs/TanstackFilter'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Divider from '@mui/material/Divider'
import { useVirtualizer } from '@tanstack/react-virtual'
import {
  GetAssetCompositionRequest,
  UpdateAssetRequest
} from 'axios/requests/operations'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  hideSortIcon: {
    '& .MuiTableSortLabel-icon': {
      display: 'none',
      visibility: 'hidden'
    }
  },
  tableContainer: {
    [theme.breakpoints.up('lg')]: {
      maxHeight: 640
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: 520
    }
  },
  tableContainerFullscreen: {
    maxHeight: '82vh'
  }
}))

const defaultColumn = {
  cell: ({ getValue, row: { index }, column: { id }, table }) => {
    const tableMeta = table.options.meta
    const initialValue = getValue()
    const [value, setValue] = useState(initialValue)

    const updateCellData = () => {
      table.options.meta?.updateData(index, id, value)
    }

    useEffect(() => {
      if (initialValue !== value) {
        setValue(initialValue)
      }
    }, [initialValue])

    // Determine the alignment
    const columnAlignment = getAlignment(id)

    const hasPredefinedValues = isColumnWithPredefinedValues(id)
    let predefinedValues
    if (hasPredefinedValues) {
      predefinedValues = table.options.meta?.assetsFieldsDistinctValues[id].map(
        (value, index) => {
          if (index === 2 && id.includes('currency')) {
            return [
              <MenuItem key={index} value={value}>
                {' '}
                {value === '' ? '---' : value}{' '}
              </MenuItem>,
              <Divider key="divider" />
            ]
          }
          return (
            <MenuItem key={index} value={value}>
              {value === '' ? '---' : value}
            </MenuItem>
          )
        }
      )
    }
    const isDate = isColumnDate(id)
    const isAssetAdministrator = isColumnAssetAdministrator(id)

    return (
      <div>
        <>
          {isAssetAdministrator ? (
            <>
              {tableMeta?.editedRows[index] ? (
                <Autocomplete
                  id="combo-box-demo"
                  fullWidth
                  label="Selecciona una Institución:"
                  variant="outlined"
                  placeholder="Institución"
                  margin="normal"
                  size="small"
                  options={
                    table.options.meta?.assetAdministratorInstitutionOptions
                  }
                  getOptionLabel={(institution) => {
                    if (institution.code === '') {
                      return ''
                    } else {
                      return `[${institution.code}] ${institution.name}`
                    }
                  }}
                  style={{ width: 300 }}
                  value={value}
                  getOptionSelected={(option, value) =>
                    option.code === value.code
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue(newValue)
                    } else {
                      setValue({ code: '', name: '' })
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona una Institución:"
                      variant="outlined"
                      placeholder="Institución"
                    />
                  )}
                  onBlur={updateCellData}
                />
              ) : (
                <Chip
                  label={`[${value?.code}] ${value?.name}`}
                  variant="outlined"
                  size="small"
                  style={{
                    textAlign: columnAlignment
                  }}
                />
              )}
            </>
          ) : hasPredefinedValues ? (
            <Select
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={updateCellData}
              fullWidth
              disableUnderline={!tableMeta?.editedRows[index]}
              inputProps={{
                readOnly: !tableMeta?.editedRows[index],
                style: { textAlign: columnAlignment }
              }}
            >
              {predefinedValues}
            </Select>
          ) : isDate ? (
            <TextField
              id={id + index}
              type="date"
              value={value}
              onChange={(event) => {
                const newValue = event.target.value
                setValue(newValue)
              }}
              onBlur={updateCellData}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                readOnly: !tableMeta?.editedRows[index]
              }}
            />
          ) : (
            <Input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={updateCellData}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateCellData()
                }
              }}
              placeholder="-"
              disableUnderline={!tableMeta?.editedRows[index]}
              fullWidth // Add this prop to enable full width
              inputProps={{
                readOnly: !tableMeta?.editedRows[index],
                style: {
                  textAlign: columnAlignment,
                  width:
                    table.getColumn(id).getSize() -
                    0.1 * table.getColumn(id).getSize()
                }
              }}
            />
          )}
        </>
      </div>
    )
  }
}

const EditCell = ({ row, table }) => {
  const meta = table.options.meta
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleAssetCompositionClick = () => {
    // Add logic to handle the menu item click
    meta?.setLoadingCompositions(true)
    meta?.setAssetCompositionsEditBoxOpen(true)
    const fetchData = async () => {
      try {
        const response = await GetAssetCompositionRequest({
          asset_ticker: row.original.ticker
        })
        const data = []
        response.data.forEach((comp) => {
          data.push({
            assetTicker: row.original.ticker,
            assetName: row.original.name,
            assetClassCode: comp.asset_class_code,
            assetClassName: comp.asset_class_name,
            weight: comp.weight,
            date: comp.date
          })
        })
        meta?.setAssetCompositionsToEdit(data)
        meta?.setLoadingCompositions(false)
        handleMenuClose()
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }

  const handleEditRowSelection = () => {
    // change the state of the row to editable
    meta?.setEditedRows((old) => ({
      ...old,
      [row.id]: !old[row.id]
    }))
    handleMenuClose()
  }

  const handleConfirmRowEdition = () => {
    // change the state of the row to not editable
    meta?.setEditedRows((old) => ({
      ...old,
      [row.id]: !old[row.id]
    }))
    // confirm data edition
    meta?.confirmRowEdition(row)
    handleMenuClose()
  }

  const handleCancelRowEdition = () => {
    // change the state of the row to not editable
    meta?.setEditedRows((old) => ({
      ...old,
      [row.id]: !old[row.id]
    }))
    // cancel data edition
    meta?.cancelRowEdition(row.index)
    handleMenuClose()
  }

  const handleCancelRowCreation = () => {
    meta?.cancelRowCreation(row.index)
  }

  const handleConfirmRowCreation = () => {
    meta?.setNewAssetRow(row.original)
    meta?.setAssetCompositionsCreateBoxOpen(true)
    meta?.setRowInCreationId(row.id)
  }

  return (
    <>
      {meta?.createdRows[row.index] ? (
        <>
          <IconButton
            onClick={() => {
              handleCancelRowCreation()
            }}
            color="primary"
          >
            <CancelIcon />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleConfirmRowCreation()
            }}
          >
            Guardar
          </Button>
        </>
      ) : (
        <>
          {meta?.editedRows[row.id] ? (
            <>
              <IconButton
                onClick={() => {
                  handleCancelRowEdition()
                }}
                color="primary"
              >
                <CancelIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleConfirmRowEdition()
                }}
                color="primary"
              >
                <CheckCircleIcon />
              </IconButton>
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                onClick={(event) => {
                  handleMenuClick(event)
                }}
                color="primary"
              >
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleEditRowSelection()}>
                  Editar
                </MenuItem>
                <MenuItem onClick={() => handleAssetCompositionClick()}>
                  Asset composition
                </MenuItem>
                {/* Add more MenuItem components as needed */}
              </Menu>
            </div>
          )}
        </>
      )}
    </>
  )
}

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const getAlignment = (columnId) => {
  if (
    columnId.toLowerCase().includes('withholding_tax') ||
    columnId.toLowerCase().includes('default_price')
  ) {
    return 'right' // Dates usually aligned to the left
  }
  return 'center' // Default for text and other data types
}

const isColumnAssetAdministrator = (columnId) => {
  return columnId.toLowerCase() === 'administrator'
}

const isColumnWithPredefinedValues = (columnId) => {
  return (
    columnId.toLowerCase().includes('exchange') ||
    columnId.toLowerCase().includes('currency') ||
    columnId.toLowerCase().includes('risk_currency') ||
    columnId.toLowerCase().includes('tax_currency') ||
    columnId.toLowerCase().includes('macro_asset') ||
    columnId.toLowerCase().includes('data_source') ||
    columnId.toLowerCase().includes('fund_type') ||
    columnId.toLowerCase().includes('dividend_payment')
  )
}

const isColumnDate = (columnId) => {
  return columnId.toLowerCase().includes('date')
}

export default function AssetsTable({
  colsData,
  rowsData,
  setRowsData,
  assetAdministratorInstitutionOptions,
  assetsFieldsDistinctValues,
  setAssetsFieldsDistinctValues,
  editedRows,
  setEditedRows,
  createdRows,
  setCreatedRows,
  showFullscreenButton = false,
  onTogleFullscreen = () => {},
  isFullscreen = false
}) {
  const classes = useStyles()
  // const [data, setData] = useState(rowsData)
  const [originalData, setOriginalData] = useState(rowsData) // for cancelling the row editing, to keep track of the data before the edition
  const [sorting, setSorting] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState([])

  // edit asset Compositions
  const [assetCompositionsEditBoxOpen, setAssetCompositionsEditBoxOpen] =
    useState(false)
  const [assetCompositionsToEdit, setAssetCompositionsToEdit] = useState([])
  const [loadingCompositions, setLoadingCompositions] = useState(false)

  // create asset compositions
  const [assetCompositionsCreateBoxOpen, setAssetCompositionsCreateBoxOpen] =
    useState(false)
  const [newAssetRow, setNewAssetRow] = useState(null)
  // for moving the rows state down when adding a new empty row
  const [rowInCreationId, setRowInCreationId] = useState(null)
  const [isAssetCreated, setIsAssetCreated] = useState(false)

  const tableContainerRef = useRef(null)

  const addNewRowToTable = () => {
    // add a new row to the top of the table
    const newRow = {
      ticker: '',
      name: '',
      administrator: { code: '', name: '' },
      dividend_ticker: '',
      exchange: '',
      currency: '',
      risk_currency: '',
      tax_currency: '',
      macro_asset: '',
      data_source: '',
      fund_type: '',
      fund_series: '',
      start_date: '',
      end_date: '',
      default_price: '',
      withholding_tax: '',
      dividend_payment: '',
      boerse_id: '',
      osa_ticker: ''
    }
    setRowsData((old) => [newRow, ...old])
    setOriginalData((old) => [newRow, ...old])

    // Update createdRows state
    setCreatedRows((old) => updateStateWithNewRow(old))

    // Update editedRows state
    setEditedRows((old) => updateStateWithNewRow(old))

    // Scroll up to the first row quickly
    if (tableContainerRef.current) {
      rowVirtualizer.scrollToIndex(0)
    }
  }

  const updateStateWithNewRow = (oldState) => {
    const newState = {}
    Object.keys(oldState).forEach((key) => {
      newState[parseInt(key, 10) + 1] = oldState[key]
    })
    newState[0] = true
    return newState
  }

  const updateStateAfterDelete = (oldState, index) => {
    const newState = {}
    Object.keys(oldState).forEach((key) => {
      const currentKey = parseInt(key, 10)
      if (currentKey > index) {
        newState[currentKey - 1] = oldState[key]
      } else if (currentKey < index) {
        newState[currentKey] = oldState[key]
      }
      // Ignore the key equal to the index (delete this entry)
    })
    return newState
  }

  const updateData = useCallback((rowIndex, columnId, value) => {
    setRowsData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          }
        }
        return row
      })
    )
  }, [])

  const confirmRowEdition = useCallback((row) => {
    const rowIndex = row.index
    // makes original data the same as data that has the update changes when confirming edition
    setOriginalData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...rowsData[rowIndex]
          }
        }
        return row
      })
    )

    // make a request to update the asset
    const fetchData = async () => {
      try {
        await UpdateAssetRequest({
          ticker: row.original.ticker,
          name: row.original.name,
          administrator_code: row.original.administrator.code,
          dividend_ticker:
            row.original.dividend_ticker === ''
              ? null
              : row.original.dividend_ticker,
          exchange: row.original.exchange,
          currency: row.original.currency,
          risk_currency: row.original.risk_currency,
          tax_currency: row.original.tax_currency,
          macro_asset: row.original.macro_asset,
          data_source: row.original.data_source,
          fund_type: row.original.fund_type,
          fund_series: row.original.fund_series,
          start_date: row.original.start_date,
          end_date: row.original.end_date === '' ? null : row.original.end_date,
          default_price:
            row.original.default_price === ''
              ? null
              : row.original.default_price,
          withholding_tax:
            row.original.withholding_tax === ''
              ? 0.0
              : row.original.withholding_tax,
          dividend_payment: row.original.dividend_payment,
          boerse_id:
            row.original.boerse_id === '' ? null : row.original.boerse_id,
          osa_ticker: row.original.osa_ticker
        })
        successNotification('savedCorrectly')
      } catch (error) {
        errorNotification('generalError')
      }
    }
    fetchData()
  }, [])

  const cancelRowEdition = useCallback(
    (rowIndex) => {
      // makes data the same as original data when cancelling edition
      setRowsData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...originalData[rowIndex]
            }
          }
          return row
        })
      )
    },
    [rowsData, originalData]
  )

  const cancelRowCreation = useCallback((rowIndex) => {
    // delete the row from the data and originalData
    setRowsData((old) => old.filter((row, index) => index !== rowIndex))
    setOriginalData((old) => old.filter((row, index) => index !== rowIndex))

    // Update createdRows state
    setCreatedRows((old) => updateStateAfterDelete(old, rowIndex))

    // Update editedRows state
    setEditedRows((old) => updateStateAfterDelete(old, rowIndex))
  }, [])

  const confirmRowCreation = useCallback((row) => {
    setCreatedRows((old) => ({
      ...old,
      [row.index]: !old[row.index]
    }))
    setEditedRows((old) => ({
      ...old,
      [row.id]: !old[row.id]
    }))
  }, [])

  useEffect(() => {
    // after asset is created
    if (isAssetCreated) {
      // replace the new asset row in the table
      setRowsData((old) => {
        const rows = [...old]
        rows.splice(rowInCreationId, 1, newAssetRow)
        return rows
      })

      setOriginalData((old) => {
        const rows = [...old]
        rows.splice(rowInCreationId, 1, newAssetRow)
        return rows
      })

      // update row states
      setCreatedRows((old) => ({
        ...old,
        [rowInCreationId]: !old[rowInCreationId]
      }))
      setEditedRows((old) => ({
        ...old,
        [rowInCreationId]: !old[rowInCreationId]
      }))

      // add new asset ticker to the distinct values
      setAssetsFieldsDistinctValues((old) => {
        const newDistinctValues = { ...old }
        newDistinctValues.ticker.push(newAssetRow.ticker)
        return newDistinctValues
      })

      setIsAssetCreated(false)
    }
  }, [isAssetCreated])

  const columnHelper = createColumnHelper()

  const columns = useMemo(() => {
    return [
      // Add the extra column at the beginning
      columnHelper.display({
        id: 'edit',
        cell: EditCell,
        maxSize: 200
      }),
      ...colsData.map((col) => {
        let column = columnHelper.accessor(col.id, {
          header: () => <div>{col.label}</div>
        })

        // set the not filterable columns
        if (
          col.id === 'administrator' ||
          col.id === 'dividend_ticker' ||
          col.id === 'start_date' ||
          col.id === 'end_date' ||
          col.id === 'withholding_tax' ||
          col.id === 'boerse_id' ||
          col.id === 'osa_ticker' ||
          col.id === 'default_price' ||
          col.id === 'actions' ||
          col.id === 'exchange' ||
          col.id === 'currency' ||
          col.id === 'risk_currency' ||
          col.id === 'tax_currency' ||
          col.id === 'macro_asset' ||
          col.id === 'data_source' ||
          col.id === 'fund_type' ||
          col.id === 'fund_series' ||
          col.id === 'dividend_payment'
        ) {
          column = {
            ...column,
            enableColumnFilter: false
          }
        }

        // set columns width sizes
        if (col.id === 'name') {
          column = {
            ...column,
            minSize: 580
          }
        }

        if (col.id === 'administrator') {
          column = {
            ...column,
            minSize: 300
          }
        }

        if (col.id === 'ticker' || col.id === 'dividend_ticker') {
          column = {
            ...column,
            maxSize: 200
          }
        }

        // set required columns
        if (
          col.id === 'dividend_ticker' ||
          col.id === 'exchange' ||
          col.id === 'fund_type' ||
          col.id === 'fund_series' ||
          col.id === 'end_date' ||
          col.id === 'default_price' ||
          col.id === 'withholding_tax' ||
          col.id === 'boerse_id' ||
          col.id === 'osa_ticker'
        ) {
          column = {
            ...column,
            meta: {
              required: false
            }
          }
        } else {
          column = {
            ...column,
            meta: {
              required: true
            }
          }
        }

        // Default return if no specific filter is needed
        return column
      })
    ]
  }, [colsData, columnHelper])

  const table = useReactTable({
    data: rowsData,
    columns,
    defaultColumn,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    meta: {
      updateData,
      editedRows,
      setEditedRows,
      confirmRowEdition,
      cancelRowEdition,
      assetCompositionsEditBoxOpen,
      setAssetCompositionsEditBoxOpen,
      assetCompositionsToEdit,
      setAssetCompositionsToEdit,
      loadingCompositions,
      setLoadingCompositions,
      assetAdministratorInstitutionOptions,
      createdRows,
      setCreatedRows,
      cancelRowCreation,
      confirmRowCreation,
      assetCompositionsCreateBoxOpen,
      setAssetCompositionsCreateBoxOpen,
      newAssetRow,
      setNewAssetRow,
      assetsFieldsDistinctValues,
      rowInCreationId,
      setRowInCreationId,
      isAssetCreated
    },
    state: {
      sorting,
      globalFilter,
      columnFilters
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  })

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 48, //estimate row height
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5
  })

  const fullscreenIcons = (
    <>
      {isFullscreen ? (
        <Grid item>
          <Tooltip title="Salir pantalla completa">
            <IconButton onClick={onTogleFullscreen} color="primary">
              <FullscreenExitIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item>
          <Tooltip title="Pantalla completa">
            <IconButton onClick={onTogleFullscreen} color="primary">
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </>
  )

  return (
    <CardWrapper>
      {assetCompositionsEditBoxOpen && (
        <AssetCompositionsEditDialog
          open={assetCompositionsEditBoxOpen}
          setOpen={setAssetCompositionsEditBoxOpen}
          compositions={assetCompositionsToEdit}
          loadingActualCompositions={loadingCompositions}
        />
      )}
      {assetCompositionsCreateBoxOpen && (
        <AssetCompositionsCreateDialog
          open={assetCompositionsCreateBoxOpen}
          setOpen={setAssetCompositionsCreateBoxOpen}
          newAssetData={newAssetRow}
          setNewAssetData={setNewAssetRow}
          assetAdministratorInstitutionOptions={
            assetAdministratorInstitutionOptions
          }
          assetFieldsPredefinedValues={assetsFieldsDistinctValues}
          setIsAssetCreated={setIsAssetCreated}
        />
      )}
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item xs={true} style={{ display: 'flex', alignItems: 'center' }}>
          <Search
            onChange={(value) => setGlobalFilter(String(value))}
            value={globalFilter}
          />
        </Grid>
        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            addNewRowToTable()
          }}
        >
          Agregar Asset
        </Button>
        {showFullscreenButton && fullscreenIcons}
        <Grid item xs={12}>
          <div
            className={
              isFullscreen
                ? classes.tableContainerFullscreen
                : classes.tableContainer
            }
            ref={tableContainerRef}
            style={{
              overflow: 'auto', //our scrollable table container
              position: 'relative', //needed for sticky header
              height: '800px' //should be a fixed height
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ display: 'grid' }}
              size="small"
            >
              <TableHead
                style={{
                  display: 'grid',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1
                }}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow
                    key={headerGroup.id}
                    style={{ display: 'flex', width: '100%' }}
                  >
                    {headerGroup.headers.map((header) => {
                      const hideSortIcon = !header.column.getCanSort()
                      return (
                        <StyledTanstackCell
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            display: 'flex',
                            width: header.getSize()
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                              }}
                            >
                              <div>
                                {' '}
                                {/* Wrapper for the sort label */}
                                <TableSortLabel
                                  className={
                                    hideSortIcon ? classes.hideSortIcon : ''
                                  }
                                  active={header.column.getIsSorted() !== false}
                                  direction={
                                    header.column.getIsSorted() === 'asc'
                                      ? 'asc'
                                      : 'desc'
                                  }
                                  onClick={header.column.getToggleSortingHandler()}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </TableSortLabel>
                              </div>
                              {header.column.getCanFilter() && (
                                <div>
                                  <TanstackFilter
                                    column={header.column}
                                    table={table}
                                    increaseSize={true}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </StyledTanstackCell>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody
                style={{
                  display: 'grid',
                  height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                  position: 'relative' //needed for absolute positioning of rows
                }}
              >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const row = rows[virtualRow.index]
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      data-index={virtualRow.index} //needed for dynamic row height measurement
                      ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                        width: '100%',
                        height: '50px', // Adjust the height to your preference
                        alignItems: 'center' // Vertically center the content within the row
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.column.id}
                          style={{
                            display: 'flex',
                            width: cell.column.getSize(),
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            borderBottom: '1px solid whitesmoke'
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </CardWrapper>
  )
}
