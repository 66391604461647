import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Autocomplete } from '@material-ui/lab'
import {
  DeletePlaidMatchedAccount,
  GetAccountsByInstitution,
  GetInstitutionsInPlaid,
  GetPlaidMatchedAccounts,
  UpdatePlaidMatchedAccount
} from 'axios/requests/operations'
import { useEffect, useState } from 'react'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  },
  button: {
    margin: theme.spacing(1)
  }
}))

const PlaidAccounts = ({
  matchedAccounts,
  setMatchedAccounts,
  isLoadingAccounts,
  setIsLoadingAccounts,
  selectedInstitution,
  setSelectedInstitution,
  selectedValidationState,
  setSelectedValidationState
}) => {
  const classes = useStyles()
  const [loadingDialog, setLoadingDialog] = useState(false)
  const [loadingDialogText, setLoadingDialogText] = useState('')
  const [editMatchedAccountDialog, setEditMatchedAccountDialog] = useState({
    open: false,
    matchAccount: null,
    wiq_account: null,
    is_validated: null
  })
  const [institutionsInPlaid, setInstitutionsInPlaid] = useState([
    { id: 0, name: 'Seleccione una institución' }
  ])
  const [wiqAccountsByInstitution, setWiqAccountsByInstitution] = useState([])

  useEffect(() => {
    GetInstitutionsInPlaid({}).then((res) => {
      setInstitutionsInPlaid(res.data)
    })
  }, [])

  const handleUpdate = () => {
    setLoadingDialogText('Actualizando cuenta...')
    setLoadingDialog(true)
    UpdatePlaidMatchedAccount({
      matched_account_id: editMatchedAccountDialog.matchedAccount.id,
      wiq_account_id: editMatchedAccountDialog.wiq_account?.account_id,
      is_validated: editMatchedAccountDialog.is_validated
    })
      .then(() => {
        GetPlaidMatchedAccounts({
          institution_id: selectedInstitution?.id,
          is_validated: selectedValidationState?.value
        })
          .then((res) => {
            setMatchedAccounts(res.data)
          })
          .catch(() => {
            errorNotification('generalError')
          })
          .finally(() => {
            successNotification('savedCorrectly')
            setEditMatchedAccountDialog({
              open: false,
              wiq_account: null,
              matchedAccount: null,
              is_validated: null
            })
            setLoadingDialog(false)
          })
      })
      .catch(() => {
        errorNotification('generalError')
        setLoadingDialog(false)
      })
  }

  const handleEditMatchedAccount = (matchedAccount) => {
    if (!wiqAccountsByInstitution.length) {
      GetAccountsByInstitution({
        institution: matchedAccount.institution_id
      }).then((res) => {
        setWiqAccountsByInstitution(res.data)
      })
    }
    setEditMatchedAccountDialog({
      open: true,
      matchedAccount: matchedAccount,
      wiq_account: null,
      is_validated: matchedAccount.is_validated
    })
  }

  const handleDelete = () => {
    if (
      window.confirm(
        '¿Estás seguro de que deseas eliminar esta asociación entre cuentas?'
      )
    ) {
      DeletePlaidMatchedAccount({
        match_account_id: editMatchedAccountDialog?.matchedAccount?.id
      })
        .then(() => {
          GetPlaidMatchedAccounts({
            institution_id: selectedInstitution?.id,
            is_validated: selectedValidationState?.value
          }).then((res) => {
            setMatchedAccounts(res.data)
          })
          successNotification('deletedCorrectly')
        })
        .catch(() => {
          errorNotification('generalError')
        })
        .finally(() => {
          setEditMatchedAccountDialog({
            open: false,
            matchedAccount: null,
            wiq_account: null,
            is_validated: null
          })
        })
    }
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <div className={classes.reportContainer}>
          <Autocomplete
            options={institutionsInPlaid}
            getOptionLabel={(option) => option.name}
            value={selectedInstitution}
            onChange={(event, newValue) => {
              setSelectedInstitution(newValue)
            }}
            style={{
              width: '100%',
              marginLeft: '10px',
              marginRight: '10px'
            }}
            renderInput={(params) => (
              <TextField {...params} label="Institución" />
            )}
          />
          <Autocomplete
            options={[
              { label: 'Validadas', value: true },
              { label: 'No Validadas', value: false }
            ]}
            value={selectedValidationState}
            onChange={(event, newValue) => setSelectedValidationState(newValue)}
            getOptionLabel={(option) => option.label}
            style={{
              width: '100%',
              marginLeft: '10px',
              marginRight: '10px'
            }}
            renderInput={(params) => (
              <TextField {...params} label="Estado de Validación" />
            )}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              setIsLoadingAccounts(true)
              setWiqAccountsByInstitution([])
              GetPlaidMatchedAccounts({
                institution_id: selectedInstitution?.id,
                is_validated: selectedValidationState?.value
              }).then((res) => {
                setMatchedAccounts(res.data)
                setIsLoadingAccounts(false)
                GetAccountsByInstitution({
                  institution: selectedInstitution?.id
                }).then((res) => {
                  setWiqAccountsByInstitution(res.data)
                })
              })
            }}
          >
            Filtrar
          </Button>
        </div>
        {isLoadingAccounts ? (
          <div className={classes.reportContainer}>
            <CircularProgress />
          </div>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Código de Cuenta en WealthIQ</TableCell>
                  <TableCell>ID de la cuenta en la institución</TableCell>
                  <TableCell>Nombre de la cuenta</TableCell>
                  <TableCell>Nombre Oficial</TableCell>
                  <TableCell>Institución</TableCell>
                  <TableCell>Nombre de Credencial Plaid</TableCell>
                  <TableCell>Validada</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matchedAccounts.map((matchedAccount) => (
                  <TableRow
                    key={matchedAccount.id}
                    onClick={() => {
                      handleEditMatchedAccount(matchedAccount)
                    }}
                    style={{ cursor: 'pointer' }}
                    hover
                  >
                    <TableCell>{matchedAccount.account_code}</TableCell>
                    <TableCell>
                      {matchedAccount.account_in_institution || '-'}
                    </TableCell>
                    <TableCell>
                      {matchedAccount.plaid_account_name || '-'}
                    </TableCell>
                    <TableCell>
                      {matchedAccount.plaid_account_official_name || '-'}
                    </TableCell>
                    <TableCell>{matchedAccount.institution_name}</TableCell>
                    <TableCell>
                      {matchedAccount.plaid_connection_name}
                    </TableCell>
                    <TableCell>
                      {matchedAccount.is_validated ? 'Si' : 'No'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      <Dialog open={loadingDialog}>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <CircularProgress />
            <Typography>{loadingDialogText}</Typography>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={editMatchedAccountDialog.open}
        onClose={() => {
          if (!selectedInstitution) {
            setWiqAccountsByInstitution([])
          }
          setEditMatchedAccountDialog({
            open: false,
            matchedAccount: null,
            wiq_account: null,
            is_validated: null
          })
        }}
      >
        <DialogTitle>Vincular Cuenta</DialogTitle>
        <DialogContent>
          <Typography variant="body2" style={{ marginTop: 16 }}>
            Cuenta Plaid:
            {editMatchedAccountDialog.matchedAccount?.plaid_account_name}
          </Typography>
          <Autocomplete
            options={wiqAccountsByInstitution || []}
            getOptionLabel={(option) => option.code || ''}
            value={editMatchedAccountDialog.wiq_account}
            onChange={(event, newValue) => {
              setEditMatchedAccountDialog({
                ...editMatchedAccountDialog,
                wiq_account: newValue
              })
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Cuenta WIQ"
                fullWidth
              />
            )}
          />

          <FormControlLabel
            control={
              <Switch
                checked={editMatchedAccountDialog.is_validated}
                onChange={(e) => {
                  setEditMatchedAccountDialog({
                    ...editMatchedAccountDialog,
                    is_validated: e.target.checked
                  })
                }}
                color="primary"
              />
            }
            label="Validada"
            style={{ marginTop: 16 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="secondary">
            Eliminar
          </Button>
          <Button
            onClick={() =>
              setEditMatchedAccountDialog({
                open: false,
                wiq_account: null,
                matchedAccount: null,
                is_validated: null
              })
            }
            color="secondary"
          >
            Cancelar
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PlaidAccounts
