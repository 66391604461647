import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import SavedPortfoliosModal from 'components/Graphs/PortfoliosReturnsSeries/SavedPortfoliosModal'
import { format, parseISO } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PortfolioTables from './PortfolioTables'
import PortfolioActions from './PortfoliosActions'
import RebalanceModal from './RebalanceModal'
import SnackbarNotification from './SnackbarNotification'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'

import HeaderContext from 'context/headerContext'
import Loading from 'utils/UI/Loading'
import { errorNotification } from 'utils/UI/Notifications/Notifications'
import { COLORS_GRAPH_ASSETS } from 'utils/UI/Theme'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import {
  createRebalancePortfolioRequest,
  deleteRebalancePortfolioRequest,
  downloadRebalancePortfolioRequest,
  portfolioAssetsSearchRequest,
  rebalancePortfolioSearchRequest,
  rebalanceReturnsRequest,
  updateRebalancePortfolioRequest
} from 'axios/requests/assetsComparator'

export default function OperationsRebalanceAssetsSeriesReturns() {
  const theme = useTheme()
  const [openSaveDialog, setOpenSaveDialog] = useState(false)
  const [openRebalanceDialog, setOpenRebalanceDialog] = useState(false)
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency } = headerState
  const [loading, setLoading] = useState(true)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [tickers, setTickers] = useState([])
  const [selectedTickersNamesDict, setSelectedTickersNamesDict] = useState({})
  const [tickersWeightsDict, setTickersWeightsDict] = useState({})
  const [portfolioInfoError, setPortfolioInfoError] = useState('')
  const [rebalanceFrequency, setRebalanceFrequency] = useState('No Rebalance')
  const [savedList, setSavedList] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [optionsTickers, setOptionsTickers] = useState([])
  const intervalRef = useRef()
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [loadingSavedList, setLoadingSavedList] = useState(false)
  const [returnsData, setReturnsData] = useState(null)
  const [portfolioName, setPortfolioName] = useState('')
  const [portfolioWeightsDict, setPortfolioWeightsDict] = useState({})
  const [rebalanceFrequencyDict, setRebalanceFrequencyDict] = useState({})
  const [selectedPortfolio, setSelectedPortfolio] = useState(null)
  const [previousPortfolioName, setpreviousPortfolioName] = useState('')
  const [newStartDate, setNewStartDate] = useState(null)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const [open, setOpen] = useState(false)

  const [chartOptions, setChartOptions] = useState({
    credits: false,
    plotOptions: {
      line: {
        marker: {
          enabled: false
        },
        events: {
          legendItemClick: function () {
            return false
          }
        }
      }
    },
    chart: {
      zoomType: 'x',
      style: {
        fontFamily: 'InconsolataRegular'
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          color: theme.palette.text.primary
        }
      }
    },
    yAxis: {
      title: {
        text: null
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: theme.palette.text.primary
        }
      ],
      labels: {
        style: {
          color: theme.palette.text.primary
        },
        formatter: function () {
          return ((this.value - 1) * 100).toFixed(1) + '%'
        }
      }
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
      formatter: function () {
        let points = ''
        this.points
          .sort((a, b) => {
            return b.point.y - a.point.y
          })
          .forEach((point) => {
            points += `<span style="color: ${point.series.color}">●</span> ${
              point.series.name
            }: ${((point.y - 1) * 100).toFixed(1)}%<br>`
          })
        const date = new Date(this.x)
        const isoDate = date.toISOString()
        const dateOnly = isoDate.substring(0, isoDate.indexOf('T'))
        return (
          '<b style="font-size: 11px  ">' +
          format(parseISO(dateOnly), 'dd/MM/yyyy') +
          '</b>' +
          '<br>' +
          '<div style="margin-top: 20px">' +
          points +
          '</div>'
        )
      }
    },
    legend: {
      enabled: true
    },
    lang: {
      noData: ''
    },
    series: []
  })

  useEffect(() => {
    setNewStartDate(null)
    let mounted = true
    if (Object.keys(portfolioWeightsDict).length > 0 && selectedPortfolio) {
      const fetchData = async () => {
        try {
          const result = await rebalanceReturnsRequest({
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            currency: currency,
            portfolioWeightsDict: portfolioWeightsDict,
            rebalanceFrequencyDict: rebalanceFrequencyDict
          })
          const parsedData = parseReturnsData(result.data)
          setReturnsData(parsedData)

          return result.data
        } catch (err) {
          errorNotification('miniBloombergError')
        }
      }

      if (mounted && currency) {
        window.addEventListener('transitionend', () => {
          Highcharts.charts.forEach((chart) => {
            if (chart) {
              chart.reflow()
            }
          })
        })
        setLoading(true)

        if (!Object.keys(portfolioWeightsDict).length) {
          setLoading(false)
          return
        }

        fetchData().then((result) => {
          if (mounted && result) {
            const series = result.map((portfolio, index) => {
              const data = portfolio.series.map((element) => {
                return [Date.parse(element[0]), element[1]]
              })

              return {
                name: portfolio.name,
                color: COLORS_GRAPH_ASSETS[index % COLORS_GRAPH_ASSETS.length],
                data: data
              }
            })

            setChartOptions({ ...chartOptions, series })
          }
          setLoading(false)
        })
      }
      return () => {
        mounted = false
        window.removeEventListener('transitionend', () => {
          Highcharts.charts.forEach((chart) => {
            if (chart) {
              chart.reflow()
            }
          })
        })
      }
    } else {
      setLoading(false)
      setReturnsData(null)
    }
  }, [
    currency,
    startDate,
    endDate,
    portfolioWeightsDict,
    rebalanceFrequencyDict,
    theme.palette.secondary.main
  ])

  const parseReturnsData = (data) => {
    if (!data || data.length === 0) return []

    const newStartDate = parseISO(data[0].series[0][0])

    if (format(startDate, 'yyyy-MM-dd') < format(newStartDate, 'yyyy-MM-dd')) {
      setNewStartDate(newStartDate)
      setOpenSnackbar(true)
    }

    return data.map((portfolio) => ({
      name: portfolio.name,
      cagr: parseFloat(portfolio.cagr),
      vol: parseFloat(portfolio.vol),
      returns: portfolio.returns
    }))
  }

  useEffect(() => {
    const assetSearchTickers = async () => {
      const result = await portfolioAssetsSearchRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency: currency,
        search_term: searchTerm
      })
      return result.data
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(() => {
      if (searchTerm.length > 0) {
        setLoadingSearch(true)
        assetSearchTickers().then((tickersFounded) => {
          setOptionsTickers(tickersFounded)
          setLoadingSearch(false)
        })
      } else {
        setLoadingSearch(false)
        setOptionsTickers([])
      }
      clearInterval(intervalRef.current)
    }, 500)
  }, [searchTerm, startDate, endDate, currency])

  useEffect(() => {
    setLoadingSavedList(true)
    const fetchList = async () => {
      try {
        const data = await fetchSavedList()
        setSavedList(data)
      } catch (err) {
        errorNotification('miniBloombergError')
      } finally {
        setLoadingSavedList(false)
      }
    }

    fetchList()
  }, [])

  const handleOnInputChange = async (e) => {
    setSearchTerm(e.target.value)
  }

  const fetchSavedList = async () => {
    try {
      const result = await rebalancePortfolioSearchRequest()
      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const deleteSavedData = async (uuid) => {
    try {
      const result = await deleteRebalancePortfolioRequest({
        uuid
      })
      fetchSavedList().then((data) => {
        setSavedList(data)
      })
      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const handleSavePortfolio = async (portfolioName) => {
    try {
      const portfolioIsSaved = savedList.some(
        (portfolio) => portfolio.name === portfolioName
      )

      if (portfolioIsSaved) {
        return
      }

      let result
      result = await createRebalancePortfolioRequest({
        name: portfolioName,
        tickers_weights_dict: portfolioWeightsDict[portfolioName],
        tickers_names_dict: selectedTickersNamesDict,
        rebalance_frequency: rebalanceFrequencyDict[portfolioName]
      })

      fetchSavedList().then((data) => {
        setSavedList(data)
      })

      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const optionsTickersDict = useMemo(() => {
    return optionsTickers.reduce((acc, ticker) => {
      acc[ticker.ticker] = ticker.name
      return acc
    }, {})
  }, [optionsTickers])

  const optionsTickersIds = useMemo(() => {
    return [...tickers, ...optionsTickers.map((ticker) => ticker.ticker)]
  }, [tickers, optionsTickers])

  const downloadHandler = async () => {
    setLoadingDownload(true)
    try {
      const response = await downloadRebalancePortfolioRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency: currency,
        portfolioWeightsDict: portfolioWeightsDict,
        rebalanceFrequencyDict: rebalanceFrequencyDict
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Abaqus - Rebalance Series returns.xlsx')
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingDownload(false)
  }

  const handleWeightChange = (ticker, value) => {
    setTickersWeightsDict((prevTickersWeightsDict) => ({
      ...prevTickersWeightsDict,
      [ticker]: parseFloat(value) || 0
    }))
    setPortfolioInfoError('')
  }

  const handleTickerSelection = (_tickers) => {
    const addedTickers = _tickers.filter((t) => !tickers.includes(t))
    const removedTickers = tickers.filter((t) => !_tickers.includes(t))

    setTickers(_tickers)

    setSelectedTickersNamesDict((prevDict) => {
      const newDict = { ...prevDict }

      addedTickers.forEach((ticker) => {
        if (!newDict[ticker]) {
          newDict[ticker] = optionsTickersDict[ticker]
        }
      })

      return newDict
    })

    removedTickers.forEach((ticker) => handleTickerRemove(ticker))
  }

  const handleTickerRemove = (ticker) => {
    setTickers((prevTickers) => prevTickers.filter((t) => t !== ticker))
    setTickersWeightsDict((prevWeights) => {
      const rest = { ...prevWeights }
      delete rest[ticker]
      return rest
    })
  }

  const handleNewPortfolioInfo = () => {
    const cleanedPortfolioName = portfolioName.trim()

    const totalWeights = Object.values(tickersWeightsDict).reduce(
      (sum, weight) => sum + weight,
      0
    )

    const previousPortfolioNameIsSaved = savedList.some(
      (portfolio) => portfolio.name === previousPortfolioName
    )

    const newPortfolioNameIsSaved = savedList.some(
      (portfolio) => portfolio.name === cleanedPortfolioName
    )

    if (totalWeights > 100) {
      setPortfolioInfoError(
        `La suma de los pesos no puede ser mayor a 100, te estás excediendo por ${
          totalWeights - 100
        }`
      )
      return
    } else if (totalWeights < 100) {
      setPortfolioInfoError(
        `La suma de los pesos no puede ser menor a 100, te faltan ${
          100 - totalWeights
        }`
      )
      return
    } else if (!cleanedPortfolioName) {
      setPortfolioInfoError('Por favor ingresa un nombre para el portafolio.')
      return
    } else if (
      portfolioWeightsDict[cleanedPortfolioName] &&
      cleanedPortfolioName !== previousPortfolioName
    ) {
      setPortfolioInfoError(
        'Ya existe un portafolio con ese nombre, elige otro.'
      )
      return
    } else if (
      newPortfolioNameIsSaved &&
      cleanedPortfolioName !== previousPortfolioName
    ) {
      setPortfolioInfoError(
        'Tienes guardado un portafolio con ese nombre, elige otro'
      )
      return
    }

    if (
      previousPortfolioName !== cleanedPortfolioName ||
      previousPortfolioNameIsSaved
    ) {
      editPortfolio(cleanedPortfolioName, previousPortfolioNameIsSaved)
    } else {
      handleAddPortfolio(
        cleanedPortfolioName,
        tickersWeightsDict,
        rebalanceFrequency
      )
    }
  }

  const editPortfolio = (portfolioName, portfolioIsSaved) => {
    if (portfolioIsSaved) {
      editSavedPortfolio(portfolioName)
      handleAddPortfolio(portfolioName, tickersWeightsDict, rebalanceFrequency)
    }
    if (previousPortfolioName !== portfolioName) {
      setPortfolioWeightsDict((prevDict) => {
        const newDict = { ...prevDict }
        delete newDict[previousPortfolioName]
        newDict[portfolioName] = { ...tickersWeightsDict }
        return newDict
      })

      setRebalanceFrequencyDict((prevDict) => {
        const newDict = { ...prevDict }
        delete newDict[previousPortfolioName]
        newDict[portfolioName] = rebalanceFrequency
        return newDict
      })
    }
    setPortfolioName('')
    setpreviousPortfolioName('')
    setSelectedPortfolio(portfolioName)
    setOpenRebalanceDialog(false)
    setOpenSaveDialog(false)
  }

  const editSavedPortfolio = async (portfolioName) => {
    try {
      const savedPortfolio = savedList.find(
        (portfolio) => portfolio.name === previousPortfolioName
      )

      let result
      result = await updateRebalancePortfolioRequest({
        uuid: savedPortfolio.uuid,
        name: portfolioName,
        tickers_weights_dict: tickersWeightsDict,
        tickers_names_dict: selectedTickersNamesDict,
        rebalance_frequency: rebalanceFrequency
      })

      fetchSavedList().then((data) => {
        setSavedList(data)
      })

      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const handleAddPortfolio = (
    portfolioName,
    tickersWeightsDict,
    rebalanceFrequency
  ) => {
    setPortfolioWeightsDict((prevDict) => {
      const newDict = {
        ...prevDict,
        [portfolioName]: { ...tickersWeightsDict }
      }

      return newDict
    })

    setRebalanceFrequencyDict((prevDict) => {
      const newDict = {
        ...prevDict,
        [portfolioName]: rebalanceFrequency
      }
      return newDict
    })
    setPortfolioName('')
    setpreviousPortfolioName('')
    setSelectedPortfolio(portfolioName)
    setOpenRebalanceDialog(false)
    updateSelectedTickersNamesDict(portfolioName)
  }

  const updateSelectedTickersNamesDict = (portfolioName) => {
    const savedPortfolio = savedList.find(
      (portfolio) => portfolio.name === portfolioName
    )

    if (savedPortfolio) {
      setSelectedTickersNamesDict((prevDict) => ({
        ...prevDict,
        ...savedPortfolio.tickers_names_dict
      }))
    }
  }

  const handleDeletePortfolio = (portfolioName) => {
    setLoading(true)

    setPortfolioWeightsDict((prevDict) => {
      const newDict = { ...prevDict }
      delete newDict[portfolioName]
      return newDict
    })

    setRebalanceFrequencyDict((prevDict) => {
      const newDict = { ...prevDict }
      delete newDict[portfolioName]
      return newDict
    })

    const remainingPortfolios = Object.keys(portfolioWeightsDict).filter(
      (key) => key !== portfolioName
    )
    setSelectedPortfolio(
      remainingPortfolios.length > 0 ? remainingPortfolios[0] : null
    )

    setLoading(false)
  }

  const handleEditPortfolio = (portfolioName) => {
    setpreviousPortfolioName(portfolioName)
    setPortfolioInfoError('')
    setOpenRebalanceDialog(true)
    setPortfolioName(portfolioName)
    setTickersWeightsDict(portfolioWeightsDict[portfolioName])
    setRebalanceFrequency(rebalanceFrequencyDict[portfolioName])
    setTickers(Object.keys(portfolioWeightsDict[portfolioName]))
  }

  const handleEditSavedPortfolio = (portfolioName) => {
    const savedPortfolio = savedList.find(
      (portfolio) => portfolio.name === portfolioName
    )

    setpreviousPortfolioName(portfolioName)
    setPortfolioInfoError('')
    setOpenRebalanceDialog(true)
    setPortfolioName(portfolioName)
    setTickersWeightsDict(savedPortfolio.tickers_weights_dict)
    setRebalanceFrequency(savedPortfolio.rebalance_frequency)
    setTickers(Object.keys(savedPortfolio.tickers_weights_dict))
  }

  const simulatePortfolio = () => {
    setTickersWeightsDict({})
    setRebalanceFrequency('No Rebalance')
    setTickers([])
    setPortfolioName('')
    setPortfolioInfoError('')
    setOpenRebalanceDialog(true)
  }

  return (
    <CardWrapper
      title="Evolución rentabilidad de portafolios"
      titleFeedback="Evolución rentabilidad de portafolios"
    >
      <PortfolioActions
        loadingSavedList={loadingSavedList}
        savedList={savedList}
        loadingDownload={loadingDownload}
        portfolioWeightsDict={portfolioWeightsDict}
        onSimulate={simulatePortfolio}
        onSave={() => setOpenSaveDialog(true)}
        onDownload={downloadHandler}
      />

      {loading ? (
        <div style={{ height: 400 }}>
          <Loading />
        </div>
      ) : Object.keys(portfolioWeightsDict).length > 0 && selectedPortfolio ? (
        <>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />

          <PortfolioTables
            portfolioWeightsDict={portfolioWeightsDict}
            selectedPortfolio={selectedPortfolio}
            selectedTickersNamesDict={selectedTickersNamesDict}
            COLORS_GRAPH_ASSETS={COLORS_GRAPH_ASSETS}
            rebalanceFrequencyDict={rebalanceFrequencyDict}
            returnsData={returnsData}
            setSelectedPortfolio={setSelectedPortfolio}
            handleEditPortfolio={handleEditPortfolio}
            handleSavePortfolio={handleSavePortfolio}
            handleDeletePortfolio={handleDeletePortfolio}
          />
        </>
      ) : (
        // Aquí se añade el mensaje en caso de que no haya portafolio seleccionado
        <Grid container style={{ height: 200 }} spacing={1}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
            spacing={3}
          >
            <Typography variant="h6" component="h2">
              Por favor selecciona el portafolio a simular
            </Typography>
          </Grid>
        </Grid>
      )}

      {newStartDate && (
        <SnackbarNotification
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          newStartDate={newStartDate}
        />
      )}

      <RebalanceModal
        open={open}
        setOpen={setOpen}
        portfolioName={portfolioName}
        loadingSearch={loadingSearch}
        openRebalanceDialog={openRebalanceDialog}
        setOpenRebalanceDialog={setOpenRebalanceDialog}
        setPortfolioName={setPortfolioName}
        optionsTickersDict={optionsTickersDict}
        optionsTickersIds={optionsTickersIds}
        tickers={tickers}
        setOptionsTickers={setOptionsTickers}
        handleOnInputChange={handleOnInputChange}
        handleTickerSelection={handleTickerSelection}
        tickersWeightsDict={tickersWeightsDict}
        handleWeightChange={handleWeightChange}
        portfolioInfoError={portfolioInfoError}
        rebalanceFrequency={rebalanceFrequency}
        setRebalanceFrequency={setRebalanceFrequency}
        handleNewPortfolioInfo={handleNewPortfolioInfo}
        setpreviousPortfolioName={setpreviousPortfolioName}
        selectedTickersNamesDict={selectedTickersNamesDict}
      />

      <SavedPortfoliosModal
        open={openSaveDialog}
        onClose={() => setOpenSaveDialog(false)}
        savedList={savedList || []}
        handleAddPortfolio={handleAddPortfolio}
        deleteSavedData={deleteSavedData}
        editSavedPortfolio={handleEditSavedPortfolio}
      />
    </CardWrapper>
  )
}
