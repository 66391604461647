import clsx from 'clsx'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import baseStyles from 'utils/UI/BaseStyles'

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1)
  }
}))

const StyledFormControlLabel = withStyles((theme) => ({
  root: {
    margin: 0,
    marginRight: theme.spacing(2)
  }
}))(FormControlLabel)

const StyledRadio = withStyles(() => ({
  root: {
    display: 'none'
  }
}))(Radio)

export default function CustomRadioSelector({
  title,
  icon,
  radioList,
  currentValue,
  onChangeHandler
}) {
  const baseClasses = baseStyles()
  const classes = useStyles()

  return (
    <div className={classes.radioContainer}>
      <div className={classes.iconContainer}>{icon}</div>
      <FormControl>
        <FormLabel component="legend">{title}</FormLabel>
        <RadioGroup
          value={currentValue}
          onChange={onChangeHandler}
          className={classes.radioGroup}
        >
          {radioList.map((element) => {
            return (
              <StyledFormControlLabel
                value={element.value}
                key={element.key}
                control={<StyledRadio />}
                label={element.label}
                className={clsx({
                  [baseClasses.primaryColor]: element.value === currentValue
                })}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    </div>
  )
}
