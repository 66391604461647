import clsx from 'clsx'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import baseStyles from '../BaseStyles'

import Auxiliary from 'utils/Auxiliary/Auxiliary'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  shadow: {
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1
  },
  cardTitle: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(1),
      fontSize: 20,
      marginTop: 2
    }
  }
}))

export default function CardWrapper({
  children,
  title,
  titleFeedback,
  action,
  customHeight,
  width = 'auto',
  shadow = true,
  background
}) {
  const classes = useStyles()
  const baseClasses = baseStyles()
  return (
    <Auxiliary>
      <Card
        className={clsx(classes.card, {
          [classes.shadow]: shadow,
          [baseClasses.background]: background
        })}
        style={{ height: customHeight, width }}
      >
        <CardContent className={classes.cardContent}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h1" className={classes.cardTitle} gutterBottom>
              {title}
              {titleFeedback && (
                <Tooltip arrow title={titleFeedback}>
                  <InfoOutlinedIcon color="secondary" />
                </Tooltip>
              )}
            </Typography>
            {action}
          </Grid>
          {children}
        </CardContent>
      </Card>
    </Auxiliary>
  )
}
