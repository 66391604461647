import HeaderContext from 'context/headerContext'
import useResponsive from 'hooks/useResponsive'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'

import { format } from 'date-fns'

import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { Backdrop, Modal } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import QuoteValueSeries from 'components/Graphs/QuoteValueSeries'
import TreeTable from 'components/Graphs/TreeTable'
import TanstackTable from 'utils/UI/Tables/TanstackTable'

import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  ClientPortfolioHoldingsRequest,
  ClientPortfoliosRequest,
  ClientsRequest,
  CreateHoldingAdjustmentRequest,
  DownloadHoldingsRequest
} from 'axios/requests/operations'
import Loading from 'utils/UI/Loading'

import CircularProgress from '@material-ui/core/CircularProgress'
import DownloadButton from 'utils/UI/Buttons/DownloadButton'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'

import { lighten } from '@material-ui/core/styles/colorManipulator'
import { COLORS_GRAPH_ASSETS } from 'utils/UI/Theme'

const colsData = [
  {
    id: 'account_code',
    label: 'Code'
  },
  {
    id: 'account',
    label: 'Account Name'
  },
  {
    id: 'currency',
    label: 'CCY'
  },
  {
    id: 'asset',
    label: 'Name'
  },
  {
    id: 'ticker',
    label: 'Ticker'
  },
  {
    id: 'quantity',
    label: 'Quant'
  },
  {
    id: 'price_clp',
    label: 'Px CLP'
  },
  {
    id: 'price_usd',
    label: 'Px USD'
  },
  {
    id: 'value_clp',
    label: 'Value CLP'
  },
  {
    id: 'value_usd',
    label: 'Value USD'
  },
  {
    id: 'adjusted_quantity',
    label: 'Adj Quant'
  },
  {
    id: 'data_source',
    label: 'Source'
  }
]

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  seriesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  },
  holdingsSelectorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  fullscreenContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Set the background color to a light color
    borderRadius: 8, // Adjust as needed
    padding: theme.spacing(2), // Add padding as needed
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.drawer + 1 // Ensure the fullscreen component is above the sidebar
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function ClientData() {
  const classes = useStyles()
  const theme = useTheme()
  const { isMobile } = useResponsive()
  const { headerState } = useContext(HeaderContext)
  const { endDate } = headerState
  const [clients, setClients] = useState([])
  const [selectedPortfolios, setSelectedPortfolios] = useState([])
  const [portfolios, setPortfolios] = useState([])
  const [loadingDownloadHoldings, setLoadingDownloadHoldings] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const [loadingHoldings, setLoadingHoldings] = useState(false)
  const [rowsData, setRowsData] = useState(null)
  const [loadingHoldingAdjustments, setLoadingHoldingAdjustments] =
    useState(false)
  const [fullscreenHoldingsTable, setFullscreenHoldingsTable] = useState(false)

  const handleFullscreenHoldingsTableToggle = () => {
    setFullscreenHoldingsTable(!fullscreenHoldingsTable)
  }

  useEffect(() => {
    let didCancel = false
    const fetchClients = async () => {
      try {
        const result = await ClientsRequest()
        if (!didCancel) {
          setClients(result.data)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchClients()

    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    let didCancel = false
    const fetchPortfolios = async () => {
      try {
        if (selectedClient) {
          const result = await ClientPortfoliosRequest({
            client_id: selectedClient.id
          })
          if (!didCancel) {
            setPortfolios(result.data)
          }
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchPortfolios()

    return () => {
      didCancel = true
    }
  }, [selectedClient])

  useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      try {
        if (selectedPortfolios.length > 0) {
          setLoadingHoldings(true)
          const portfolioCodes = selectedPortfolios.map((p) => p.code).join(',')
          const result = await ClientPortfolioHoldingsRequest({
            endDate: format(endDate, 'yyyy-MM-dd'),
            portfolio_codes: portfolioCodes,
            mode: 'adjust'
          })
          return result.data
        }
      } catch (err) {
        errorNotification('generalError')
        setLoadingHoldings(false)
      }
    }

    if (mounted && selectedPortfolios) {
      fetchData().then((data) => {
        if (mounted && data) {
          const newData = data.HoldingData.map((x) => {
            return { ...x, date: moment(x.date).format('DD-MM-YYYY') }
          })
          setRowsData(newData)
          setLoadingHoldings(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [selectedPortfolios, endDate])

  useEffect(() => {
    if (!selectedClient) {
      setSelectedPortfolios([])
      setPortfolios([])
      setRowsData(null)
    }
  }, [selectedClient])

  const downloadHoldingsHandler = async () => {
    setLoadingDownloadHoldings(true)
    try {
      const portfolioCodes = selectedPortfolios.map((p) => p.code).join(',')
      const response = await DownloadHoldingsRequest({
        endDate: format(endDate, 'yyyy-MM-dd'),
        portfolio_codes: portfolioCodes
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url

      link.setAttribute(
        'download',
        `${format(endDate, 'yyyyMMdd')} - ${portfolioCodes} - Holdings.xlsx`
      )

      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingDownloadHoldings(false)
  }

  const handleTableSave = (updatedData) => {
    setRowsData(updatedData)
    saveHoldingAdjustmentHandler(updatedData)
  }

  const saveHoldingAdjustmentHandler = async (updatedData) => {
    setLoadingHoldingAdjustments(true)
    try {
      const adjustments = updatedData
        .filter(
          (row) =>
            row.adjusted_quantity !== undefined &&
            row.adjusted_quantity !== null &&
            row.adjusted_quantity !== ''
        )
        .map((row) => {
          return {
            account_code: row.account_code,
            ticker: row.ticker,
            date: moment(row.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            real_quantity:
              typeof row.adjusted_quantity === 'string'
                ? row.adjusted_quantity.replace(/,/g, '.')
                : row.adjusted_quantity
          }
        })
      await CreateHoldingAdjustmentRequest({
        adjustments: adjustments
      })
      successNotification('savedCorrectly')
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingHoldingAdjustments(false)
  }

  const holdingsTable = (
    <Grid
      container
      justifyContent={isMobile ? 'center' : 'space-between'}
      alignItems="center"
    >
      {loadingHoldings ? (
        <Loading />
      ) : !rowsData || !portfolios.length ? (
        <Grid container justifyContent="center" style={{ paddingTop: 45 }}>
          <Typography variant="h6">
            Selecciona un portafolio para ver los holdings
          </Typography>
        </Grid>
      ) : (
        <TanstackTable
          colsData={colsData}
          rowsData={rowsData}
          showSaveButton={true}
          onSave={handleTableSave}
          loadingSave={loadingHoldingAdjustments}
          showFullscreenButton={true}
          onTogleFullscreen={handleFullscreenHoldingsTableToggle}
          isFullscreen={fullscreenHoldingsTable}
          filterExcludedColumns={[
            'quantity',
            'price_clp',
            'price_usd',
            'value_clp',
            'value_usd',
            'adjusted_quantity'
          ]}
          showDateOnTopCorner={true}
        />
      )}
    </Grid>
  )

  return (
    <div className={classes.contentContainer}>
      <div className={classes.clientDataContainer}>
        <CardWrapper title="Client Data" titleFeedback="Client Data Validation">
          <Grid
            container
            className={classes.holdingsSelectorContainer}
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                label="Selecciona un cliente:"
                variant="outlined"
                placeholder="Clientes"
                margin="normal"
                options={clients}
                getOptionLabel={(client) =>
                  `[${client.code}] ${client.first_name} ${client.last_name}`
                }
                value={selectedClient}
                onChange={(event, newValue) => {
                  setSelectedClient(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona un cliente:"
                    variant="outlined"
                    placeholder="Clientes"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((client, index) => (
                    <Tooltip title={client.code} key={index}>
                      <Chip
                        style={{
                          marginRight: 12,
                          color: lighten(
                            [
                              theme.palette.primary.main,
                              ...COLORS_GRAPH_ASSETS
                            ][index],
                            0.5
                          )
                            ? '#fff'
                            : '#000'
                        }}
                        label={`[${client.code}] ${client.first_name} ${client.last_name}`}
                        key={client.code}
                        classes={{ deleteIcon: classes.deleteIcon }}
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                label="Selecciona un portafolio:"
                variant="outlined"
                placeholder="Portafolios"
                margin="normal"
                multiple
                options={portfolios}
                getOptionLabel={(portfolio) =>
                  `[${portfolio.code}]: ${portfolio.name}`
                }
                value={selectedPortfolios}
                onChange={(event, newValues) => {
                  setSelectedPortfolios(newValues)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona un portafolio:"
                    variant="outlined"
                    placeholder="Portafolios"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((portfolio, index) => (
                    <Tooltip title={portfolio.code} key={index}>
                      <Chip
                        style={{
                          marginRight: 12,
                          backgroundColor: theme.palette.orange.lighten1,
                          color: theme.palette.getContrastText(
                            theme.palette.orange.lighten1
                          )
                        }}
                        size="small"
                        label={portfolio.code}
                        key={portfolio.code}
                        classes={{ deleteIcon: classes.deleteIcon }}
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            item
            xs={12}
          ></Grid>
        </CardWrapper>
      </div>

      <div className={classes.seriesContainer}>
        <QuoteValueSeries
          selectedPortfolios={selectedPortfolios}
          portfolios={portfolios}
        />
      </div>

      <div className={classes.clientDataContainer}>
        <CardWrapper className={classes.reportCard} width="100%">
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.cardReportName}>
              Reporte Holdings
            </Typography>
            {loadingDownloadHoldings ? (
              <CircularProgress />
            ) : (
              <DownloadButton onClickHandler={downloadHoldingsHandler} />
            )}
          </Grid>
        </CardWrapper>
      </div>
      <div>
        {fullscreenHoldingsTable ? (
          // Fullscreen holdings table render
          <>
            <Backdrop open={fullscreenHoldingsTable} />
            <Modal
              open={fullscreenHoldingsTable}
              onClose={handleFullscreenHoldingsTableToggle}
              className={classes.modal}
            >
              <div className={classes.fullscreenContainer}>{holdingsTable}</div>
            </Modal>
          </>
        ) : (
          // Normal holdings table render
          holdingsTable
        )}
      </div>
      <div className={classes.clientDataContainer}>
        <TreeTable selectedPortfolio={selectedPortfolios[0]} />
      </div>
    </div>
  )
}
