import { getUserFlagsRequest } from 'axios/requests/flags'

const createFlagsProvider = () => {
  let flags = null
  let flagObservers = []

  const setFlags = (newFlags) => {
    flags = newFlags
    notifyFlags()
  }

  const notifyFlags = () => {
    flagObservers.forEach((observer) => {
      observer(flags)
    })
  }

  const subscribeFlags = (observer) => {
    flagObservers.push(observer)
  }

  const unsubscribeFlags = (observer) => {
    flagObservers = flagObservers.filter((_observer) => _observer !== observer)
  }

  const getUserFlags = async () => {
    try {
      const response = await getUserFlagsRequest()
      setFlags(response.data) // Update flags when fetched successfully
    } catch (error) {
      console.error('Failed to fetch user flags:', error)
    }
  }

  return {
    getUserFlags,
    subscribeFlags,
    unsubscribeFlags
  }
}

export { createFlagsProvider }
