import { useState } from 'react'

import OperationsAssetsSeriesReturns from 'components/Graphs/OperationsAssetsSeriesReturns'
import InstrumentReturnsTable from 'components/Graphs/instrumentReturnsTable'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

export default function OperationsAssetsComparator() {
  const [data, setData] = useState()
  const handleData = (value) => {
    setData(value)
  }

  return (
    <Auxiliary>
      <OperationsAssetsSeriesReturns handleData={handleData} />
      <InstrumentReturnsTable data={data} />
    </Auxiliary>
  )
}
