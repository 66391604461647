import { useEffect, useRef, useState } from 'react'

import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SendIcon from '@material-ui/icons/Send'

import {
  createUserCoversationRequest,
  deleteConversationRequest,
  getConversationMessagesRequest,
  getUserConversationsRequest,
  updateConversationNameRequest
} from 'axios/requests/message'
import MessageLabel from 'components/ChatPage/Message'
import NoMessage from 'components/ChatPage/NoMessageScreen'
import usePostRequest from 'hooks/usePostRequest'
import Loading from 'utils/UI/Loading'
import BotResponseLoader from 'utils/UI/Loading/BotResponseLoader'
import ChatLoader from 'utils/UI/Loading/ChatLoader'

const useStyles = makeStyles((theme) => ({
  chatPage: {
    display: 'flex',
    gap: 10,
    padding: 5,
    height: '80vh'
  },
  sidebar: {
    flex: '0 0 25%',
    padding: '10px',
    paddingTop: theme.spacing(3),
    backgroundColor: '#f0f0f0',
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto'
  },
  messageListContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    borderRadius: theme.spacing(1)
  },
  messageList: {
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#f5f5f5',
    padding: '5%',
    gap: '15px'
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    width: '100%'
  },
  inputText: {
    flex: 1
  },
  inputButton: {
    marginLeft: theme.spacing(2)
  },
  selectedConversation: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: 'auto',
    marginRight: theme.spacing(2)
  },
  button: {
    padding: theme.spacing(0.5)
  }
}))

const SCROLL_SPEED = 14
const SCROLL_INTERVAL = 4

export default function Chat() {
  const classes = useStyles()
  const [humanMessage, setHumanMessage] = useState('')
  const [postResult, postRequest] = usePostRequest('/chats/messages/create/')
  const [messageList, setMessageList] = useState([])
  const messageListRef = useRef(null)
  const sidebarRef = useRef(null)
  const [conversationId, setConversationId] = useState('')
  const [userConversations, setUserConversations] = useState([])
  const [lastUsedConversationId, setLastUsedConversationId] = useState('')
  const [editedConversationName, setEditedConversationName] = useState('')
  const [isEditingName, setIsEditingName] = useState(false)
  const [editedConversationId, setEditedConversationId] = useState(null)
  const [
    isConversationAtTheBeginningOfList,
    setIsConversationAtTheBeginningOfList
  ] = useState(false)
  const [conversationLoading, setConversationLoading] = useState(true)

  const formatConversationDate = (dateObject) => {
    // Define options for formatting the date in Spanish
    const options = {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }
    // Format the date in Spanish
    const formattedDate = dateObject.toLocaleDateString('es-ES', options)
    return formattedDate
  }

  const addHumanMessageToList = (message) => {
    if (message !== '') {
      setMessageList((prevMessageList) => [
        ...prevMessageList,
        { id: undefined, type: 'human', message: message }
      ])
    }
  }

  const addBotMessageToList = (message) => {
    setMessageList((prevMessageList) => [
      ...prevMessageList,
      { id: message.id, type: 'ai', message: message.content }
    ])
  }

  const handleHumanMessageOnChange = (message) => {
    setHumanMessage(message)
  }

  const handleHumanMessageOnSubmit = async () => {
    addHumanMessageToList(humanMessage)
    setHumanMessage('')
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !postResult.isLoading) {
      handleHumanMessageOnSubmit()
    }
  }

  const addNewConversationToUserConversations = (conversation) => {
    setUserConversations((prevUserConversations) => [
      conversation,
      ...prevUserConversations
    ])
  }

  const handleSubmitNewConversation = async () => {
    try {
      const newConversationResponse = await createUserCoversationRequest({
        name: 'New Chat'
      })
      const newConversation = newConversationResponse.data.conversation
      addNewConversationToUserConversations(newConversation)
      setIsConversationAtTheBeginningOfList(true)
      setConversationId(newConversation.id)
      setLastUsedConversationId(newConversation.id)
    } catch (error) {
      console.error('Error creating new conversation:', error)
    }
  }

  const formatMessagesList = (messages) => {
    let formattedMessages = []
    messages.forEach((msg) => {
      formattedMessages.push({
        id: msg.id,
        type: msg.message.type,
        message: msg.message.data.content
      })
    })
    return formattedMessages
  }

  const handleSelectConversation = (convId) => {
    setConversationId(convId)
  }

  const handleEditConversationName = (event, conversation_id) => {
    event.stopPropagation()
    const conversationToEdit = userConversations.find(
      (conv) => conv.id === conversation_id
    )
    setEditedConversationId(conversation_id)
    setEditedConversationName(conversationToEdit.name)
    setIsEditingName(true)
  }

  const handleSaveEditedName = async (conversationId) => {
    try {
      // Call an API endpoint to update the conversation name
      await updateConversationNameRequest({
        conversationId: conversationId,
        name: editedConversationName
      })
      // Update the conversation name locally
      setUserConversations((prevConversations) =>
        prevConversations.map((conv) => {
          if (conv.id === conversationId) {
            return { ...conv, name: editedConversationName }
          }
          return conv
        })
      )
      setIsEditingName(false)
      setEditedConversationId(null)
    } catch (error) {
      console.error('Error updating conversation name:', error)
    }
  }

  const handleCancelEditing = () => {
    setIsEditingName(false)
    setEditedConversationId(null)
  }

  const handleDeleteConversation = (event, conversation_id) => {
    try {
      event.stopPropagation()
      setUserConversations(
        userConversations.filter((conv) => conv.id !== conversation_id)
      )
      setConversationId('')
      setMessageList([])
      deleteConversationRequest({ conversationId: conversation_id })
    } catch (error) {
      console.error('Error deleting conversation:', error)
    }
  }

  const updateConversationInfo = (conversationId, newInfo) => {
    setUserConversations((prevConversations) =>
      prevConversations.map((conv) => {
        if (conv.id === conversationId) {
          return { ...conv, ...newInfo }
        }
        return conv
      })
    )
  }

  useEffect(() => {
    if (lastUsedConversationId !== '' && !isConversationAtTheBeginningOfList) {
      // place the conversation at the top of the list
      let conversationIndex = userConversations.findIndex(
        (conv) => conv.id === lastUsedConversationId
      )
      let conversation = userConversations[conversationIndex]
      userConversations.splice(conversationIndex, 1)
      userConversations.unshift(conversation)
      setUserConversations(userConversations)
    }
    const scrollInterval = setInterval(() => {
      if (sidebarRef.current.scrollTop > 0) {
        sidebarRef.current.scrollTop -= SCROLL_SPEED // Adjust this value to control the scroll speed
      } else {
        clearInterval(scrollInterval) // Stop the interval when scrollTop reaches 0
      }
    }, SCROLL_INTERVAL) // Adjust this value to control the interval frequency
  }, [lastUsedConversationId])

  useEffect(() => {
    // cada vez que se actualiza el conversationId se hace un request para obtener los mensajes de la conversacion
    async function getConversationMessages() {
      try {
        const conversationMessagesResponse =
          await getConversationMessagesRequest({
            conversationId: conversationId
          })
        const conversationMessages = conversationMessagesResponse.data.messages
        const formattedMessages = formatMessagesList(conversationMessages)
        setMessageList(formattedMessages)
      } catch (error) {
        console.error('Error fetching conversation messages:', error)
      }
    }
    if (conversationId) {
      getConversationMessages()
    }
  }, [conversationId])

  useEffect(() => {
    // al renderizar la pagina se hace un request para obtener las conversaciones del usuario
    async function getUserConversations() {
      try {
        const conversationsResponse = await getUserConversationsRequest()
        setUserConversations(conversationsResponse.data.conversations) // Assuming the data structure matches the state
        setConversationLoading(false)
      } catch (error) {
        console.error('Error fetching user conversations:', error)
        setConversationLoading(false)
      }
    }
    getUserConversations()
  }, [])

  useEffect(() => {
    // cuanto se actualiza la lista de mensajes si el ultimo es del usuario, se hace un request
    // cada vez que se actualiza la lista de mensajes, se hace scroll al final
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight
    let lastMessage = messageList[messageList.length - 1]
    if (lastMessage && lastMessage.type === 'human') {
      setIsConversationAtTheBeginningOfList(false)
      setLastUsedConversationId(conversationId)
      postRequest({
        content: lastMessage.message,
        conversation_id: conversationId
      })
    }
  }, [messageList])

  useEffect(() => {
    // cuando se actualiza el resultado del request, se agrega el mensaje del bot a la lista
    if (postResult.data?.status === 200) {
      const jsonResponse = postResult.data?.json
      if (jsonResponse) {
        addBotMessageToList(jsonResponse.ai_response)
        // si fue el primer mensaje y la conversacion aun no estaba creada, se agrega la conversacion a la lista de conversaciones
        if (jsonResponse.is_new_conversation) {
          setConversationId(jsonResponse.conversation.id)
          addNewConversationToUserConversations(jsonResponse.conversation)
          setIsConversationAtTheBeginningOfList(true)
          setLastUsedConversationId(jsonResponse.conversation.id)
        }
        // si la conversacion ya estaba creada, y es el primer mensaje que se envia, se actualiza la fecha de ultima modificacion y nombre por el creado por el bot
        if (
          jsonResponse.is_first_message &&
          !jsonResponse.is_new_conversation
        ) {
          updateConversationInfo(jsonResponse.conversation.id, {
            id: jsonResponse.conversation.id,
            name: jsonResponse.conversation.name,
            modified: jsonResponse.conversation.modified
          })
        }
        // si la conversacion ya estaba creada, y no es el primer mensaje que se envia, se actualiza la fecha de ultima modificacion
        // esto es para que se actualice la fecha de ultima modificacion del chat cuando el usuario envia un mensaje, si no se necesitara
        // mostrar la fecha de ultima modificacion del chat en la lista de chats, eliminar este if
        if (
          !jsonResponse.is_first_message &&
          !jsonResponse.is_new_conversation
        ) {
          updateConversationInfo(jsonResponse.conversation.id, {
            id: jsonResponse.conversation.id,
            name: jsonResponse.conversation.name,
            modified: new Date()
          })
        }
      }
    }
  }, [postResult.data])

  return (
    <div className={classes.chatPage}>
      <div className={classes.sidebar} ref={sidebarRef}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            handleSubmitNewConversation()
          }}
        >
          Create New Chat
        </Button>
        {conversationLoading && <Loading />}
        <List>
          {userConversations.map((conv) => (
            <ListItem
              key={conv.id}
              button
              onClick={() => handleSelectConversation(conv.id)}
              className={
                conversationId === conv.id ? classes.selectedConversation : ''
              }
            >
              {isEditingName && conv.id === editedConversationId ? (
                <>
                  <TextField
                    value={editedConversationName}
                    onChange={(e) => setEditedConversationName(e.target.value)}
                    placeholder={conv.name}
                  />
                  <IconButton onClick={() => handleSaveEditedName(conv.id)}>
                    <SendIcon />
                  </IconButton>
                  <IconButton onClick={() => handleCancelEditing()}>
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <ListItemText
                    primary={conv.name}
                    secondary={formatConversationDate(new Date(conv.modified))}
                  />
                  <div>
                    <IconButton
                      onClick={(e) => handleEditConversationName(e, conv.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => handleDeleteConversation(e, conv.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </>
              )}
            </ListItem>
          ))}
        </List>
      </div>
      <div className={classes.messageListContainer}>
        {messageList.length > 0 ? (
          <div className={classes.messageList} ref={messageListRef}>
            {messageList.map((msg, index) => (
              <MessageLabel
                key={index}
                messageId={msg.id}
                message={msg.message}
                sender={msg.type}
              />
            ))}
            {postResult.isLoading && <BotResponseLoader />}
          </div>
        ) : (
          <div className={classes.messageList} ref={messageListRef}>
            <NoMessage />
            {postResult.isLoading && <Loading />}
          </div>
        )}
        <div className={classes.input}>
          <TextField
            className={classes.inputText}
            variant="outlined"
            value={humanMessage}
            onChange={(e) => handleHumanMessageOnChange(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          {postResult.isLoading ? (
            <ChatLoader />
          ) : (
            <Button
              className={classes.inputButton}
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              onClick={handleHumanMessageOnSubmit}
            >
              Send
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
