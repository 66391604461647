import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AbaqusIcon1 from 'assets/images/figures/figura-azul.png'
import AbaqusIcon2 from 'assets/images/figures/figura-beige.png'
import AbaqusIcon4 from 'assets/images/figures/figura-gris.png'
import AbaqusIcon3 from 'assets/images/figures/figura-naranja.png'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(1),
    width: 30,
    height: 30,
    '&:nth-child(1)': {
      animation: '$fade 1s ease-in alternate infinite'
    },
    '&:nth-child(2)': {
      animation: '$fade 1.25s ease-in alternate infinite'
    },
    '&:nth-child(3)': {
      animation: '$fade 1.25s ease-in alternate infinite'
    },
    '&:nth-child(4)': {
      animation: '$fade 1.5s ease-in alternate infinite',
      transform: 'rotate(180deg)'
    }
  },
  '@keyframes fade': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  }
}))

const images = [AbaqusIcon1, AbaqusIcon2, AbaqusIcon3, AbaqusIcon4]

export default function Loading({ extraHeight = 0 }) {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        style={{
          width: 60,
          paddingTop: extraHeight,
          paddingBottom: extraHeight
        }}
      >
        {images.map((image, i) => (
          <img key={i} className={classes.icon} src={image} alt="loading" />
        ))}
      </Grid>
    </Grid>
  )
}
