import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

const SavedPortfoliosModal = ({
  open,
  onClose,
  savedList,
  handleAddPortfolio,
  deleteSavedData,
  editSavedPortfolio
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Mis Portafolios
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {savedList.length ? (
            savedList.map((portfolio, index) => (
              <ListItem button key={index}>
                <ListItemText primary={portfolio.name} />
                <Tooltip title="Agregar al gráfico">
                  <IconButton
                    onClick={() => {
                      handleAddPortfolio(
                        portfolio.name,
                        portfolio.tickers_weights_dict,
                        portfolio.rebalance_frequency
                      )
                    }}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Editar portafolio">
                  <IconButton
                    onClick={() => {
                      editSavedPortfolio(portfolio.name)
                    }}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Eliminar portafolio">
                  <IconButton
                    onClick={() => {
                      deleteSavedData(portfolio.uuid)
                    }}
                    color="primary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No hay portafolios guardados" />
            </ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default SavedPortfoliosModal
