import { Link } from 'react-router-dom'

const NotFoundPage = () => (
  <div>
    <div>
      <h1 className="display-3">Error 404: Página no encontrada</h1>
      <hr className="my-2" />
      <p className="lead">
        <Link to="/">Ir al inicio</Link>
      </p>
    </div>
  </div>
)

export default NotFoundPage
