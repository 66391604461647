import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Autocomplete } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'
import {
  CreatePlaidInstitution,
  DeletePlaidInstitution,
  GetInstitutions,
  GetPlaidInstitutions,
  UpdatePlaidInstitution
} from '../../axios/requests/operations'
const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  },
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    marginBottom: theme.spacing(2)
  }
}))

const PlaidInstitutions = () => {
  const classes = useStyles()

  const [institutions, setInstitutions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [newInstitutionId, SetNewInstitutionId] = useState('')
  const [newInstitutionPlaidId, setNewInstitutionPlaidId] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [wiqInstitutions, setWiqInstitutions] = useState([])
  const [currentInstitution, setCurrentInstitution] = useState(null)

  useEffect(() => {
    fetchInstitutions()
  }, [])

  const fetchInstitutions = () => {
    setIsLoading(true)
    GetPlaidInstitutions()
      .then((res) => {
        setInstitutions(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching institutions:', error)
        setIsLoading(false)
      })
    GetInstitutions()
      .then((res) => {
        setWiqInstitutions(res.data)
      })
      .catch((error) => {
        console.error('Error fetching institutions:', error)
        setIsLoading(false)
      })
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    SetNewInstitutionId('')
    setNewInstitutionPlaidId('')
  }

  const handleOpenEditDialog = (institution) => {
    setCurrentInstitution(institution)
    SetNewInstitutionId(institution.institution_id)
    setNewInstitutionPlaidId(institution.plaid_institution_id)
    setEditDialogOpen(true)
  }

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false)
    setCurrentInstitution(null)
    SetNewInstitutionId('')
    setNewInstitutionPlaidId('')
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    const newInstitution = {
      institution: newInstitutionId,
      plaid_institution_id: newInstitutionPlaidId
    }
    CreatePlaidInstitution(newInstitution)
      .then(() => {
        successNotification('savedCorrectly')
        fetchInstitutions()
        handleCloseDialog()
        setIsSubmitting(false)
      })
      .catch((error) => {
        console.error('Error creando institución:', error)
        errorNotification('generalError')
        setIsSubmitting(false)
      })
  }

  const handleUpdate = () => {
    setIsSubmitting(true)
    const updatedInstitution = {
      id: currentInstitution.id,
      institution: newInstitutionId,
      plaid_institution_id: newInstitutionPlaidId
    }
    UpdatePlaidInstitution(updatedInstitution)
      .then(() => {
        successNotification('savedCorrectly')
        fetchInstitutions()
        handleCloseEditDialog()
        setIsSubmitting(false)
      })
      .catch((error) => {
        console.error('Error actualizando institución:', error)
        errorNotification('generalError')
        setIsSubmitting(false)
      })
  }

  const handleDelete = () => {
    if (
      window.confirm('¿Estás seguro de que deseas eliminar esta institución?')
    ) {
      DeletePlaidInstitution({ id: currentInstitution.id })
        .then(() => {
          successNotification('deletedCorrectly')
          fetchInstitutions()
          handleCloseEditDialog()
        })
        .catch((error) => {
          console.error('Error eliminando institución:', error)
          errorNotification('generalError')
        })
    }
  }

  return (
    <div>
      {isLoading ? (
        <div className={classes.reportContainer}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.contentContainer}>
          <div className={classes.reportContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleOpenDialog}
            >
              Nueva Institución
            </Button>
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre de Institución en WealthIQ</TableCell>
                  <TableCell>ID de Institución en Plaid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {institutions.map((institution) => (
                  <TableRow
                    key={institution.id}
                    onClick={() => handleOpenEditDialog(institution)}
                    hover
                  >
                    <TableCell>{institution.institution_name}</TableCell>
                    <TableCell>{institution.plaid_institution_id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nueva Institución</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, ingresa los detalles de la nueva institución.
          </DialogContentText>
          <Autocomplete
            autoFocus
            margin="dense"
            id="institution-name"
            options={wiqInstitutions}
            getOptionLabel={(option) => option.name}
            value={
              wiqInstitutions.find((inst) => inst.id === newInstitutionId) ||
              null
            }
            onChange={(e, newValue) => {
              SetNewInstitutionId(newValue?.id || '')
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nombre de la Institución"
                fullWidth
                className={classes.textField}
              />
            )}
          />
          <TextField
            margin="dense"
            id="plaid-institution-id"
            label="ID de Institución en Plaid"
            type="text"
            fullWidth
            value={newInstitutionPlaidId}
            onChange={(e) => setNewInstitutionPlaidId(e.target.value)}
            className={classes.textField}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={
              isSubmitting || !newInstitutionId || !newInstitutionPlaidId
            }
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de Edición */}
      <Dialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        aria-labelledby="edit-dialog-title"
      >
        <DialogTitle id="edit-dialog-title">Editar Institución</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Modifica los detalles de la institución seleccionada.
          </DialogContentText>
          <Autocomplete
            autoFocus
            margin="dense"
            id="edit-institution-name"
            options={wiqInstitutions}
            getOptionLabel={(option) => option.name}
            value={
              wiqInstitutions.find((inst) => inst.id === newInstitutionId) ||
              null
            }
            onChange={(e, newValue) => {
              SetNewInstitutionId(newValue?.id || '')
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nombre de la Institución"
                fullWidth
                className={classes.textField}
              />
            )}
          />
          <TextField
            margin="dense"
            id="edit-plaid-institution-id"
            label="ID de Institución en Plaid"
            type="text"
            fullWidth
            value={newInstitutionPlaidId}
            onChange={(e) => setNewInstitutionPlaidId(e.target.value)}
            className={classes.textField}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDelete}
            color="secondary"
            disabled={isSubmitting}
          >
            Eliminar
          </Button>
          <Button
            onClick={handleCloseEditDialog}
            color="primary"
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleUpdate}
            color="primary"
            disabled={
              isSubmitting || !newInstitutionId || !newInstitutionPlaidId
            }
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Actualizar'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PlaidInstitutions
