import { postAuthRequest, postRequest } from 'axios/request'

export function getTokensRequest(userEmail, userPassword) {
  const url = '/token/'
  return postRequest(
    url,
    {
      email: userEmail,
      password: userPassword
    },
    {}
  )
}

export function refreshAccessTokenRequest(refreshToken) {
  const url = '/token/refresh/'
  return postRequest(
    url,
    {
      refresh: refreshToken
    },
    {}
  )
}

export function sendEmailPasswordReset(userEmail) {
  const url = '/users/send-email-reset-password/'

  return postRequest(
    url,
    {
      user_email: userEmail
    },
    {}
  )
}

export function passwordResetRequest(password, token) {
  const url = '/users/change-password/'

  return postRequest(
    url,
    {
      new_password: password
    },
    {
      Authorization: `Bearer ${token}`
    }
  )
}

export function passwordChangeRequest(password) {
  const url = '/users/change-password/'

  return postAuthRequest(
    url,
    {
      new_password: password
    },
    {}
  )
}
