import { makeStyles } from '@material-ui/core/styles'

export const wizardUseStyles = makeStyles((theme) => ({
  // Registration
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  button: {
    marginRight: theme.spacing(3),
    color: '#FFF'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  // ButtonDirections
  button_directions: {
    marginRight: theme.spacing(1)
  },
  // PersonalDataForm ,ContactDataForm, ContactDataForm,
  // SpouseDataForm, WorkDataForm, LegalDataForm
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  formControl: {
    textAlign: 'left',
    marginTop: 15,
    marginBottom: 15,
    color: '#707070'
  },
  // UploadIdentityForm
  media: {
    height: 60
  }
}))

export const useStylesCircularProgress = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'center',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  }
}))
