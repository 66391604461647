import { useEffect, useMemo, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { Checkbox } from '@material-ui/core'
import {
  DuplicateSplitsRequest,
  ValidateSplitsRequest
} from 'axios/requests/operations'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import SaveIcon from '@material-ui/icons/Save'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'
import DefaultTable from 'utils/UI/Tables/DefaultTable'

const colsData = [
  {
    id: 'checkbox',
    label: 'Válido'
  },
  {
    id: 'date',
    label: 'Fecha'
  },
  {
    id: 'ticker',
    label: 'Ticker'
  },
  {
    id: 'asset',
    label: 'Activo'
  },
  {
    id: 'reverse',
    label: 'Reverse'
  }
]

export default function DuplicateSplitsTable() {
  const [rowsData, setRowsData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingValidateSplits, setLoadingValidateSplits] = useState(false)

  const handleChangeCheckbox = (id, checked) => {
    const updatedRowsData = rowsData.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          checked: checked
        }
      }
      return row
    })

    setRowsData(updatedRowsData)
  }

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await DuplicateSplitsRequest({})
        if (!didCancel) {
          setRowsData(result.data)
          setLoading(false)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }

    if (!loadingValidateSplits) {
      fetchData()
    }

    return () => {
      didCancel = true
    }
  }, [loadingValidateSplits])

  const updatedRowsData = useMemo(() => {
    if (rowsData) {
      return rowsData.map((item) => ({
        checkbox: (
          <Checkbox
            color="primary"
            size="small"
            checked={item.checked}
            onChange={(e) => {
              if (rowsData) {
                handleChangeCheckbox(item.id, e.target.checked)
              }
            }}
          />
        ),
        id: item.id,
        ticker: item.ticker,
        asset: item.asset,
        date: item.date,
        reverse: item.reverse
      }))
    }
    return []
  }, [rowsData])

  const saveValidSplitsHandler = async () => {
    setLoadingValidateSplits(true)

    try {
      await ValidateSplitsRequest({
        valid_split_ids: rowsData
          .filter((row) => row.checked)
          .map((row) => row.id)
          .join(',')
      })
      successNotification('savedCorrectly')
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingValidateSplits(false)
  }

  return (
    <CardWrapper title="Splits duplicados">
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Tooltip title={rowsData?.length ? 'Validar' : ''}>
            {loadingValidateSplits ? (
              <CircularProgress />
            ) : (
              <IconButton
                disabled={!rowsData?.length}
                onClick={saveValidSplitsHandler}
                color="primary"
              >
                <SaveIcon />
              </IconButton>
            )}
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          {loading || !updatedRowsData ? (
            <Loading />
          ) : (
            <DefaultTable colsData={colsData} rowsData={updatedRowsData} />
          )}
        </Grid>
      </Grid>
    </CardWrapper>
  )
}
