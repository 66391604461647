import { useEffect, useState } from 'react'

import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  ClientPortfoliosRequest,
  ClientsRequest,
  UserPortfoliosOrderRequest
} from 'axios/requests/operations'

import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import SaveIcon from '@material-ui/icons/Save'
import Loading from 'utils/UI/Loading'
import DraggableTable from 'utils/UI/Tables/DraggableTable'
import { COLORS_GRAPH_ASSETS } from 'utils/UI/Theme'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const colsData = [
  {
    id: 'code',
    label: 'Code'
  },
  {
    id: 'name',
    label: 'Name'
  }
]

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  seriesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  },
  holdingsSelectorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  fullscreenContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Set the background color to a light color
    borderRadius: 8, // Adjust as needed
    padding: theme.spacing(2), // Add padding as needed
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.drawer + 1 // Ensure the fullscreen component is above the sidebar
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function PortafolioSortTable() {
  const classes = useStyles()
  const theme = useTheme()
  const [clients, setClients] = useState([])
  const [portfolios, setPortfolios] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingSaveOrder, setLoadingSaveOrder] = useState(false)

  useEffect(() => {
    let didCancel = false
    const fetchClients = async () => {
      try {
        const result = await ClientsRequest()
        if (!didCancel) {
          setClients(result.data)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchClients()

    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    let didCancel = false
    const fetchPortfolios = async () => {
      try {
        if (selectedClient) {
          setLoading(true)
          const result = await ClientPortfoliosRequest({
            client_id: selectedClient.id
          })
          if (!didCancel) {
            setPortfolios(result.data)
            setLoading(false)
          }
        }
      } catch (err) {
        errorNotification('generalError')
        setLoading(false)
      }
    }
    fetchPortfolios()

    return () => {
      didCancel = true
    }
  }, [selectedClient])

  useEffect(() => {
    if (!selectedClient) {
      setPortfolios([])
    }
  }, [selectedClient])

  const onDragEnd = (result) => {
    if (!result.destination) return

    const items = Array.from(portfolios)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setPortfolios(items)
  }

  const saveOrderHandler = async () => {
    setLoadingSaveOrder(true)
    try {
      await UserPortfoliosOrderRequest({
        user_id: selectedClient.id,
        portfolios_order: portfolios
          .map((portfolio) => portfolio.code)
          .join(',')
      })
      successNotification('savedCorrectly')
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingSaveOrder(false)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={classes.contentContainer}>
        <CardWrapper
          title="Portfolio Sort"
          titleFeedback="Set the desired order for portfolios"
        >
          <Grid
            container
            className={classes.holdingsSelectorContainer}
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={clients}
                getOptionLabel={(client) =>
                  `[${client.code}] ${client.first_name} ${client.last_name}`
                }
                value={selectedClient}
                onChange={(event, newValue) => {
                  setSelectedClient(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona un cliente:"
                    variant="outlined"
                    placeholder="Clientes"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((client, index) => (
                    <Tooltip title={client.code} key={index}>
                      <Chip
                        style={{
                          marginRight: 12,
                          color: lighten(
                            [
                              theme.palette.primary.main,
                              ...COLORS_GRAPH_ASSETS
                            ][index],
                            0.5
                          )
                            ? '#fff'
                            : '#000'
                        }}
                        size="small"
                        label={`[${client.code}] ${client.first_name} ${client.last_name}`}
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ))
                }
              />
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={portfolios?.length ? 'Guardar Orden' : ''}>
                {loadingSaveOrder ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    disabled={!portfolios?.length}
                    onClick={saveOrderHandler}
                    color="primary"
                  >
                    <SaveIcon />
                  </IconButton>
                )}
              </Tooltip>
            </Grid>

            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              item
              xs={12}
            ></Grid>
            <Grid item xs={12}>
              {loading ? (
                <Loading />
              ) : portfolios && portfolios.length > 0 ? (
                <Droppable droppableId="droppable-portfolios">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <DraggableTable
                        colsData={colsData}
                        rowsData={portfolios.map((portfolio, index) => ({
                          ...portfolio,
                          id: `draggable-${portfolio.id}`,
                          index
                        }))}
                        draggable={{
                          provided,
                          snapshot
                        }}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ) : (
                <p>No hay portafolios para mostrar</p>
              )}
            </Grid>
          </Grid>
        </CardWrapper>
      </div>
    </DragDropContext>
  )
}
