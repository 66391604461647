import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import { StyledTableCell, StyledTableContainer } from 'assets/jss/Tables/Tables'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

const DraggableTable = ({ colsData, rowsData }) => {
  return (
    <Auxiliary>
      <StyledTableContainer>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {colsData.map((column) => (
                <StyledTableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <Droppable droppableId="table-body">
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {rowsData.map((row, index) => (
                  <Draggable
                    draggableId={row.id.toString()}
                    index={index}
                    key={row.id}
                  >
                    {(provided, snapshot) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          background: snapshot.isDragging
                            ? 'rgba(0,0,0,0.1)'
                            : 'none'
                        }}
                      >
                        {colsData.map((column) => (
                          <StyledTableCell key={column.id} align={column.align}>
                            {row[column.id]}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </StyledTableContainer>
    </Auxiliary>
  )
}

export default DraggableTable
