import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import AssetsCreation from 'components/Graphs/AssetsCreation'
import useResponsive from 'hooks/useResponsive'
import { useState } from 'react'
import Auxiliary from 'utils/Auxiliary/Auxiliary'
import CustomModeButtonGroup from 'utils/UI/Buttons/CustomModeButtonGroup'

const DefaultModelList = [
  {
    key: 'assets',
    label: 'Assets'
  },
  {
    key: 'modelo2',
    label: 'Modelo 2'
  },
  {
    key: 'modelo3',
    label: 'Modelo 3'
  }
]

const useStyles = makeStyles((theme) => ({
  graphSelectorsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    width: '100%',
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    }
  }
}))

export default function ModelsCRUDTables() {
  const classes = useStyles()
  const { isMobile } = useResponsive()
  const [model, setModel] = useState('assets')

  return (
    <Auxiliary>
      <Box className={classes.graphSelectorsContainer}>
        <Box className={classes.graphDataType}>
          <Grid container={isMobile}></Grid>
        </Box>
      </Box>
      <div className={classes.contentContainer}>
        <CustomModeButtonGroup
          modeList={DefaultModelList}
          currentValue={model}
          onClickHandler={setModel}
        />
        <AssetsCreation />
      </div>
    </Auxiliary>
  )
}
