import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import useDebounce from 'hooks/useDebounce'
import { useEffect, useState } from 'react'
import { AssetSearchRequest } from '../../axios/requests/operations'

const useStyles = makeStyles((theme) => ({
  holdingsSelectorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  assetTicker: {
    fontFamily: 'InconsolataSemiBold',
    lineHeight: 0.8
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginLeft: 0
    }
  }
}))

const AssetsLookup = ({ filterAsset, setFilterAsset }) => {
  const classes = useStyles()
  const [assetOptions, setAssetOptions] = useState([])
  const [loadingAssetSearch, setLoadingAssetSearch] = useState(false)
  const [assetSearch, setAssetSearch] = useState('')
  const debouncedAssetSearch = useDebounce(assetSearch, 500)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const fetchAssetOptions = async () => {
      setLoadingAssetSearch(true)
      try {
        if (debouncedAssetSearch) {
          const result = await AssetSearchRequest({
            search: debouncedAssetSearch
          })
          setAssetOptions(result.data)
        }
      } catch (error) {
        console.error('Error fetching asset options:', error)
      } finally {
        setLoadingAssetSearch(false)
      }
    }

    if (debouncedAssetSearch) {
      fetchAssetOptions()
    } else {
      setAssetOptions([])
    }
  }, [debouncedAssetSearch])

  return (
    <Autocomplete
      options={assetOptions}
      value={filterAsset}
      onChange={(event, newValue) => {
        setInputValue('')
        setFilterAsset(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
        setAssetSearch(newInputValue)
      }}
      filterSelectedOptions
      getOptionLabel={(option) => `[${option.ticker}] ${option.name}` || ''}
      className={classes.assetsSelector}
      style={{
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Buscar Asset de Plaid"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingAssetSearch ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default AssetsLookup
