import HeaderContext from 'context/headerContext'
import { useContext, useEffect, useState } from 'react'

import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  ClientAccountsCashHoldingsRequest,
  DownloadClientAccountsCashHoldingsRequest
} from 'axios/requests/accounts'
import { AccountsRequest, ClientsRequest } from 'axios/requests/operations'

import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'

import { Backdrop, Modal } from '@material-ui/core'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { CreateHoldingAdjustmentRequest } from 'axios/requests/operations'
import { CashHoldingsTable } from 'components/Graphs/CashHoldingsTable'
import { format, parseISO } from 'date-fns'
import Loading from 'utils/UI/Loading'
import { COLORS_GRAPH_ASSETS } from 'utils/UI/Theme'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  },
  holdingsSelectorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  fullscreenContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    zIndex: 1300 // The z-index for Modal is usually 1300 in Material-UI
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function CashHoldingsMonitor() {
  const classes = useStyles()
  const theme = useTheme()
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate } = headerState
  const [clients, setClients] = useState([])
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [accounts, setAccounts] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const [periodicity, setPeriodicity] = useState({
    id: 1,
    name: 'Diario',
    value: 'daily'
  })
  const [cashHoldings, setCashHoldings] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [savingRealQuantities, setSavingRealQuantities] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen)
  }

  const manageClientSelection = (client) => {
    setSelectedClient(client)
    setSelectedAccounts([])
  }

  const sortCashHoldingsFromNewestToOldest = (cashHoldings) => {
    const sortedCashHoldings = cashHoldings.map((account) => ({
      ...account,
      dates: account.dates.slice().reverse(),
      quantity: account.quantity.slice().reverse(),
      real_quantity: account.real_quantity.slice().reverse()
    }))
    return sortedCashHoldings
  }

  const downloadCashHoldingsHandler = async () => {
    setLoadingDownload(true)
    try {
      const response = await DownloadClientAccountsCashHoldingsRequest({
        user_code: selectedClient.code,
        account_codes: selectedAccounts
          .map((account) => account.code)
          .join(','),
        start_date: format(startDate, 'yyyy-MM-dd'),
        end_date: format(endDate, 'yyyy-MM-dd'),
        periodicity: periodicity.value,
        response_type: 'xlsx'
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Abaqus - Cash Holdings' + '.xlsx') // or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingDownload(false)
  }

  useEffect(() => {
    let didCancel = false
    const fetchClients = async () => {
      try {
        const result = await ClientsRequest()
        if (!didCancel) {
          setClients(result.data)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchClients()

    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    let didCancel = false
    const fetchAccounts = async () => {
      try {
        if (selectedClient) {
          const result = await AccountsRequest({
            clients: selectedClient.id
          })
          if (!didCancel) {
            setAccounts(result.data)
          }
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchAccounts()

    return () => {
      didCancel = true
    }
  }, [selectedClient])

  useEffect(() => {
    if (
      selectedClient &&
      selectedAccounts.length > 0 &&
      startDate &&
      endDate &&
      periodicity
    ) {
      setLoading(true)
      let didCancel = false
      const fetchCashHoldings = async () => {
        try {
          const result = await ClientAccountsCashHoldingsRequest({
            user_code: selectedClient.code,
            account_codes: selectedAccounts
              .map((account) => account.code)
              .join(','),
            start_date: format(startDate, 'yyyy-MM-dd'),
            end_date: format(endDate, 'yyyy-MM-dd'),
            periodicity: periodicity.value,
            response_type: 'json',
            mode: 'adjustment'
          })
          if (!didCancel) {
            let sortedCashHoldings = sortCashHoldingsFromNewestToOldest(
              result.data.cash_holdings
            )
            setCashHoldings(sortedCashHoldings)
            setLoading(false)
          }
        } catch (err) {
          errorNotification('generalError')
        }
      }
      fetchCashHoldings()

      return () => {
        didCancel = true
      }
    } else {
      setCashHoldings([])
    }
  }, [selectedClient, selectedAccounts, startDate, endDate, periodicity])

  const handleRealQuantityChange = (dateIndex, currencyIndex, newValue) => {
    setCashHoldings((currentHoldings) => {
      const newHoldings = currentHoldings.map((holding) => ({
        ...holding,
        real_quantity: [...holding.real_quantity]
      }))

      newHoldings[currencyIndex].real_quantity[dateIndex] = newValue

      return newHoldings
    })
  }

  const saveRealQuantities = async () => {
    setSavingRealQuantities(true)

    try {
      const adjustments = cashHoldings
        .flatMap((holding) =>
          holding.dates.map((date, index) => ({
            account_code: holding.account_code,
            ticker: holding.cash_currency,
            date: format(parseISO(date), 'yyyy-MM-dd'), // convert date to required format
            real_quantity: holding.real_quantity[index]
          }))
        )
        .filter(
          (adjustment) =>
            adjustment.real_quantity !== undefined &&
            adjustment.real_quantity !== null &&
            adjustment.real_quantity !== ''
        )

      // Prepare the payload for the request
      await CreateHoldingAdjustmentRequest({
        adjustments: adjustments
      })

      successNotification('savedCorrectly')
    } catch (err) {
      errorNotification('generalError')
    }

    setSavingRealQuantities(false)
  }

  const Table = (
    <CashHoldingsTable
      cashHoldingsData={cashHoldings}
      handleRealQuantityChange={handleRealQuantityChange}
      handleSave={saveRealQuantities}
      handleDownload={downloadCashHoldingsHandler}
      isSaving={savingRealQuantities}
      isDownloading={loadingDownload}
      onToggleFullscreen={handleToggleFullscreen}
      isFullscreen={isFullscreen}
    />
  )
  return (
    <div>
      <CardWrapper title="Cash Monitor" titleFeedback="Cash Holdings Monitor">
        <Grid
          container
          className={classes.holdingsSelectorContainer}
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={12} sm={5}>
            <Autocomplete
              fullWidth
              label="Selecciona un cliente:"
              variant="outlined"
              placeholder="Clientes"
              margin="normal"
              options={clients}
              getOptionLabel={(client) =>
                `[${client.code}] ${client.first_name} ${client.last_name}`
              }
              value={selectedClient}
              onChange={(event, newValue) => {
                manageClientSelection(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecciona un cliente:"
                  variant="outlined"
                  placeholder="Clientes"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((client, index) => (
                  <Tooltip title={client.code} key={index}>
                    <Chip
                      style={{
                        marginRight: 12,
                        color: lighten(
                          [theme.palette.primary.main, ...COLORS_GRAPH_ASSETS][
                            index
                          ],
                          0.5
                        )
                          ? '#fff'
                          : '#000'
                      }}
                      size="small"
                      label={`[${client.code}] ${client.first_name} ${client.last_name}`}
                      key={client.code}
                      classes={{ deleteIcon: classes.deleteIcon }}
                      {...getTagProps({ index })}
                    />
                  </Tooltip>
                ))
              }
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <Autocomplete
              fullWidth
              label="Selecciona cuentas:"
              variant="outlined"
              placeholder="Cuentas"
              margin="normal"
              multiple
              limitTags={2}
              options={accounts}
              getOptionLabel={(account) => `[${account.code}] ${account.name}`}
              value={selectedAccounts}
              onChange={(event, newValues) => {
                setSelectedAccounts(newValues)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecciona cuentas:"
                  variant="outlined"
                  placeholder="Cuentas"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((portfolio, index) => (
                  <Tooltip title={portfolio.code} key={index}>
                    <Chip
                      style={{
                        marginRight: 12,
                        backgroundColor: theme.palette.orange.lighten1,
                        color: theme.palette.getContrastText(
                          theme.palette.orange.lighten1
                        )
                      }}
                      size="small"
                      label={portfolio.code}
                      key={portfolio.code}
                      classes={{ deleteIcon: classes.deleteIcon }}
                      {...getTagProps({ index })}
                    />
                  </Tooltip>
                ))
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              fullWidth
              label="Periodo:"
              variant="outlined"
              placeholder="Periodo"
              margin="normal"
              options={[
                { id: 1, name: 'Diario', value: 'daily' },
                { id: 2, name: 'Mensual', value: 'monthly' }
              ]}
              getOptionLabel={(periodo) => `${periodo.name}`}
              value={periodicity}
              onChange={(event, newValue) => {
                setPeriodicity(newValue)
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Periodo:"
                  variant="outlined"
                  placeholder="Periodo"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((periodicity, index) => (
                  <Tooltip title={periodicity.name} key={index}>
                    <Chip
                      style={{
                        marginRight: 12,
                        color: lighten(
                          [theme.palette.primary.main, ...COLORS_GRAPH_ASSETS][
                            index
                          ],
                          0.5
                        )
                          ? '#fff'
                          : '#000'
                      }}
                      size="small"
                      label={`${periodicity.name}`}
                      key={periodicity.id}
                      classes={{ deleteIcon: classes.deleteIcon }}
                      {...getTagProps({ index })}
                    />
                  </Tooltip>
                ))
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
          >
            {loading ? (
              <Loading />
            ) : cashHoldings.length ? (
              <div className={classes.tableContainer}>{Table}</div>
            ) : (
              <p>
                Selecciona clientes o activos para mostrar los fondos
                disponibles.
              </p>
            )}
          </Grid>
        </Grid>
      </CardWrapper>
      <div>
        {isFullscreen && (
          <>
            <Backdrop open={isFullscreen} />
            <Modal
              open={isFullscreen}
              onClose={handleToggleFullscreen}
              className={classes.modal}
            >
              <div className={classes.fullscreenContainer}>{Table}</div>
            </Modal>
          </>
        )}
      </div>
    </div>
  )
}
