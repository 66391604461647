import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  languageButton: {
    cursor: 'pointer',
    margin: theme.spacing(0, 1), // Adjust margin to bring them closer
    padding: theme.spacing(0.5), // Reduce padding for a more compact look
    textDecoration: 'none', // Remove default underline
    borderBottom: '2px solid transparent', // Default border
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.text.primary}` // Underline on hover
    }
  },
  selected: {
    borderBottom: `2px solid ${theme.palette.text.primary}` // Underline for selected language
  }
}))

const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    const storedLang = localStorage.getItem('i18nextLng')
    if (storedLang) {
      i18n.changeLanguage(storedLang)
    }
  }, [i18n])

  const handleChange = (language) => {
    i18n.changeLanguage(language)
  }

  return (
    <Box>
      <span
        className={`${classes.languageButton} ${
          i18n.language === 'es' ? classes.selected : ''
        }`}
        onClick={() => handleChange('es')}
      >
        es
      </span>
      <span
        className={`${classes.languageButton} ${
          i18n.language === 'en' ? classes.selected : ''
        }`}
        onClick={() => handleChange('en')}
      >
        en
      </span>
    </Box>
  )
}

export default LanguageSelector
