import { useContext, useEffect, useState } from 'react'

import clsx from 'clsx'
import moment from 'moment'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import AbaqusLogo from 'assets/images/abaqus-logo-black.svg'

import useResponsive from 'hooks/useResponsive'
import Auxiliary from 'utils/Auxiliary/Auxiliary'
import HijackBanner from 'utils/UI/Hijack/HijackBanner'

import OptionsCard from './OptionsCard'
import PopoverMobile from './PopoverMobile'
import { SidebarContext } from './SidebarContext'

import {
  getCurrenciesRequest,
  getUserPortfoliosRequest
} from 'axios/requests/header'

const defaultPortfolios = []

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`
    },
    height: '100px',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%'
    },
    backgroundColor: 'white',
    boxShadow: 'none',
    marginBottom: theme.spacing(2)
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%'
      },
      backgroundColor: 'white',
      boxShadow: 'none'
    }
  },
  toolbar: {
    backgroundColor: 'white',
    zIndex: 1100,
    paddingBotton: 5
  },
  iconContainer: {
    height: '50%',
    textAlign: 'left',
    '& img': {
      maxWidth: 150,
      maxHeight: 40,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 16,
        paddingRight: 5
      }
    }
  },
  iconContainerMobile: {
    height: '50%',
    textAlign: 'left',
    marginTop: 20,
    '& img': {
      maxWidth: 150,
      maxHeight: 40,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 16,
        paddingRight: 5
      }
    }
  },
  navPortafolioContainerMobile: {
    padding: 0,
    marginTop: 0,
    marginBottom: 17
  },
  iconContainerTop: {
    height: 34,
    width: '100%',
    marginTop: 10
  },
  navBarBtnOpen: {
    marginTop: '10px'
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  dialogOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    margin: theme.spacing(2)
  },
  profileMenuBtn: {
    marginLeft: 'auto'
  },
  datePicker: {
    margin: theme.spacing(3)
  },
  settingsDrawerContainer: {
    width: drawerWidth + 50,
    '& .settingsDrawerHeader': {
      padding: 5,
      display: 'inline-flex',
      alignItems: 'center',
      '& h2': {
        width: '100%',
        fontSize: 18,
        textAlign: 'center',
        marginLeft: -24
      }
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  paper: {
    overflow: 'unset'
  }
}))

export default function NavBar({ headerState, headerDispatch }) {
  const classes = useStyles()
  const { openDrawer: open, handleOpenDrawer } = useContext(SidebarContext)
  const [usingPeriod, setUsingPeriod] = useState(true)
  const [datePeriod, setDatePeriod] = useState('YTD')
  const {
    startDate,
    endDate,
    maxDate,
    minDate,
    currency,
    currentPortfolio,
    portfolios
  } = headerState
  const { isMobile, isDesktop } = useResponsive()

  useEffect(() => {
    let mounted = true

    const fetchUserPortfoliosData = async () => {
      try {
        const result = await getUserPortfoliosRequest()
        return result.data.portfolios
      } catch (err) {
        console.log(err)
      }
    }

    const fetchCurrenciesData = async () => {
      try {
        const result = await getCurrenciesRequest()
        return result.data
      } catch (err) {
        console.log(err)
      }
    }

    if (mounted && portfolios.length === 0) {
      fetchUserPortfoliosData()
        .then((response) => {
          if (mounted && response?.length > 0) {
            headerDispatch({
              type: 'PORTFOLIOS_LIST_CHANGE',
              portfolio_list: response
            })
            for (let index = 0; index < response.length; index++) {
              const portfolio = response[index]
              defaultPortfolios.push({
                key: portfolio.id,
                value: portfolio.name,
                label: portfolio.name
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    if (mounted && currency === null) {
      fetchCurrenciesData()
        .then((response) => {
          if (mounted && response?.length > 0) {
            headerDispatch({
              type: 'CURRENCY_LIST_CHANGE',
              currencyList: response
            })
            headerDispatch({
              type: 'CURRENCY_CHANGE',
              currency: response[0].code
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    return function cleanup() {
      mounted = false
    }
  }, [headerDispatch, portfolios, currency])

  const handleCurrencyChange = (event) => {
    headerDispatch({
      type: 'CURRENCY_CHANGE',
      currency: event.target.value
    })
  }

  const handlePortfolioChange = (event) => {
    const newPortfolio = portfolios.find(
      (element) => element.name === event.target.value
    )
    headerDispatch({
      type: 'CURRENT_PORTFOLIO_CHANGE',
      currentPortfolio: newPortfolio
    })
  }

  const handlePeriodChange = (event) => {
    setUsingPeriod(true)
    const selectedPeriod = event.target.value
    const dateAux = new Date(maxDate)
    switch (selectedPeriod) {
      case '1M':
        setDatePeriod('1M')
        headerDispatch({
          type: 'DATE_CHANGE',
          isITD: false,
          startDate: moment(dateAux).subtract(1, 'months').toDate(),
          endDate: maxDate
        })
        break
      case '6M':
        setDatePeriod('6M')
        headerDispatch({
          type: 'DATE_CHANGE',
          isITD: false,
          startDate: moment(dateAux).subtract(6, 'months').toDate(),
          endDate: maxDate
        })
        break
      case '1Y':
        setDatePeriod('1Y')
        headerDispatch({
          type: 'DATE_CHANGE',
          isITD: false,
          startDate: new Date(
            dateAux.getFullYear() - 1,
            dateAux.getMonth(),
            dateAux.getDate()
          ),
          endDate: maxDate
        })
        break
      case 'ITD':
        setDatePeriod('ITD')
        headerDispatch({
          type: 'DATE_CHANGE',
          isITD: true,
          startDate: minDate,
          endDate: maxDate
        })
        break
      default:
        setDatePeriod('YTD')
        headerDispatch({
          type: 'DATE_CHANGE',
          isITD: false,
          startDate: new Date(maxDate.getFullYear() - 1, 11, 31),
          endDate: maxDate
        })
        break
    }
  }

  const renderOptionsCard = (
    <OptionsCard
      selectedCurrency={currency}
      handleCurrencyChange={handleCurrencyChange}
      selectedPeriod={datePeriod}
      handlePeriodChange={handlePeriodChange}
      portfolioList={portfolios}
      selectedPortfolio={currentPortfolio}
      handlePortfolioChange={handlePortfolioChange}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      headerDispatch={headerDispatch}
      setUsingPeriod={setUsingPeriod}
      usingPeriod={usingPeriod}
      vertical={isMobile}
    />
  )

  return (
    <Auxiliary>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        {window.localStorage.getItem('HIJACK_ADMIN_TOKENS') && <HijackBanner />}
        <Box
          component={Grid}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: { xs: 'none', md: 'flex', lg: 'none' }
          }}
        >
          <div className={classes.iconContainerTop}>
            <img src={AbaqusLogo} alt="full-logo" hidden={open} />
          </div>
        </Box>
        <Toolbar className={classes.toolbar}>
          <Box
            component={Grid}
            sx={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: { xs: 'none', lg: 'flex' }
            }}
          >
            <div className={classes.iconContainer}>
              <img src={AbaqusLogo} alt="full-logo" hidden={open} />
            </div>
          </Box>
          {currentPortfolio && isDesktop && (
            <Grid
              container
              justifyContent="center"
              style={{ position: 'relative' }}
            >
              {renderOptionsCard}
            </Grid>
          )}
          <Box component={Grid} sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton>
              <MenuIcon onClick={() => handleOpenDrawer()} />
            </IconButton>
          </Box>
          <Box sx={{ width: '100%', display: { sm: 'block', md: 'none' } }}>
            <Grid
              container
              justifyContent="center"
              className={classes.iconContainerMobile}
            >
              <img src={AbaqusLogo} alt="full-logo" />
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.navPortafolioContainerMobile}
            >
              {currentPortfolio && (
                <PopoverMobile
                  selectedPortfolio={currentPortfolio}
                  renderOptionsCard={renderOptionsCard}
                  vertical={isMobile}
                />
              )}
            </Grid>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <div id="portfolio-filter-desktop" />
          </Box>
        </Toolbar>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Grid container justifyContent="center">
            <div id="portfolio-filter-mobile" />
          </Grid>
        </Box>
      </AppBar>
    </Auxiliary>
  )
}
