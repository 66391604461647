const lan = 'es'

export const percentageFormatter2 = (value) => {
  if (value == 66500.0) {
    return '-'
  } else if (lan === 'es') {
    return (
      parseFloat(value).toLocaleString('de-DE', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
      }) + '%'
    )
  } else {
    return (
      parseFloat(value).toLocaleString('en-US', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
      }) + '%'
    )
  }
}
