import Typography from '@material-ui/core/Typography'
import { CircularProgress, Grid } from '@material-ui/core/index'
import { makeStyles } from '@material-ui/core/styles'
import { DownloadPortfolioSeries } from 'axios/requests/operations'
import {
  balanceSummaryRequest,
  performanceSeriesRequest
} from 'axios/requests/summary'
import BaseGraph from 'components/Graphs/BaseGraph'
import HeaderContext from 'context/headerContext'
import { format } from 'date-fns'
import Highcharts from 'highcharts'
import useResponsive from 'hooks/useResponsive'
import { useContext, useEffect, useState } from 'react'
import DownloadButton from 'utils/UI/Buttons/DownloadButton'
import Loading from 'utils/UI/Loading'
import { errorNotification } from 'utils/UI/Notifications/Notifications'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  }
}))

export default function QuoteValueSeries({ selectedPortfolios, portfolios }) {
  const classes = useStyles()
  const { isMobile } = useResponsive()
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency } = headerState
  const [portfoliosDict, setPortfoliosDict] = useState({})
  const [balanceData, setBalanceData] = useState(null)
  const [performaceSeries, setPerformanceSeries] = useState(null)
  const [mode, setMode] = useState('valor-cuota')
  const [comparators, setComparators] = useState([])
  const [mergedComparators, setMergedComparators] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)

  const downloadPortfolioSeries = async () => {
    try {
      if (selectedPortfolios.length) {
        let portfoliosCodesToDownloadList = selectedPortfolios.map(
          (p) => p.code
        )

        if (comparators.length) {
          // if there is comparators selected, we add them to the list of portfolios to download. and delete duplicates
          portfoliosCodesToDownloadList = [
            ...new Set(portfoliosCodesToDownloadList.concat(comparators))
          ]
        }
        const portfoliosCodesToDownload =
          portfoliosCodesToDownloadList.join(',')

        setLoadingDownload(true)

        const response = await DownloadPortfolioSeries({
          codes: portfoliosCodesToDownload,
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Abaqus - PortfoliosSeries - ' + '.xlsx')
        document.body.appendChild(link)
        link.click()
        setLoadingDownload(false)
      }
    } catch (err) {
      errorNotification('generalError')
      setLoadingDownload(false)
    }
    return 0
  }

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      if (selectedPortfolios.length) {
        setLoading(true)
        try {
          const selectedPortfolio = selectedPortfolios[0]
          const result = await balanceSummaryRequest({
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            currency: currency,
            portfolio_id: selectedPortfolio.id
          })
          if (!didCancel) setBalanceData({ ...result.data })
        } catch (err) {
          errorNotification('generalError')
        }
        setLoading(false)
      }
    }
    fetchData()

    return () => {
      didCancel = true
    }
  }, [selectedPortfolios, startDate, endDate, currency])

  useEffect(() => {
    if (selectedPortfolios.length === 0) {
      setBalanceData(null)
      setPerformanceSeries(null)
    } else {
      let compsPortfolios = selectedPortfolios.slice(1)
      const portfoliosCodes = portfolios.map((p) => p.code)

      // Filter out codes from comparators that are in portfolios
      const newComparators = comparators.filter((code) => {
        return !portfoliosCodes.includes(code)
      })

      setMergedComparators([
        ...new Set(compsPortfolios.map((p) => p.code).concat(newComparators))
      ])
    }
  }, [selectedPortfolios, comparators])

  useEffect(() => {
    let didCancel = false
    const fetchPerformanceSeries = async () => {
      if (selectedPortfolios.length) {
        const selectedPortfolio = selectedPortfolios[0]
        try {
          Highcharts.charts.forEach((chart) => {
            if (chart) chart.showLoading()
          })
          const result = await performanceSeriesRequest({
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            currency: currency,
            portfolio_id: selectedPortfolio.id,
            mode: mode,
            comparator:
              mode === 'valor-cuota' && mergedComparators.length
                ? mergedComparators.join(',')
                : ''
          })
          if (!didCancel) setPerformanceSeries({ ...result.data })
        } catch (err) {
          errorNotification('generalError')
        }
      }
    }
    fetchPerformanceSeries()
    return () => {
      didCancel = true
    }
  }, [
    selectedPortfolios,
    mergedComparators,
    currency,
    startDate,
    endDate,
    portfoliosDict,
    mode
  ])

  useEffect(() => {
    if (portfolios.length) {
      const basePortfolioDict = {}
      portfolios.forEach((portfolio) => {
        basePortfolioDict[portfolio.code] = portfolio
      })
      setPortfoliosDict(basePortfolioDict)
    } else {
      setPortfoliosDict([])
    }
    setComparators([])
    setMergedComparators([])
  }, [portfolios])

  return (
    <div className={classes.root}>
      <CardWrapper className={classes.reportCard}>
        <Grid
          container
          justifyContent={isMobile ? 'center' : 'space-between'}
          alignItems="center"
        >
          <Typography className={classes.cardReportName}>
            Series de Valor Cuota, Patrimonio y TIR
          </Typography>
          {loadingDownload ? (
            <CircularProgress />
          ) : (
            <DownloadButton onClickHandler={downloadPortfolioSeries} />
          )}
        </Grid>
      </CardWrapper>
      <Grid>
        {loading && <Loading extraHeight={150} />}
        {balanceData && (
          <BaseGraph
            balanceData={balanceData}
            seriesData={performaceSeries}
            portfolio={selectedPortfolios[0]}
            portfolios={[]}
            portfoliosDict={portfoliosDict}
            comparators={mergedComparators}
            setComparators={setComparators}
            mode={mode}
            setMode={setMode}
            currency={currency}
          />
        )}
      </Grid>
    </div>
  )
}
