import { useContext, useEffect, useState } from 'react'

import { format } from 'date-fns'

import { balanceSummaryRequest } from 'axios/requests/summary'
import BalanceSummary from 'components/Graphs/BalanceSummary'
import PerformanceSeries from 'components/Graphs/PerformanceSeries'
import PositionsSummary from 'components/Graphs/PositionsSummary'
import HeaderContext from 'context/headerContext'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

export default function Summary() {
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [balanceData, setBalanceData] = useState()

  useEffect(() => {
    let mounted = true

    const fetchData = async () => {
      try {
        const result = await balanceSummaryRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
          portfolio_id: currentPortfolio.id
        })
        return result.data
      } catch (err) {
        console.log(err)
      }
    }

    if (mounted && currentPortfolio && currency) {
      fetchData().then((data) => {
        if (mounted && data) {
          setBalanceData({
            balance: data.balance,
            total_return: data.total_return,
            withdrawal_sum: Math.abs(data.withdrawal_sum),
            deposit_sum: data.deposit_sum
          })
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [currency, currentPortfolio, startDate, endDate])

  return (
    <Auxiliary>
      <PerformanceSeries balanceData={balanceData} />
      <BalanceSummary balanceData={balanceData} />
      <PositionsSummary />
    </Auxiliary>
  )
}
