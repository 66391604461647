import { getRequest } from 'axios/request'

export function balanceSummaryRequest(params) {
  const url = '/portfolios/data/balance-summary/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function performanceSeriesRequest(params) {
  const url = '/portfolios/data/performance-series/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function positionsSummaryRequest(params) {
  const url = '/portfolios/data/positions-summary/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}
