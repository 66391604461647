import axios from 'axios'
import backendURL from '../../config'

export const USER_API = backendURL

const TIMEOUT = 60 * 2 * 1000 // 2 minutes

export function processUserValidation(callData) {
  const url = '/pace/wizard/process-user-valuation/'
  return axios({
    method: 'post',
    baseURL: USER_API,
    timeout: TIMEOUT,
    url,
    data: callData
  })
}

export function processAccountValidation(callData) {
  const url = '/pace/wizard/process-account-valuation/'
  return axios({
    method: 'post',
    baseURL: USER_API,
    timeout: TIMEOUT,
    url,
    data: callData
  })
}

export function processDocumentPreview(callData) {
  const url = '/pace/wizard/process-document-preview/'
  return axios({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    baseURL: USER_API,
    timeout: TIMEOUT,
    url,
    data: callData
  })
}

export function processRegistration(callData) {
  const url = '/pace/wizard/process-registration/'
  return axios({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    baseURL: USER_API,
    timeout: TIMEOUT,
    url,
    data: callData
  })
}

export function processConfirmation(callData) {
  const url = '/pace/wizard/process-confirmation/'
  return axios({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    baseURL: USER_API,
    timeout: TIMEOUT,
    url,
    data: callData
  })
}
