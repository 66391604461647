import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'

import ChangePasswordForm from 'components/ChangePasswordPage/ChangePasswordForm'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}))

export default function ChangePasswordPage() {
  const classes = useStyles()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const token = query.get('token')

  return (
    <Container className={classes.container}>
      <ChangePasswordForm token={token} />
    </Container>
  )
}
