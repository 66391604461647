// SnackbarNotification.jsx
import { Alert, Snackbar } from '@mui/material'
import { format } from 'date-fns'

const SnackbarNotification = ({ open, onClose, newStartDate }) => (
  <Snackbar
    open={open}
    autoHideDuration={3000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    <Alert onClose={onClose} severity="warning" elevation={6} variant="filled">
      La fecha de inicio ha cambiado a: {format(newStartDate, 'dd/MM/yyyy')}
    </Alert>
  </Snackbar>
)

export default SnackbarNotification
